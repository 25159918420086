/* ==============
Template name : TourX Pro - Travels Tourism Agency React Template
Author : Egens Lab
Version : 1.0
Created : 
Last update : 
================= */
/* CSS Index 
-----------------------------------
1. Global CSS
2. Reuseable Components Start
3. Topbar area start 
4. Navbar area start 
5. Hero area start 
6. Package area start
7. Destination area start
8. Newslatter area start
9. Gallary area start
10. testimonial area start
11. Guide area start
12. Blog area start
13. Footer area start
14. world map area start
15. About Wrapper start
16. Contact Wrapper start
17. FAQ wrapper start
*/
/*====================
    1. Global CSS
======================*/
/*Google Fonts*/
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.6.1/font/bootstrap-icons.css");
@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: "helveticaneue";
  src: url(../fonts/helveticaneuebold.ttf) format("truetype");
}

@font-face {
  font-family: "helveticaneueregular";
  src: url(../fonts/helveticaneueregular.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-Regular";
  src: url(../fonts/Poppins-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto-Regular";
  src: url(../fonts/Roboto-Regular.ttf) format("truetype");
}
/*CSS Variable*/
:root {
  /*color Variable*/
  --text-primary: #2d373c;
  --text-secondary: #454545;
  --text-tertiary: #666666;
  --c-primary: #ff4838;
  /*font Variable*/
  --f-primary: "Barlow", sans-serif;
  --f-secondary: "helveticaneue";
  --f-secondary-two: "helveticaneueregular";
  /*shadow Variable*/
  --shadow-one: 4px 8px 30px rgba(141, 141, 141, 0.1);
}

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
}
h1,h2,h3,h4,h5,h6{
  font-family:'Poppins-Regular';
}
label,a,ul,li,input,div,option,select{
  font-family:'Roboto-Regular';
}
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
#submitanad p:hover{
  color:#ff4838 !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
.thead{
  font-size:20px;
  font-weight:bold;
}
p {
  margin: 0;
  font-family:'Roboto-Regular';
  font-size: 16px;
}
@media print {
  body {
    background-color: #fff;
    color: #000;
  }
}
.noti{
  transform: rotate(50deg)
}
ol,
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

/* img {
  cursor: pointer;
} */

.pt-110 {
  padding-top: 110px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-95 {
  padding-top: 95px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-76 {
  padding-top: 76px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-20 {
  padding-top: 20px;
}

.pb-30 {
  padding-bottom: -30px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-118 {
  margin-top: 118px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-0 {
  margin-bottom: 0;
}

ins,
a {
  text-decoration: none !important;
}

/* *****
input's defult style
**** */
input {
  padding: 8px 15px;
  display: block;
  width: 100%;
  background: #ffffff;
  border: 1px solid #eee;
  border-radius: 5px;
  height: 45px;
}

input:focus {
  outline: none !important;
  border: 1px solid #c27c7c;
}

input[type="submit"] {
  background: #000000;
  border: none;
  color: #fff;
  padding: 10px 20px;
  border: 1px solid #000000;
  text-transform: uppercase;
}

input[type="submit"]:hover {
  background: #ffffff;
  color: #000000;
}

input[type="submit"]:focus {
  outline: none;
}

textarea {
  padding: 8px 10px;
  background: #ffffff;
  border-radius: 5px;
  display: block;
  width: 100%;
  border: 1px solid #eee;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

select {
  padding: 8px 10px;
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 5px;
  display: block;
  width: 100%;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  margin-bottom: 0;
  height: 45px;
}

select:focus {
  outline: none;
  border: 1px solid #ff5235 !important;
}

.hero-image-group img,
.featured-img img {
  cursor: initial;
}

input.input-field.check-in.react-datepicker-ignore-onclickoutside {
  border: 1px solid #ff4838;
}

/* *****
table's defult style
**** */
table tbody tr td,
table tbody tr th,
table thead tr td,
table thead tr th,
table tfoot tr td,
table tfoot tr th {
  border: 1px solid #eee;
  padding: 10px 10px;
}

/* *****
accordion defult style
**** */
.accordion-item.faq-accordion {
  margin-top: 30px;
  border-bottom: none;
}

.accordion-item.faq-accordion .accordion-header .accordion-button {
  background: #162b32;
  border-radius: 5px 5px 0px 0px;
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
  cursor: pointer;
  padding: 15px 15px 15px 20px;
}

.accordion-item.faq-accordion .accordion-header .accordion-button::after {
  background-image: none;
  content: "\ecad";
  font-family: boxicons;
  height: auto;
  width: auto;
}

.accordion-item.faq-accordion .accordion-header .accordion-button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.accordion-item.faq-accordion .accordion-collapse .accordion-body {
  background: #ffffff;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 0px 0px 5px 5px;
  padding: 20px 30px;
}

.accordion-item.faq-accordion .accordion-collapse .accordion-body p {
  font-size: 16px;
  line-height: 30px;
  color: #666666;
}

.accordion-item.faq-accordion .accordion-collapse .accordion-body .faq-list li {
  padding-top: 12px;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #2d373c;
}

.accordion-item.faq-accordion .accordion-collapse .accordion-body .faq-list li strong {
  font-weight: 600;
}

/*defult select*/
.select2-container--open .select2-dropdown--below {
  border: 1px solid #eee;
}

.select2-container--default .select2-selection--single {
  border: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  height: 30px;
  border: 1px solid #eee;
  border-radius: 0;
}

.select2-container--default .select2-results__group {
  font-size: 14px;
  color: var(--text-tertiary);
}

.select2-results__option {
  font-size: 13px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered,
.select2-container--default .select2-selection--single .select2-selection__placeholder {
  font-weight: 500;
  font-size: 12px;
  color: #666666;
}

/*defult calander*/
.calendar {
  padding: 8px;
  background: #ffffff;
  border-radius: 4px;
  font-size: 14px !important;
  border: none;
  -webkit-box-shadow: 0px 20px 40px rgba(59, 60, 56, 0.05);
  box-shadow: 0px 20px 40px rgba(59, 60, 56, 0.05);
}

.ui-datepicker {
  background: #ffffff;
}

.ui-datepicker-header {
  height: 50px;
  line-height: 50px;
  color: #ffffff;
  background: #ff4838;
}

.ui-datepicker-prev,
.ui-datepicker-next {
  width: 20px;
  height: 20px;
  text-indent: 9999px;
  border-radius: 100%;
  cursor: pointer;
  overflow: hidden;
  margin-top: 12px;
}

.ui-datepicker-prev {
  float: left;
  margin-left: 12px;
}

.ui-datepicker-prev:after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  margin: -43px 0px 0px 8px;
}

.ui-datepicker-next {
  float: right;
  margin-right: 12px;
}

.ui-datepicker-next:after {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  margin: -43px 0px 0px 6px;
}

.ui-datepicker-prev:after,
.ui-datepicker-next:after {
  content: "";
  position: absolute;
  display: block;
  width: 8px;
  height: 8px;
  border-left: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
}

.ui-datepicker-prev:hover,
.ui-datepicker-next:hover,
.ui-datepicker-prev:hover:after,
.ui-datepicker-next:hover:after {
  border-color: #eee;
}

.ui-datepicker-title {
  text-align: center;
  font-size: 18px;
}

.ui-datepicker-calendar {
  width: 100%;
  text-align: center;
}

.ui-datepicker-calendar tr td,
.ui-datepicker-calendar table tbody tr th,
.ui-datepicker-calendar table thead tr td,
.ui-datepicker-calendar table thead tr th,
.ui-datepicker-calendar table tfoot tr td,
.ui-datepicker-calendar table tfoot tr th {
  padding: 0 !important;
}

.ui-datepicker-calendar thead tr th span {
  display: block;
  width: 15px;
  color: #ff4838;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
}

.ui-state-default {
  display: block;
  text-decoration: none;
  color: #333333;
  line-height: 2;
  font-size: 14px;
}

.ui-state-default:hover {
  color: #ffffff;
  background: #ff4838;
  border-radius: 50px;
  -webkit-transition: all 0.25s cubic-bezier(0.7, -0.12, 0.2, 1.12);
  transition: all 0.25s cubic-bezier(0.7, -0.12, 0.2, 1.12);
}

.ui-state-highlight {
  color: #ffffff;
  background-color: #ff4838;
  border-radius: 0;
}

.ui-state-active {
  color: #ffffff;
  background-color: #ff4838;
  border-radius: 50px;
}

.ui-datepicker-unselectable .ui-state-default {
  color: #eee;
  border: 2px solid transparent;
}

/*Preloder CSS*/
.preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: #ffffff;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@-webkit-keyframes loader {

  0%,
  10%,
  100% {
    width: 80px;
    height: 80px;
  }

  65% {
    width: 150px;
    height: 150px;
  }
}

@keyframes loader {

  0%,
  10%,
  100% {
    width: 80px;
    height: 80px;
  }

  65% {
    width: 150px;
    height: 150px;
  }
}

@-webkit-keyframes loaderBlock {

  0%,
  30% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  55% {
    background-color: #ff4838;
  }

  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@keyframes loaderBlock {

  0%,
  30% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  55% {
    background-color: #ff4838;
  }

  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@-webkit-keyframes loaderBlockInverse {

  0%,
  20% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  55% {
    background-color: #ff4838;
  }

  100% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
}

@keyframes loaderBlockInverse {

  0%,
  20% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  55% {
    background-color: #ff4838;
  }

  100% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
}

.preloader .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  -webkit-transform: translate(-50%, -50%) rotate(45deg) translate3d(0, 0, 0);
  transform: translate(-50%, -50%) rotate(45deg) translate3d(0, 0, 0);
  -webkit-animation: loader 1.2s infinite ease-in-out;
  animation: loader 1.2s infinite ease-in-out;
}

.preloader .loader span {
  position: absolute;
  display: block;
  width: 40px;
  height: 40px;
  background-color: #ff4838;
  -webkit-animation: loaderBlock 1.2s infinite ease-in-out both;
  animation: loaderBlock 1.2s infinite ease-in-out both;
}

.preloader .loader span:nth-child(1) {
  top: 0;
  left: 0;
}

.preloader .loader span:nth-child(2) {
  top: 0;
  right: 0;
  -webkit-animation: loaderBlockInverse 1.2s infinite ease-in-out both;
  animation: loaderBlockInverse 1.2s infinite ease-in-out both;
}

.preloader .loader span:nth-child(3) {
  bottom: 0;
  left: 0;
  -webkit-animation: loaderBlockInverse 1.2s infinite ease-in-out both;
  animation: loaderBlockInverse 1.2s infinite ease-in-out both;
}

.preloader .loader span:nth-child(4) {
  bottom: 0;
  right: 0;
}

/* =============================
         Global CSS end
==============================*/
/* =============================
  2. Reuseable Components Start
==============================*/
.custom-input-group {
  margin-top: 30px;
  position: relative;
}

.custom-input-group i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 15px;
  z-index: 1;
}

.custom-input-group label {
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  color: #2d373c;
}

.custom-input-group input,
.custom-input-group textarea {
  padding: 15px 20px;
}

.custom-input-group input::-webkit-input-placeholder,
.custom-input-group textarea::-webkit-input-placeholder {
  font-weight: 500;
  font-size: 13px;
  color: #2d373c;
}

.custom-input-group input:-ms-input-placeholder,
.custom-input-group textarea:-ms-input-placeholder {
  font-weight: 500;
  font-size: 13px;
  color: #2d373c;
}

.custom-input-group input::-ms-input-placeholder,
.custom-input-group textarea::-ms-input-placeholder {
  font-weight: 500;
  font-size: 13px;
  color: #2d373c;
}

.custom-input-group input::placeholder,
.custom-input-group textarea::placeholder {
  font-weight: 500;
  font-size: 13px;
  color: #2d373c;
}

.custom-input-group input {
  background: rgba(245, 245, 245, 0.7);
  border: 1px solid rgba(255, 72, 56, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* border-radius: 100px; */
  height: 53px;
  margin-top: 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-input-group input:focus {
  border: 1px solid #ff4838;
}

.custom-input-group select {
  background: rgba(245, 245, 245, 0.7);
  border: 1px solid rgba(255, 72, 56, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* border-radius: 100px; */
  height: 53px;
  margin-top: 14px;
  padding: 15px 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
}

.custom-input-group select:focus {
  border: 1px solid #ff4838;
}

.custom-input-group textarea {
  background: rgba(245, 245, 245, 0.7);
  border: 1px solid rgba(255, 72, 56, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 30px;
}

.custom-input-group textarea:focus {
  border: 1px solid #ff4838;
}

.custom-input-group button[type="submit"],.custom-input-group button[type="buttons"] {
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  background: #ff4838;
  border-radius: 5px;
  border: none;
  padding: 14px 38px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.custom-input-group button[type="submit"]:hover,.custom-input-group button[type="buttons"]:hover {
  background: #162b32;
  color: #ff4838;
}

.section-head-alpha {
  max-width: 710px;
  padding-bottom: 50px;
}

.section-head-alpha h2 {
  font-weight: 800;
  font-size: 35px;
  line-height: 42px;
  color: #2d373c;
}

.section-head-alpha p {
  padding-top: 10px;
  font-size: 20px;
  color: #0a0a0a;
}

.section-head-gamma {
  text-align: center;
  padding-bottom: 50px;
}

.section-head-gamma h2 {
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  color: #2d373c;
  font-family:'Roboto-Regular';
}

/*Reuseable Buttons*/
.button-fill-primary {
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  background: #ff4838;
  display: inline-block;
  padding: 10px 25px;
  border: 2px solid #ff4838;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.button-fill-primary:hover {
  background: transparent;
  color: #ff4838;
}

.button-outlined-primary {
  font-weight: 600;
  font-size: 18px;
  color: #ff4838;
  background: transparent;
  display: inline-block;
  padding: 9px 25px;
  border: 2px solid #ff4838;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.button-outlined-primary:hover {
  background: #ff4838;
  color: #fff;
}

.button-fill-round {
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  background: #ff4838;
  border-radius: 30px;
  display: inline-block;
  padding: 10px 40px;
  border: 2px solid #ff4838;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

.button-fill-round:hover {
  color: #ff4838;
  background: transparent;
}

/*swiper buttons*/
.custom-swiper-next,
.custom-swiper-prev {
  background: transparent;
  border: 2px solid #ffb6af;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #ffb6af;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0.7;
}

.custom-swiper-next:hover,
.custom-swiper-prev:hover {
  opacity: 1;
  border: 2px solid #ff4838;
}

.custom-swiper-next:hover i,
.custom-swiper-prev:hover i {
  color: #ff4838;
}

.custom-swiper-next i,
.custom-swiper-prev i {
  color: #ffb6af;
  font-size: 16px;
}

.custom-swiper-next-sm,
.custom-swiper-prev-sm {
  background: #ffffff;
  border: 1px solid #ff4838;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.custom-swiper-next-sm i,
.custom-swiper-prev-sm i {
  color: #ff4838;
  font-size: 14px;
}

.slider-arrows .custom-swiper-prev {
  margin-right: 50px;
}

/*Reuseable breadcrumb*/
.breadcrumb {
  margin-bottom: unset;
}

.breadcrumb-style-one {
  background-image: url(../../../public/images/banner/breadcrumb-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 150px 0;
}

.breadcrumb-style-two {
  background-image: url(../../../public/images/banner/My_account_background.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 150px 0;
}

.breadcrumb-style-three {
  background-image: url(../../../public/images/banner/Cobagage_ads_background.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 150px 0;
}

.breadcrumb-style-four {
  background-image: url(../../../public/images/banner/Submit_an_ad_background.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 150px 0;
}

.breadcrumb-style-five {
  background-image: url(../../../public/images/banner/Cobagage_club_background.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 150px 0;
}

.breadcrumb-style-six {
  background-image: url(../../../public/images/banner/subplan.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 150px 0;
}

.breadcrumb-style-seven {
  background-image: url(../../../public/images/banner/basket.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 150px 0;
}

.breadcrumb-style-eight {
  background-image: url(../../../public/images/banner/Cobagage_as_a_job_background.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 150px 0;
}

.breadcrumb-style-nine {
  background-image: url(../../../public/images/banner/Why_choose_us_background.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 150px 0;
}

.breadcrumb-style-ten {
  background-image: url(../../../public/images/banner/FAQ_background.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 150px 0;
}

.breadcrumb-style-eleven {
  background-image: url(../../../public/images/banner/Article_ads_background.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 150px 0;
}

.breadcrumb-style-twelve {
  background-image: url(../../../public/images/banner/Picture2.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 150px 0;
}

.breadcrumb-style-thirteen {
  background-image: url(../../../public/images/banner/Picture3.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 150px 0;
}

.breadcrumb-style-fourteen {
  background-image: url(../../../public/images/banner/Picture4.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 150px 0;
}

.breadcrumb-style-fifteen {
  background-image: url(../../../public/images/banner/Picture1.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 150px 0;
}

.breadcrumb-style-sixteen {
  background-image: url(../../../public/images/banner/Picture5.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 150px 0;
}

.breadcrumb-style-one .breadcrumb-title {
  font-weight: 700;
  font-size: 50px;
  color: #ffffff;
}

.breadcrumb-style-one .breadcrumb-items .breadcrumb-item {
  font-size: 20px;
  color: #ffffff;
  position: relative;
}

.breadcrumb-style-one .breadcrumb-items .breadcrumb-item.active {
  position: relative;
}

.breadcrumb-style-one .breadcrumb-items .breadcrumb-item.active::before {
  content: "\f280";
  font-family: bootstrap-icons !important;
  color: #ff4838;
  font-size: 12px;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  padding-right: 12px;
  padding-left: 8px;
}

.breadcrumb-style-one .breadcrumb-items .breadcrumb-item a {
  color: inherit;
}

/*Reuseable pagination*/
.pagination-style-one .page-item {
  margin-right: 20px;
}

.pagination-style-one .page-item:last-child {
  margin-right: 0;
}

.pagination-style-one .page-item .page-link:hover {
  background-color: #ff4838;
  color: #fff;
}

.pagination-style-one .page-item.page-arrow a {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background: #ff4838;
  color: #fff;
  border-color: #ff4838;
}

.pagination-style-one .page-item.active a {
  background: #ff4838;
  color: #fff;
  border-color: #ff4838;
}

.pagination-style-one .page-item a {
  border: 1px solid #ff4838;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.05em;
  color: #2d373c;
  padding: 7px 10px;
  min-height: 40px;
  min-width: 40px;
}

.pagination-style-one .page-item a:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* error wrapper*/
.error-content .error-text {
  margin-top: 50px;
}

.error-content .error-text h2 {
  font-weight: 700;
  font-size: 50px;
  color: #2d373c;
}

.error-content .error-text p {
  padding-top: 20px;
  font-size: 16px;
  line-height: 30px;
  color: #666666;
}

.error-content .error-text .error-btn {
  margin-top: 50px;
}

.error-content .error-text .error-btn a {
  display: inline-block;
  font-weight: 700;
  font-size: 26px;
  color: #ffffff;
  background: #ff4838;
  border-radius: 10px;
  padding: 15px 35px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.error-content .error-text .error-btn a:hover {
  background: #162b32;
  color: #ff4838;
}

.error-content .error-text .error-btn a i {
  margin-right: 5px;
}

header.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 21;
  padding: 0;
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-box-shadow: 0 2px 28px 0 rgba(122, 121, 121, 0.1);
  box-shadow: 0 2px 28px 0 rgba(122, 121, 121, 0.1);
  background: #fcfcfc;
  background-repeat: no-repeat;
  background-size: cover;
}

.main-searchbar-wrapper {
  position: fixed;
  width: 100%;
  min-height: 100vh;
  inset: 0;
  background: rgba(45, 55, 60, 0.8);
  z-index: 20;
  display: -ms-grid;
  display: grid;
  place-items: center;
  padding: 50px 0;
  -webkit-transition: all 0.8s cubic-bezier(0.32, 0, 0, 1);
  transition: all 0.8s cubic-bezier(0.32, 0, 0, 1);
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.main-searchbar-wrapper.search-active {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.category-sidebar-wrapper {
  position: fixed;
  width: 100%;
  min-height: 100vh;
  inset: 0;
  z-index: 20;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
}

.category-sidebar-wrapper.category-active {
  opacity: 1;
  visibility: visible;
}

.category-sidebar-wrapper.category-active .category-sidebar {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.category-sidebar-wrapper .category-sidebar {
  background: #ffffff;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  height: 100%;
  max-width: 490px;
  padding: 40px 30px;
  overflow-y: scroll;
  -webkit-transition: all 0.6s cubic-bezier(0.32, 0, 0, 1);
  transition: all 0.6s cubic-bezier(0.32, 0, 0, 1);
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.category-sidebar-wrapper .category-sidebar::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #fff;
}

.category-sidebar-wrapper .category-sidebar::-webkit-scrollbar {
  width: 12px;
  background-color: white;
}

.category-sidebar-wrapper .category-sidebar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #eee;
}

.category-sidebar-wrapper .category-sidebar .category-header h4 {
  font-weight: 600;
  font-size: 25px;
  font-weight: 600;
  color: #2d373c;
}

.category-sidebar-wrapper .category-sidebar .category-header .category-toggle i {
  font-size: 20px;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background: #ff4838;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.category-sidebar-wrapper .category-sidebar .category-box {
  background: #ffffff;
  border: 1px solid #eeeeee;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
  display: block;
  padding: 15px;
  padding-top: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.category-sidebar-wrapper .category-sidebar .category-box:hover {
  background: #ff4838;
}

.category-sidebar-wrapper .category-sidebar .category-box:hover h5 {
  color: #fff;
}

.category-sidebar-wrapper .category-sidebar .category-box .cate-icon {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background: #ffffff;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: -22px;
}

.category-sidebar-wrapper .category-sidebar .category-box .cate-icon img {
  max-width: 100%;
}

.category-sidebar-wrapper .category-sidebar .category-box h5 {
  font-weight: 600;
  font-size: 15px;
  color: #494949;
  margin-top: 14px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.sidebar-main {
  background: #ffffff;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  width: 100%;
  height: 100vh;
  top: 131px;
}

.sidebar-main .sidebar-list {
  gap: 60px;
}

.sidebar-main .sidebar-list li i {
  width: 50px;
  height: 50px;
  background: #ffffff;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
  color: #ff4838;
}

.sidebar-main .sidebar-list li i:hover {
  color: #fff;
  background: #ff4838;
}

/* =============================
   Reuseable Components end
==============================*/
/* =============================
    3. Topbar area start 
==============================*/
.topbar-style-one {
  background: #2d373c;
  position: relative;
  z-index: 1;
}

.topbar-style-one .topbar-contact-left .contact-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.topbar-style-one .topbar-contact-left .contact-list li {
  margin-right: 20px;
  font-weight: 600;
  font-size: 13px;
  color: #ffffff;
}

.topbar-style-one .topbar-contact-left .contact-list li a {
  color: inherit;
}

.topbar-style-one .topbar-contact-left .contact-list li:last-child {
  margin-right: 0;
}

.topbar-style-one .topbar-contact-left .contact-list li i {
  margin-right: 5px;
}

.topbar-style-one .topbar-ad a {
  display: inline-block;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
  background: #ff4838;
  padding: 13px 50px;
}

.topbar-style-one .topbar-social-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.topbar-style-one .topbar-social-links li {
  margin-right: 20px;
}

.topbar-style-one .topbar-social-links li:last-child {
  margin-right: 0;
}

.topbar-style-one .topbar-social-links li i {
  color: #ff4838;
  font-size: 20px;
}

.topbar-style-one .topbar-social-links li i::before {
  vertical-align: middle;
}

/* =============================
    Topbar area end
==============================*/
/* =============================
    4. Navbar area start 
==============================*/
.inner-logo {
  padding-top: 40px;
}

.inner-contact-options .contact-box-inner {
  font-weight: 600;
  font-size: 15px;
  color: #2d373c;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 20px;
  padding-top: 8px;
}

.inner-contact-options .contact-box-inner a {
  color: inherit;
  display: inline-block;
}

.inner-contact-options .contact-box-inner i {
  margin-right: 10px;
  color: #2d373c;
}

/*header-style-three*/
header .header-style-three {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
  padding-left: 2%;
  padding-right: 2%;
  width: 100%;
  background: rgba(25, 26, 28, 0.6);
}

header .header-style-three .main-nav ul li .sub-menu {
  position: absolute;
  left: 0;
  top: auto;
  min-width: 200px;
  list-style: none;
  background: rgba(25, 26, 28, 0.6);
  opacity: 0;
  z-index: 9999;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-box-shadow: 0px 0px 65px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 65px 0px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid var(--c-primary);
}

header .header-style-three .main-nav ul li .sub-menu li .sub-menu {
  right: -200px;
  left: inherit;
  top: 0;
}

header .header-style-three .main-nav ul li .sub-menu>li {
  display: block;
  margin: 0;
  position: relative;
}

header .header-style-three .main-nav ul>li a:hover {
  background: transparent;
  color: #ff4838 !important;
}

header .header-style-three .main-nav .fl {
  width: 30px;
  font-size: 20px;
  line-height: 35px;
  text-align: center;
  color: #2d373c;
  font-style: normal;
  position: absolute;
  right: -5px;
  top: 0;
  z-index: 999;
  display: none;
  cursor: pointer;
}

header .header-style-three .main-nav .fl:before {
  font-size: 14px;
  text-align: center;
  line-height: 35px;
}

header .header-style-three .main-nav>ul>li+li>.sub-menu {
  left: 15px;
}

header .header-style-three .main-nav>ul>li:hover>.sub-menu {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  opacity: 1;
}

header .header-style-three .main-nav ul li .sub-menu li:hover>.sub-menu,
header .header-style-three .main-nav ul li .sub-menu li .sub-menu li:hover>.sub-menu {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

header .header-style-three .main-nav ul {
  margin: 0;
  list-style: none;
}

header .header-style-three .main-nav ul li {
  display: inline-block;
  position: relative;
  padding: 0 20px;
}

header .header-style-three .main-nav ul li:last-child {
  padding-right: 0;
}

header .header-style-three .main-nav ul li:hover>a {
  color: #ff4838;
}

header .header-style-three .main-nav ul li:hover>a::before {
  opacity: 1;
  background: #ff4838;
}

header .header-style-three .main-nav ul li a {
  font-size: 16px;
  color: #fff;
  display: block;
  font-weight: 500;
  text-transform: capitalize;
  text-decoration: none;
  font-family:'Roboto-Regular';
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 30.5px 0;
  position: relative;
}

header .header-style-three .main-nav ul li ul.sub-menu>li {
  padding: 0;
}

header .header-style-three .main-nav ul li ul.sub-menu>li:last-child {
  border-bottom: none;
}

header .header-style-three .main-nav ul li ul.sub-menu>li a {
  display: block;
  padding: 15px;
  color: #fff;
  line-height: 1;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

header .header-style-three .main-nav ul li ul.sub-menu>li a.active {
  color: #ff4838;
  font-weight: 600;
}

header .header-style-three .main-nav ul li ul.sub-menu>li a:hover {
  background: #ff4838;
  color: #ffff !important;
}

header .header-style-three .main-nav ul li ul.sub-menu>li a:hover::before {
  opacity: 0;
}

header .header-style-three .main-nav ul li.has-child-menu:after {
  position: absolute;
  right: 9px;
  color: #ff4838;
  content: "";
  font-family: bootstrap-icons !important;
  content: "\f64d";
  font-size: 12px;
  font-weight: 600;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  top: 36px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

header .header-style-three .main-nav ul li:hover.has-child-menu:after {
  content: "\F2EA";
}

header .header-style-three .main-nav>ul>li>a.active {
  color: #ff4838;
  font-weight: 600;
  font-size: 16px;
}

header .header-style-three .nav-right-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

header .header-style-three .nav-right-icons .user-dropdown {
  position: relative;
}

header .header-style-three .nav-right-icons .user-dropdown .user-drop-list {
  background: #000;
  position: absolute;
  z-index: 2;
  min-width: 190px;
  padding: 25px 20px;
  color: #ffff;
  right: 0;
  top: 57px;
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: 100% 0%;
  transform-origin: 100% 0%;
  visibility: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

header .header-style-three .nav-right-icons .user-dropdown .user-drop-list.account-drop-active {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
  z-index: 6;
}

header .header-style-three .nav-right-icons .user-dropdown .user-drop-list li {
  margin-top: 10px;
}

header .header-style-three .nav-right-icons .user-dropdown .user-drop-list li:first-child {
  margin-top: 0;
}

header .header-style-three .nav-right-icons .user-dropdown .user-drop-list li a {
  color: #a6a6a6;
  font-size: 14px;
  font-weight: 500;
}

header .header-style-three .nav-right-icons .user-dropdown .user-drop-list li a:hover {
  color: var(--c-primary);
}

header .header-style-three .nav-right-icons div {
  margin-right: 10px;
}

header .header-style-three .nav-right-icons div i {
  height: 30px;
  width: 30px;
  background: #ff4838;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  border: 1px solid #ff4838;
  font-size: 18px;
  border-radius: 50%;
  cursor: pointer;
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

header .header-style-three .nav-right-icons div i:hover {
  border: 1px solid #ff4838;
  color: #ff4838;
  background: transparent;
}

header .header-style-three .nav-right {
  float: right;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}

header .header-style-three .nav-right .nav-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

header .header-style-three .nav-right .nav-actions li {
  margin-right: 30px;
}

header .header-style-three .nav-right .nav-actions li:last-child {
  margin-right: 0;
}

header .header-style-three .nav-right .nav-actions li a {
  font-size: 20px;
  color: var(--c-primary-one);
}

header .header-style-three .nav-right .nav-right-hotline {
  padding-left: 44px;
  padding-right: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
}

header .header-style-three .nav-right .nav-right-hotline .hotline-icon i {
  color: #fff;
  font-size: 30px;
  color: #ff4838;
}

header .header-style-three .nav-right .nav-right-hotline .hotline-info {
  margin-left: 10px;
}

header .header-style-three .nav-right .nav-right-hotline .hotline-info span {
  font-weight: 600;
  font-size: 12px;
  color: #ffffff;
  margin-top: -3px;
  display: block;
}

header .header-style-three .nav-right .nav-right-hotline .hotline-info h6 {
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  color: #fff;
}

header .header-style-three .nav-right .nav-right-hotline .hotline-info h6 a {
  color: inherit;
}

header .header-style-four {
  position: absolute;
  z-index: 9;
  padding-left: 2%;
  padding-right: 2%;
  /* background: rgba(3, 0, 19, 0.6); */
  background: #fff;
  width: 100%;
  border-color: rgba(3, 0, 19, 0.6);
}

header .header-style-four .main-nav ul li .sub-menu {
  position: absolute;
  left: 0;
  top: auto;
  min-width: 200px;
  list-style: none;
  background: rgba(3, 0, 19, 0.6);
  opacity: 0;
  z-index: 9999;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-box-shadow: 0px 0px 65px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 65px 0px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid var(--c-primary);
}

header .header-style-four .main-nav ul li .sub-menu li .sub-menu {
  right: -200px;
  left: inherit;
  top: 0;
}

header .header-style-four .main-nav ul li .sub-menu>li {
  display: block;
  margin: 0;
  position: relative;
}

header .header-style-four .main-nav ul>li a:hover {
  background: transparent;
  color: #ff4838 !important;
}

header .header-style-four .main-nav .fl {
  width: 30px;
  font-size: 20px;
  line-height: 35px;
  text-align: center;
  color: #2d373c;
  font-style: normal;
  position: absolute;
  right: -5px;
  top: 0;
  z-index: 999;
  display: none;
  cursor: pointer;
}

header .header-style-four .main-nav .fl:before {
  font-size: 14px;
  text-align: center;
  line-height: 35px;
}

header .header-style-four .main-nav>ul>li+li>.sub-menu {
  left: 15px;
}

header .header-style-four .main-nav>ul>li:hover>.sub-menu {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  opacity: 1;
}

header .header-style-four .main-nav ul li .sub-menu li:hover>.sub-menu,
header .header-style-four .main-nav ul li .sub-menu li .sub-menu li:hover>.sub-menu {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

header .header-style-four .main-nav ul {
  margin: 0;
  list-style: none;
}

header .header-style-four .main-nav ul li {
  display: inline-block;
  position: relative;
  padding: 0 20px;
}

header .header-style-four .main-nav ul li:last-child {
  padding-right: 0;
}

header .header-style-four .main-nav ul li:hover>a {
  color: #ff4838;
}

header .header-style-four .main-nav ul li:hover>a::before {
  opacity: 1;
  background: #ff4838;
}

header .header-style-four .main-nav ul li a {
  font-size: 16px;
  /* color: #fff; */
  color: #2d373c;
  display: block;
  font-weight: 500;
  text-transform: capitalize;
  text-decoration: none;
  font-family:'Roboto-Regular';
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 30.5px 0;
  position: relative;
}

header .header-style-four .main-nav ul li ul.sub-menu>li {
  padding: 0;
}

header .header-style-four .main-nav ul li ul.sub-menu>li:last-child {
  border-bottom: none;
}

header .header-style-four .main-nav ul li ul.sub-menu>li a {
  display: block;
  padding: 15px;
  color: #fff;
  line-height: 1;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

header .header-style-four .main-nav ul li ul.sub-menu>li a.active {
  color: #ff4838;
  font-weight: 600;
}

header .header-style-four .main-nav ul li ul.sub-menu>li a:hover {
  background: #ff4838;
  color: #ffff !important;
}

header .header-style-four .main-nav ul li ul.sub-menu>li a:hover::before {
  opacity: 0;
}

header .header-style-four .main-nav ul li.has-child-menu:after {
  position: absolute;
  right: 9px;
  color: #ff4838;
  content: "";
  font-family: bootstrap-icons !important;
  content: "\f64d";
  font-size: 12px;
  font-weight: 600;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  top: 36px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

header .header-style-four .main-nav ul li:hover.has-child-menu:after {
  content: "\F2EA";
}

header .header-style-four .main-nav>ul>li>a.active {
  color: #ff4838;
  font-weight: 600;
  font-size: 16px;
}

header .header-style-four .nav-right-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

header .header-style-four .nav-right-icons .user-dropdown {
  position: relative;
}

header .header-style-four .nav-right-icons .user-dropdown .user-drop-list {
  background: #000;
  position: absolute;
  z-index: 2;
  min-width: 190px;
  padding: 25px 20px;
  color: #ffff;
  right: 0;
  top: 57px;
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: 100% 0%;
  transform-origin: 100% 0%;
  visibility: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

header .header-style-four .nav-right-icons .user-dropdown .emailuser-drop-list {
  background: #000;
  position: absolute;
  z-index: 2;
  min-width: 1000px;
  padding: 15px;
  color: #ffff;
  right: -225px;
  top: 57px;
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: 100% 0%;
  transform-origin: 100% 0%;
  visibility: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

header .header-style-four .nav-right-icons .user-dropdown .user-drop-list.account-drop-active {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
  z-index: 6;
}

header .header-style-four .nav-right-icons .user-dropdown .emailuser-drop-list.account-drop-active {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
  z-index: 6;
}

header .header-style-four .nav-right-icons .user-dropdown .emailusermobile-drop-list.account-drop-active {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
  z-index: 6;
}

header .header-style-four .nav-right-icons .user-dropdown .user-drop-list li {
  margin-top: 10px;
}

header .header-style-four .nav-right-icons .user-dropdown .user-drop-list li:first-child {
  margin-top: 0;
}

header .header-style-four .nav-right-icons .user-dropdown .user-drop-list li a {
  color: #a6a6a6;
  font-size: 14px;
  font-weight: 500;
}

header .header-style-four .nav-right-icons .user-dropdown .user-drop-list li a:hover {
  color: var(--c-primary);
}

header .header-style-four .nav-right-icons div {
  margin-right: 10px;
}

header .header-style-four .nav-right-icons div i {
  height: 30px;
  width: 30px;
  background: #ff4838;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  border: 1px solid #ff4838;
  font-size: 18px;
  border-radius: 50%;
  cursor: pointer;
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

header .header-style-four .nav-right-icons div i:hover {
  border: 1px solid #ff4838;
  color: #ff4838;
  background: transparent;
}

header .header-style-four .nav-right {
  float: right;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}

header .header-style-four .nav-right .nav-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

header .header-style-four .nav-right .nav-actions li {
  margin-right: 30px;
}

header .header-style-four .nav-right .nav-actions li:last-child {
  margin-right: 0;
}

header .header-style-four .nav-right .nav-actions li a {
  font-size: 20px;
  color: var(--c-primary-one);
}

header .header-style-four .nav-right .nav-right-hotline {
  padding-left: 44px;
  padding-right: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
}

header .header-style-four .nav-right .nav-right-hotline .hotline-icon i {
  color: #fff;
  font-size: 30px;
  color: #ff4838;
}

header .header-style-four .nav-right .nav-right-hotline .hotline-info {
  margin-left: 10px;
}

header .header-style-four .nav-right .nav-right-hotline .hotline-info span {
  font-weight: 600;
  font-size: 12px;
  color: #ffffff;
  margin-top: -3px;
  display: block;
}

header .header-style-four .nav-right .nav-right-hotline .hotline-info h6 {
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  color: #fff;
}

header .header-style-four .nav-right .nav-right-hotline .hotline-info h6 a {
  color: inherit;
}

.hero-style-three {
  overflow: hidden;
}

.hero-style-three .banner-plane {
  position: absolute;
  top: 25%;
  left: 5%;
  z-index: 2;
  -webkit-animation: fly 12s linear infinite;
  animation: fly 12s linear infinite;
  opacity: 0.5;
}

.wallet {
  width: 330px !important;
}

@media (max-width: 576px) {
  .hero-style-three .banner-plane {
    display: none;
    visibility: hidden;
  }

  .wallet {
    width: 303px !important;
  }
}

@-webkit-keyframes fly {
  0% {
    -webkit-transform: translateX(0) scale(0.5);
    transform: translateX(0) scale(0.5);
    opacity: 0;
  }

  50% {
    -webkit-transform: translateX(50%) scale(0.8);
    transform: translateX(50%) scale(0.8);
    opacity: 0.8;
  }

  100% {
    -webkit-transform: translateX(100%) scale(0.5);
    transform: translateX(100%) scale(0.5);
    opacity: 0;
  }
}

@keyframes fly {
  0% {
    -webkit-transform: translateX(0) scale(0.5);
    transform: translateX(0) scale(0.5);
    opacity: 0;
  }

  50% {
    -webkit-transform: translateX(50%) scale(0.8);
    transform: translateX(50%) scale(0.8);
    opacity: 0.8;
  }

  100% {
    -webkit-transform: translateX(100%) scale(0.5);
    transform: translateX(100%) scale(0.5);
    opacity: 0;
  }
}

.hero-style-three .slider-arrows {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 60px;
  z-index: 2;
  padding: 5px;
}

.hero-style-three .slider-arrows .hero-prev3 {
  width: 35px;
  height: 35px;
  line-height: 32px;
  text-align: center;
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.hero-style-three .slider-arrows .hero-prev3:hover {
  background: var(--c-primary);
  border: 1px solid var(--c-primary);
  color: #fff;
}

.hero-style-three .slider-arrows .hero-next3 {
  width: 35px;
  height: 35px;
  line-height: 33px;
  text-align: center;
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 50%;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.hero-style-three .slider-arrows .hero-next3:hover {
  background: var(--c-primary);
  border: 1px solid var(--c-primary);
  color: #fff;
}

.hero-style-three .hero-social {
  position: absolute;
  top: 50%;
  left: -90px;
  z-index: 2;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.hero-style-three .hero-social .social-list li {
  position: relative;
  display: inline-block;
  padding-left: 20px;
}

.hero-style-three .hero-social .social-list li::before {
  content: url("../../../public/images/icons/list-style.svg");
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0px;
  z-index: 1;
}

.hero-style-three .hero-social .social-list a {
  color: #fff;
}

.hero-style-three .slider-bg-1 {
  padding: 300px 0px;
  width: 100%;
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      from(rgba(0, 5, 15, 0.45)),
      to(rgba(0, 5, 15, 0.45))),
    url("../../../public/images/banner/home3-banner1.png");
  background: linear-gradient(rgba(0, 5, 15, 0.45), rgba(0, 5, 15, 0.45)),
    url("../../../public/images/banner/home3-banner1.png");
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) and (max-width: 991px) {
  .hero-style-three .slider-bg-1 {
    padding: 250px 0px;
  }
}

@media (max-width: 767px) {
  .hero-style-three .slider-bg-1 {
    padding: 200px 0px;
  }
}

.hero-style-three .slider-bg-3 {
  padding: 300px 0px;
  width: 100%;
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      from(rgba(0, 5, 15, 0.45)),
      to(rgba(0, 5, 15, 0.45))),
    url("../../../public/images/banner/home3-banner3.png");
  background: linear-gradient(rgba(0, 5, 15, 0.45), rgba(0, 5, 15, 0.45)),
    url("../../../public/images/banner/home3-banner3.png");
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) and (max-width: 991px) {
  .hero-style-three .slider-bg-3 {
    padding: 250px 0px;
  }
}

@media (max-width: 767px) {
  .hero-style-three .slider-bg-3 {
    padding: 200px 0px;
  }
}

.hero-style-three .hero3-content {
  text-align: center;
}

.hero-style-three .hero3-content .title-top-text {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 5px;
  display: block;
  -webkit-animation: unset;
  animation: unset;
}

.hero-style-three .hero3-content .title-top {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}

.hero-style-three .hero3-content h1 {
  font-size: 70px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 25px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .hero-style-three .hero3-content h1 {
    font-size: 60px;
  }
}

@media (max-width: 767px) {
  .hero-style-three .hero3-content h1 {
    font-size: 50px;
  }
}

.hero-style-three .hero3-content p {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 45px;
}

.hero-style-three .swiper-slide-active h1 {
  -webkit-animation: fadeInDown 1.5s;
  animation: fadeInDown 1.5s;
}

.hero-style-three .swiper-slide-active p {
  -webkit-animation: fadeInUp 1.5s;
  animation: fadeInUp 1.5s;
}

.hero-style-three .swiper-slide-active .banner3-btn {
  -webkit-animation: zoomIn 2s;
  animation: zoomIn 2s;
}

.hero-style-four {
  position: relative;
  overflow: hidden;
}

.hero-style-four .banner-star3 {
  position: absolute;
  bottom: 28%;
  left: 5%;
  z-index: 9;
  -webkit-animation: rotation 8s linear infinite;
  animation: rotation 8s linear infinite;
}

@-webkit-keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.hero-style-four .banner-star2 {
  position: absolute;
  top: 15%;
  right: 22%;
  z-index: 9;
  -webkit-animation: blink 2s linear infinite alternate;
  animation: blink 2s linear infinite alternate;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.hero-style-four .banner-star1 {
  position: absolute;
  top: 15%;
  right: 25%;
  z-index: 9;
  -webkit-animation: blink 2s linear infinite alternate;
  animation: blink 2s linear infinite alternate;
}

@-webkit-keyframes blink {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  50% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes blink {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  50% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.hero-style-four .banner-plane {
  position: absolute;
  top: 25%;
  left: 5%;
  z-index: 2;
  -webkit-animation: fly 12s linear infinite;
  animation: fly 12s linear infinite;
  opacity: 0.5;
}

@media (max-width: 576px) {
  .hero-style-four .banner-plane {
    display: none;
    visibility: hidden;
  }
}

@keyframes fly {
  0% {
    -webkit-transform: translateX(0) scale(0.5);
    transform: translateX(0) scale(0.5);
    opacity: 0;
  }

  50% {
    -webkit-transform: translateX(50%) scale(0.8);
    transform: translateX(50%) scale(0.8);
    opacity: 0.8;
  }

  100% {
    -webkit-transform: translateX(100%) scale(0.5);
    transform: translateX(100%) scale(0.5);
    opacity: 0;
  }
}

.hero-style-four .slider-arrows {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 60px;
  z-index: 2;
  padding: 5px;
}

.hero-style-four .slider-arrows .hero-prev3 {
  width: 35px;
  height: 35px;
  line-height: 32px;
  text-align: center;
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.hero-style-four .slider-arrows .hero-prev3:hover {
  background: var(--c-primary);
  border: 1px solid var(--c-primary);
  color: #fff;
}

.hero-style-four .slider-arrows .hero-next3 {
  width: 35px;
  height: 35px;
  line-height: 33px;
  text-align: center;
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 50%;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.hero-style-four .slider-arrows .hero-next3:hover {
  background: var(--c-primary);
  border: 1px solid var(--c-primary);
  color: #fff;
}

.hero-style-four .slider-bg-1 {
  padding: 300px 0px;
  width: 100%;
  aspect-ratio: 4/2;
  object-fit: contain;
  height: 50%;
  background: -webkit-gradient(linear,
      left top,
      right top,
      from(rgba(0, 0, 0, 0.45)),
      to(rgba(0, 0, 0, 0.45))),
    url("../../../public/images/banner/banner412.jpg");
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),
    url("../../../public/images/banner/banner412.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media (min-width: 768px) and (max-width: 991px) {
  .hero-style-four .slider-bg-1 {
    padding: 250px 0px;
  }
}

@media (max-width: 767px) {
  .hero-style-four .slider-bg-1 {
    padding: 200px 0px;
  }
}

.hero-style-four .slider-bg-2 {
  padding: 300px 0px;
  width: 100%;
  aspect-ratio: 4/2;
  object-fit: contain;
  height: 50%;
  background: -webkit-gradient(linear,
      left top,
      right top,
      from(rgba(0, 0, 0, 0.45)),
      to(rgba(0, 0, 0, 0.45))),
    url("../../../public/images/banner/banner422.jpg");
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),
    url("../../../public/images/banner/banner422.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media (min-width: 768px) and (max-width: 991px) {
  .hero-style-four .slider-bg-2 {
    padding: 250px 0px;
  }
}

@media (max-width: 767px) {
  .hero-style-four .slider-bg-2 {
    padding: 200px 0px;
  }
}

/* -------------------------------------for 3rd swiperSlide----------------------------------- */
.hero-style-four .slider-bg-3 {
  padding: 300px 0px;
  width: 100%;
  aspect-ratio: 4/2;
  object-fit: contain;
  height: 50%;
  background: -webkit-gradient(linear,
      left top,
      right top,
      from(rgba(0, 0, 0, 0.45)),
      to(rgba(0, 0, 0, 0.45))),
    url("../../../public/images/banner/banner43.jpg");
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),
    url("../../../public/images/banner/banner43.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: inherit;
}

@media (min-width: 768px) and (max-width: 991px) {
  .hero-style-four .slider-bg-3 {
    padding: 250px 0px;
  }
}

@media (max-width: 767px) {
  .hero-style-four .slider-bg-3 {
    padding: 200px 0px;
  }
}

/* ----------------------------------swiper slide 4---------------------------------------------------------- */

.hero-style-four .slider-bg-4 {
  padding: 300px 0px;
  width: 100%;
  aspect-ratio: 4/2;
  object-fit: contain;
  height: 50%;
  background: -webkit-gradient(linear,
      left top,
      right top,
      from(rgba(0, 0, 0, 0.45)),
      to(rgba(0, 0, 0, 0.45))),
    url("../../../public/images/banner/banner44.jpg");
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),
    url("../../../public/images/banner/banner44.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media (min-width: 768px) and (max-width: 991px) {
  .hero-style-four .slider-bg-4 {
    padding: 250px 0px;
  }
}

@media (max-width: 767px) {
  .hero-style-four .slider-bg-4 {
    padding: 200px 0px;
  }
}

/* ------------------------------------------------------------------------------------------------ */

.hero-style-four .hero4-content {
  text-align: left;
}

.hero-style-four .hero4-content .title-top-text {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 5px;
  display: block;
  -webkit-animation: unset;
  animation: unset;
}

.hero-style-four .hero4-content .title-top {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}

.hero-style-four .hero4-content h1 {
  font-size: 70px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 25px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .hero-style-four .hero4-content h1 {
    font-size: 60px;
  }
}

@media (max-width: 767px) {
  .hero-style-four .hero4-content h1 {
    font-size: 50px;
  }
}

.hero-style-four .hero4-content p {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 45px;
}

.hero-style-four .swiper-slide-active h1 {
  -webkit-animation: fadeInDown 1.5s;
  animation: fadeInDown 1.5s;
}

.hero-style-four .swiper-slide-active p {
  -webkit-animation: fadeInUp 1.5s;
  animation: fadeInUp 1.5s;
}

.hero-style-four .swiper-slide-active .banner3-btn {
  -webkit-animation: zoomIn 2s;
  animation: zoomIn 2s;
}

/*header-style-one*/
header .header-style-one {
  position: relative;
  z-index: 2;
  -webkit-transition: all 0.8s ease-out 0s;
  transition: all 0.8s ease-out 0s;
}

header .header-style-one .main-nav ul li .sub-menu {
  position: absolute;
  left: 0;
  top: auto;
  right: 0;
  min-width: 200px;
  list-style: none;
  margin: 0;
  padding: 0;
  background: #fff;
  opacity: 0;
  z-index: 99;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-box-shadow: 0px 0px 65px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 65px 0px rgba(0, 0, 0, 0.1);
}

header .header-style-one .main-nav ul li .sub-menu li .sub-menu {
  right: -200px;
  left: inherit;
  top: 0;
}

header .header-style-one .main-nav ul li .sub-menu>li {
  display: block;
  margin: 0;
  position: relative;
}

header .header-style-one .main-nav ul>li a:hover {
  background: transparent;
  color: #ff4838 !important;
}

header .header-style-one .main-nav .fl {
  width: 30px;
  font-size: 20px;
  line-height: 35px;
  text-align: center;
  color: #2d373c;
  font-style: normal;
  position: absolute;
  right: -5px;
  top: 0;
  z-index: 999;
  display: none;
  cursor: pointer;
}

header .header-style-one .main-nav .fl:before {
  font-size: 14px;
  text-align: center;
  line-height: 35px;
}

header .header-style-one .main-nav>ul>li+li>.sub-menu {
  left: 15px;
}

header .header-style-one .main-nav>ul>li:hover>.sub-menu {
  -webkit-transform: scale(1);
  transform: scale(1);
  visibility: visible;
  opacity: 1;
}

header .header-style-one .main-nav ul li .sub-menu li:hover>.sub-menu,
header .header-style-one .main-nav ul li .sub-menu li .sub-menu li:hover>.sub-menu {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

header .header-style-one .main-nav ul {
  margin: 0;
  list-style: none;
}

header .header-style-one .main-nav ul li {
  display: inline-block;
  position: relative;
  padding: 0 20px;
}

header .header-style-one .main-nav ul li:last-child {
  padding-right: 0;
}

header .header-style-one .main-nav ul li:hover>a {
  color: #ff4838;
}

header .header-style-one .main-nav ul li:hover>a::before {
  opacity: 1;
  background: #ff4838;
}

header .header-style-one .main-nav ul li a {
  font-size: 16px;
  color: #162b32;
  display: block;
  font-weight: 500;
  text-transform: capitalize;
  text-decoration: none;
  font-family:'Roboto-Regular';
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 30.5px 0;
  position: relative;
}

header .header-style-one .main-nav ul li ul.sub-menu>li {
  padding: 0;
}

header .header-style-one .main-nav ul li ul.sub-menu>li a {
  display: block;
  padding: 15px;
  color: #162b32;
  line-height: 1;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

header .header-style-one .main-nav ul li ul.sub-menu>li a.active {
  color: #ff4838;
  font-weight: 600;
}

header .header-style-one .main-nav ul li ul.sub-menu>li a:hover {
  background: #ff4838;
  color: #fff !important;
}

header .header-style-one .main-nav ul li ul.sub-menu>li a:hover::before {
  opacity: 0;
}

header .header-style-one .main-nav ul li.has-child-menu:after {
  position: absolute;
  right: 0;
  color: #ff4838;
  font-family: bootstrap-icons !important;
  content: "\f64d";
  font-size: 13px;
  font-weight: 600;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  top: 34px;
  transition: all 0.5s ease-in-out;
}

header .header-style-one .main-nav ul li:hover.has-child-menu:after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

header .header-style-one .main-nav>ul>li>a.active {
  color: #ff4838;
  font-weight: 600;
  font-size: 16px;
}

header .header-style-one .nav-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}

header .header-style-one .nav-right .nav-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

header .header-style-one .nav-right .nav-actions li {
  margin-right: 30px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

header .header-style-one .nav-right .nav-actions li:last-child {
  margin-right: 0;
}

header .header-style-one .nav-right .nav-actions li i {
  font-size: 20px;
  width: 40px;
  height: 40px;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  color: #ff4838;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
}

header .header-style-one .nav-right .nav-actions li i:hover {
  background: #ff4838;
  color: #fff;
}

/*header-style-two*/
header .header-style-two {
  background: #162b32;
}

header .header-style-two .main-nav ul li .sub-menu {
  position: absolute;
  left: 0;
  top: auto;
  min-width: 200px;
  list-style: none;
  background: #fff;
  opacity: 0;
  z-index: 9999;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-box-shadow: 0px 0px 65px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 65px 0px rgba(0, 0, 0, 0.1);
}

header .header-style-two .main-nav ul li .sub-menu li .sub-menu {
  right: -200px;
  left: inherit;
  top: 0;
}

header .header-style-two .main-nav ul li .sub-menu>li {
  display: block;
  margin: 0;
  position: relative;
}

header .header-style-two .main-nav ul>li a:hover {
  background: transparent;
  color: #ff4838 !important;
}

header .header-style-two .main-nav .fl {
  width: 30px;
  font-size: 20px;
  line-height: 35px;
  text-align: center;
  color: #2d373c;
  font-style: normal;
  position: absolute;
  right: -5px;
  top: 0;
  z-index: 999;
  display: none;
  cursor: pointer;
}

header .header-style-two .main-nav .fl:before {
  font-size: 14px;
  text-align: center;
  line-height: 35px;
}

header .header-style-two .main-nav>ul>li+li>.sub-menu {
  left: 15px;
}

header .header-style-two .main-nav>ul>li:hover>.sub-menu {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  opacity: 1;
}

header .header-style-two .main-nav ul li .sub-menu li:hover>.sub-menu,
header .header-style-two .main-nav ul li .sub-menu li .sub-menu li:hover>.sub-menu {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

header .header-style-two .main-nav ul {
  margin: 0;
  list-style: none;
}

header .header-style-two .main-nav ul li {
  display: inline-block;
  position: relative;
  padding: 0 20px;
}

header .header-style-two .main-nav ul li:last-child {
  padding-right: 0;
}

header .header-style-two .main-nav ul li:hover>a {
  color: #ff4838;
}

header .header-style-two .main-nav ul li:hover>a::before {
  opacity: 1;
  background: #ff4838;
}

header .header-style-two .main-nav ul li a {
  font-size: 16px;
  color: #fff;
  display: block;
  font-weight: 500;
  text-transform: capitalize;
  text-decoration: none;
  font-family:'Roboto-Regular';
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 30.5px 0;
  position: relative;
}

header .header-style-two .main-nav ul li ul.sub-menu>li {
  padding: 0;
}

header .header-style-two .main-nav ul li ul.sub-menu>li:last-child {
  border-bottom: none;
}

header .header-style-two .main-nav ul li ul.sub-menu>li a {
  display: block;
  padding: 15px;
  color: #162b32;
  line-height: 1;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

header .header-style-two .main-nav ul li ul.sub-menu>li a.active {
  color: #ff4838;
  font-weight: 600;
}

header .header-style-two .main-nav ul li ul.sub-menu>li a:hover {
  background: #ff4838;
  color: #ffff !important;
}

header .header-style-two .main-nav ul li ul.sub-menu>li a:hover::before {
  opacity: 0;
}

header .header-style-two .main-nav ul li.has-child-menu:after {
  position: absolute;
  right: 9px;
  color: #ff4838;
  content: "";
  background: #ff4838;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  top: 45px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

header .header-style-two .main-nav ul li:hover.has-child-menu:after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

header .header-style-two .main-nav>ul>li>a.active {
  color: #ff4838;
  font-weight: 600;
  font-size: 16px;
}

header .header-style-two .nav-right-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

header .header-style-two .nav-right-icons .user-dropdown {
  position: relative;
}

header .header-style-two .nav-right-icons .user-dropdown .user-drop-list {
  background: #162b32;
  position: absolute;
  z-index: 2;
  min-width: 190px;
  padding: 25px 20px;
  color: #ffff;
  right: 0;
  top: 57px;
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: 100% 0%;
  transform-origin: 100% 0%;
  visibility: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

header .header-style-two .nav-right-icons .user-dropdown .user-drop-list.account-drop-active {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
  z-index: 6;
}

header .header-style-two .nav-right-icons .user-dropdown .user-drop-list li {
  margin-top: 10px;
}

header .header-style-two .nav-right-icons .user-dropdown .user-drop-list li:first-child {
  margin-top: 0;
}

header .header-style-two .nav-right-icons .user-dropdown .user-drop-list li a {
  color: #a6a6a6;
  font-size: 14px;
  font-weight: 500;
}

header .header-style-two .nav-right-icons .user-dropdown .user-drop-list li a:hover {
  color: var(--c-primary);
}

header .header-style-two .nav-right-icons div {
  margin-right: 30px;
}

header .header-style-two .nav-right-icons div i {
  font-size: 26px;
  color: #c4c4c4;
  cursor: pointer;
  line-height: 33px;
}

header .header-style-two .nav-right-icons div i::before {
  vertical-align: middle;
}

header .header-style-two .nav-right {
  float: right;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}

header .header-style-two .nav-right .nav-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

header .header-style-two .nav-right .nav-actions li {
  margin-right: 30px;
}

header .header-style-two .nav-right .nav-actions li:last-child {
  margin-right: 0;
}

header .header-style-two .nav-right .nav-actions li a {
  font-size: 20px;
  color: var(--c-primary-one);
}

header .header-style-two .nav-right .nav-right-hotline {
  padding-left: 44px;
  padding-right: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
}

header .header-style-two .nav-right .nav-right-hotline .hotline-icon i {
  color: #fff;
  font-size: 30px;
  color: #ff4838;
}

header .header-style-two .nav-right .nav-right-hotline .hotline-info {
  margin-left: 10px;
}

header .header-style-two .nav-right .nav-right-hotline .hotline-info span {
  font-weight: 600;
  font-size: 12px;
  color: #ffffff;
}

header .header-style-two .nav-right .nav-right-hotline .hotline-info h6 {
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
  color: #fff;
  padding-top: 2px;
}

header .header-style-two .nav-right .nav-right-hotline .hotline-info h6 a {
  color: inherit;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0.3rem;
  background: #ff4838;
}

.react-datepicker__day--selected {
  background-color: #ff4838;
  color: #000;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #fff;
  font-weight: bold;
  font-size: 0.944rem;
}

/* =============================
     Navbar area end 
==============================*/
/* =============================
    5. Hero area start 
==============================*/
.hero-style-one {
  position: relative;
}

.hero-style-one .hero-pagination {
  position: absolute;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  right: 120px;
  top: 50%;
  left: auto;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  font-weight: 800;
  font-size: 110px;
  letter-spacing: 0.08em;
  opacity: 0.3;
  background: url(../../.././public/images/banner/hero-pagination-bg.png);
  background-position: bottom;
  background-clip: text;
  color: transparent;
}

.hero-style-one .slider-arrows {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  bottom: 70px;
  right: 120px;
  z-index: 2;
}

.hero-style-one .slider-arrows .hero-next,
.hero-style-one .slider-arrows .hero-prev {
  font-weight: 600;
  font-size: 16px;
  color: #b8b8b8;
  opacity: 0.7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-style: italic;
}

.hero-style-one .slider-arrows .hero-next:hover,
.hero-style-one .slider-arrows .hero-prev:hover {
  opacity: 1;
}

.hero-style-one .slider-arrows .hero-next {
  margin-left: 50px;
}

.hero-style-one .slider-arrows .hero-next i {
  margin-left: 5px;
}

.hero-style-one .slider-arrows .hero-prev i {
  margin-right: 5px;
}

.hero-style-one .swiper-slide.swiper-slide-active .hero-single-slide .hero-highlighted-bg img {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.hero-style-one .swiper-slide.swiper-slide-active .hero-single-slide .hero-content-bg .hero-content h2 {
  opacity: 1;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

.hero-style-one .swiper-slide.swiper-slide-active .hero-single-slide .hero-content-bg .hero-content p {
  opacity: 1;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

.hero-style-one .swiper-slide.swiper-slide-active .hero-single-slide .hero-content-bg .hero-content .hero-btns {
  opacity: 1;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

.hero-style-one .hero-single-slide {
  min-height: 750px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr) [2];
  grid-template-columns: repeat(2, 1fr);
}

.hero-style-one .hero-single-slide .hero-highlighted-bg {
  height: 100%;
  overflow: hidden;
}

.hero-style-one .hero-single-slide .hero-highlighted-bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transform: scale(1.4);
  transform: scale(1.4);
  -webkit-transition: -webkit-transform 9000ms ease;
  transition: -webkit-transform 9000ms ease;
  transition: transform 9000ms ease;
  transition: transform 9000ms ease, -webkit-transform 9000ms ease;
}

.hero-style-one .hero-single-slide .hero-content-bg {
  height: 100%;
  background: url(../../.././public/images/banner/hero-bg1.png) no-repeat center;
  background-size: cover;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.hero-style-one .hero-single-slide .hero-content-bg::before {
  position: absolute;
  content: "";
  inset: 0;
  background: rgba(22, 43, 50, 0.9);
  z-index: 0;
}

.hero-style-one .hero-single-slide .hero-content-bg .hero-content {
  max-width: 600px;
  padding: 90px 20px 90px 40px;
}

.hero-style-one .hero-single-slide .hero-content-bg .hero-content h2 {
  font-family:'Roboto-Regular';
  font-weight: 700;
  font-size: 85px;
  line-height: 95px;
  letter-spacing: 0.05em;
  color: #ff4838;
  opacity: 0;
  -webkit-transform: translateY(80px);
  transform: translateY(80px);
  -webkit-transition: all 800ms ease;
  transition: all 800ms ease;
  -webkit-transition-delay: 900ms;
  transition-delay: 900ms;
}

.hero-style-one .hero-single-slide .hero-content-bg .hero-content h2 span {
  -webkit-text-stroke: 2px #ff4838;
  -webkit-text-fill-color: transparent;
}

.hero-style-one .hero-single-slide .hero-content-bg .hero-content p {
  padding-top: 25px;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.02em;
  color: #ffffff;
  opacity: 0;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  -webkit-transition: all 800ms ease;
  transition: all 800ms ease;
  -webkit-transition-delay: 500ms;
  transition-delay: 500ms;
}

.hero-style-one .hero-single-slide .hero-content-bg .hero-content .hero-btns {
  padding-top: 40px;
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-transition: all 800ms ease;
  transition: all 800ms ease;
  -webkit-transition-delay: 1700ms;
  transition-delay: 1700ms;
  opacity: 0;
}

.hero-style-one .hero-single-slide .hero-content-bg .hero-content .hero-btns a {
  margin-top: 15px;
  margin-right: 50px;
}

.hero-style-one .hero-single-slide .hero-content-bg .hero-content .hero-btns a:last-child {
  margin-right: 0;
}

.selectbox input:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: none !important;
}

.selectbox .css-1s2u09g-control {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: none !important;
}

.selectbox .css-1s2u09g-control:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: none !important;
}

.selectbox .css-1s2u09g-control:hover {
  border: 1px solid #000;
}

.custom-input-group input.input-field.check-in.react-datepicker-ignore-onclickoutside {
  border: 1px solid #ff4838;
}

.searchbox-input .css-1pahdxg-control {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  margin-left: -2px;
}

.searchbox-input .css-1pahdxg-control:hover {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.searchbox-input input {
  height: auto;
  border: none;

  -webkit-box-shadow: none;
  box-shadow: none;
}

.searchbox-input .input-field.check-in.react-datepicker-ignore-onclickoutside {
  border: none;
}

.selectbox .css-1s2u09g-control {
  border: none;
  height: 10px !important;
  margin-left: -7px;
}

.calendar input:focus {
  border: none;
}

.css-14el2xx-placeholder {
  color: #333333;
  margin-left: 0px !important;
  font-size: 12px;
  font-weight: 500;
}

.react-datepicker__day-names .react-datepicker__day-name {
  color: #fff;
}

.css-319lph-ValueContainer {
  padding: 2px 3px !important;
}

.css-1okebmr-indicatorSeparator {
  display: none;
}

.select-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* border: 1px solid #e2e2e2; */
  padding: 21px 15px 6px;
  position: relative;
}

.react-datepicker__input-container input:focus {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.css-tlfecz-indicatorContainer {
  padding: 0px !important;
  color: #333333;
}

.select-box .css-2613qy-menu {
  position: absolute;
  z-index: 7555 !important;
  background: red;
  padding: 100px;
}

.multi-main-searchber .search-box-single .searchbox-input.date-picker-input::before {
  position: absolute;
  content: "\f1f3";
  font-family: bootstrap-icons !important;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: var(--text-tertiary);
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.react-datepicker__input-container:after {
  position: absolute;
  content: "\f1f3";
  font-family: bootstrap-icons !important;
  right: 0;
  top: 0;
  -webkit-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  color: #888;
  margin-left: 5px;
}

.custom-input-group .react-datepicker__input-container:after {
  -webkit-transform: translate(-25px, 25px);
  transform: translate(-25px, 25px);
}

.css-tj5bde-Svg {
  padding: 0px !important;
  stroke-width: none;
  padding-right: 10px;
  width: 17px;
  color: #333333;
  display: none;
}

.select-box .bx.bxs-calendar {
  position: absolute;
  left: 200px;
  -webkit-transform: translate(10px, 10px);
  transform: translate(10px, 10px);
  color: #7e7373;
}

.css-1gtu0rj-indicatorContainer {
  padding: 0 !important;
}

.searchbox-input label {
  font-weight: 600;
  font-size: 14px;
  color: #2d373c;
  line-height: 1;
  display: block;
  position: absolute;
  top: -16px;
  left: 0px;
  margin-left: 5px;
  /* z-index: 1; */
  margin-left: 0px;
}

@media (max-width: 991px) {
  .searchbox-input label {
    position: unset;
  }
}

.searchbox-input {
  position: relative;
  width: 100%;
}

.css-1s2u09g-control {
  border: none;
}

.searchbox-icons {
  /* font-size: 24px; */
  color: #ff4838;
  padding-right: 13px;
  padding-left: 0px;
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

.hero-two-pagination {
  position: absolute;
  right: 100px;
  top: 50%;
  left: unset !important;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.hero-two-pagination span {
  background: transparent;
  display: inline-block;
  padding: 30px 0;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.15em;
  -webkit-text-stroke: 1px rgba(22, 43, 50, 0.3);
  -webkit-text-fill-color: transparent;
}

.hero-two-pagination span.swiper-pagination-bullet {
  opacity: 1;
}

.hero-two-pagination span.swiper-pagination-bullet-active {
  -webkit-text-stroke: 1px #ff4838;
}

.hero-style-two {
  background: url(../../.././public/images/banner/hero-two-bg.png),
    -webkit-gradient(linear, left top, left bottom, color-stop(100%, #f3f4f5), to(#f3f4f5));
  background: url(../../.././public/images/banner/hero-two-bg.png),
    linear-gradient(#f3f4f5 100%, #f3f4f5 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.hero-style-two .hero-main-wrapper .single-hero-slide {
  padding-top: 110px;
  padding-bottom: 110px;
}

.hero-style-two .hero-main-wrapper .single-hero-slide .hero-content .featured-location {
  font-weight: 500;
  font-size: 20px;
  color: #2d373c;
}

.hero-style-two .hero-main-wrapper .single-hero-slide .hero-content .featured-location i {
  color: #ff4838;
  margin-right: 5px;
}

.hero-style-two .hero-main-wrapper .single-hero-slide .hero-content .hero-title {
  font-weight: 700;
  font-size: 50px;
  line-height: 65px;
  color: #2d373c;
  padding-top: 2px;
}

.hero-style-two .hero-main-wrapper .single-hero-slide .hero-content .hero-title span {
  color: #ff4838;
  margin-left: 10px;
}

.hero-style-two .hero-main-wrapper .single-hero-slide .hero-content .featured-price {
  font-weight: 700;
  font-size: 30px;
  color: #2d373c;
  padding-top: 16px;
}

.hero-style-two .hero-main-wrapper .single-hero-slide .hero-content .featured-price span {
  color: #ff4838;
  font-size: 20px;
}

.hero-style-two .hero-main-wrapper .single-hero-slide .hero-content p {
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.02em;
  color: #666666;
  padding-top: 25px;
}

.hero-style-two .hero-main-wrapper .single-hero-slide .hero-content .hero-btns {
  padding-top: 14px;
}

.hero-style-two .hero-main-wrapper .single-hero-slide .hero-content .hero-btns a {
  margin-top: 20px;
  margin-right: 30px;
}

.hero-style-two .hero-main-wrapper .single-hero-slide .hero-content .hero-btns a:last-child {
  margin-right: 0;
}

.multi-main-searchber {
  /* background: #ffffff; */
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 10px;
  padding: 15px 25px;
  position: relative;
  z-index: 2;
}

.multi-main-searchber .main-searchbar-close {
  position: absolute;
  right: 0;
  top: -38px;
}

.multi-main-searchber .main-searchbar-close i {
  height: 30px;
  width: 30px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #ff4838;
  font-size: 18px;
  border-radius: 50%;
  cursor: pointer;
}

.multi-main-searchber .search-box-single {
  border: 1px solid #e2e2e2;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 8px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.multi-main-searchber .search-box-single .searchbox-icon {
  font-size: 25px;
  color: #ff4838;
  padding-right: 15px;
}

.multi-main-searchber .search-box-single .searchbox-input {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.multi-main-searchber .search-box-single .searchbox-input.date-picker-input::before {
  position: absolute;
  content: "\f1f3";
  font-family: bootstrap-icons !important;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: var(--text-tertiary);
}

.multi-main-searchber .search-box-single .searchbox-input label {
  font-weight: 600;
  font-size: 16px;
  color: #2d373c;
  line-height: 1;
  display: block;
  margin-bottom: -2px;
}

.multi-main-searchber .search-box-single .searchbox-input select {
  font-weight: 500;
  font-size: 12px;
  color: #666666;
  display: block;
}

.multi-main-searchber .search-box-single .searchbox-input input,
.multi-main-searchber .search-box-single .searchbox-input select {
  height: 30px;
  padding: 0;
  border: none;
  padding-bottom: 10px;
}

.multi-main-searchber .search-box-single .searchbox-input input::-webkit-input-placeholder,
.multi-main-searchber .search-box-single .searchbox-input select::-webkit-input-placeholder {
  font-weight: 500;
  font-size: 12px;
  color: #666666;
}

.multi-main-searchber .search-box-single .searchbox-input input:-ms-input-placeholder,
.multi-main-searchber .search-box-single .searchbox-input select:-ms-input-placeholder {
  font-weight: 500;
  font-size: 12px;
  color: #666666;
}

.multi-main-searchber .search-box-single .searchbox-input input::-ms-input-placeholder,
.multi-main-searchber .search-box-single .searchbox-input select::-ms-input-placeholder {
  font-weight: 500;
  font-size: 12px;
  color: #666666;
}

.multi-main-searchber .search-box-single .searchbox-input input::placeholder,
.multi-main-searchber .search-box-single .searchbox-input select::placeholder {
  font-weight: 500;
  font-size: 12px;
  color: #666666;
}

.multi-main-searchber .main-form-submit {
  height: 100%;
}

.multi-main-searchber .main-form-submit button {
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  border: none;
  background: #ff4838;
  display: block;
  width: 100%;
  height: 100%;
  padding: 12px 24px;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

.multi-main-searchber .main-form-submit button:hover {
  background: transparent;
  color: red;
  border: 1px solid #ff4838;
}

/* =============================
     Hero area end
==============================*/
/* =============================
   6. Package area start
==============================*/
.package-card-alpha {
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.package-card-alpha:hover .package-card-body .p-card-bottom .book-btn a {
  background: #ff4838;
  color: #fff;
}

.package-card-alpha:hover .package-card-body .p-card-bottom .book-btn a i {
  -webkit-transform: translateX(5px);
  transform: translateX(5px);
}

.package-card-alpha .package-thumb {
  position: relative;
}

.package-card-alpha .package-thumb img {
  width: 100%;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

.package-card-alpha .package-thumb .card-lavel {
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  color: #ffffff;
  background: #ff4838;
  border-radius: 3px;
  position: absolute;
  padding: 9px 32px;
  bottom: 0;
  left: 20px;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  -webkit-clip-path: polygon(9% 0, 90% 3%, 100% 94%, 0 100%);
  clip-path: polygon(9% 0, 90% 3%, 100% 94%, 0 100%);
}

.package-card-alpha .package-thumb .card-lavel i {
  margin-right: 8px;
}

.package-card-alpha .package-card-body {
  padding: 30px 25px;
}

.package-card-alpha .package-card-body .p-card-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #454545;
}

.package-card-alpha .package-card-body .p-card-title a {
  color: inherit;
}

.package-card-alpha .package-card-body .p-card-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 20px;
}

.package-card-alpha .package-card-body .p-card-bottom .book-btn a {
  display: inline-block;
  font-weight: 600;
  font-size: 15px;
  text-transform: uppercase;
  color: #ff4838;
  background: #ffffff;
  border: 1px solid #ff4838;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 8px 16px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.package-card-alpha .package-card-body .p-card-bottom .book-btn a i {
  margin-left: 5px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.package-card-alpha .package-card-body .p-card-bottom .p-card-info h6 {
  color: #ff4838;
  font-weight: 800;
  font-size: 20px;
  line-height: 1;
}

.package-card-alpha .package-card-body .p-card-bottom .p-card-info h6 span {
  font-size: 14px;
}

.package-card-alpha .package-card-body .p-card-bottom .p-card-info span {
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
  color: #2d373c;
}

.package-card-gamma {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #ffffff;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 30px;
}

.package-card-gamma:hover .package-card-body .p-card-bottom .book-btn a {
  background: #ff4838;
  color: #fff;
}

.package-card-gamma:hover .package-card-body .p-card-bottom .book-btn a i {
  -webkit-transform: translateX(5px);
  transform: translateX(5px);
}

.package-card-gamma .package-thumb img {
  max-width: 355px;
  border-radius: 10px;
}

.package-card-gamma .package-card-body {
  text-align: center;
  padding-left: 30px;
  padding-right: 20px;
}

.package-card-gamma .package-card-body .card-lavel {
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  color: #ffffff;
  background: #ff4838;
  border-radius: 3px;
  padding: 9px 32px;
  -webkit-clip-path: polygon(9% 0, 90% 3%, 100% 94%, 0 100%);
  clip-path: polygon(9% 0, 90% 3%, 100% 94%, 0 100%);
  display: inline-block;
}

.package-card-gamma .package-card-body .card-lavel i {
  margin-right: 8px;
}

.package-card-gamma .package-card-body .p-card-title {
  padding-top: 18px;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #454545;
  text-align: start;
}

.package-card-gamma .package-card-body .p-card-title a {
  color: inherit;
}

.package-card-gamma .package-card-body .p-card-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 20px;
}

.package-card-gamma .package-card-body .p-card-bottom .book-btn a {
  display: inline-block;
  font-weight: 600;
  font-size: 15px;
  text-transform: uppercase;
  color: #ff4838;
  background: #ffffff;
  border: 1px solid #ff4838;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 8px 16px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.package-card-gamma .package-card-body .p-card-bottom .book-btn a i {
  margin-left: 5px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.package-card-gamma .package-card-body .p-card-bottom .p-card-info {
  text-align: start;
}

.package-card-gamma .package-card-body .p-card-bottom .p-card-info h6 {
  color: #ff4838;
  font-weight: 800;
  font-size: 20px;
  line-height: 1;
}

.package-card-gamma .package-card-body .p-card-bottom .p-card-info h6 span {
  font-size: 14px;
}

.package-card-gamma .package-card-body .p-card-bottom .p-card-info span {
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
  color: #2d373c;
}

.package-bottom-btn {
  margin-top: 50px;
}

.offer-switch-button {
  margin-bottom: 30px;
}

.offer-switch-button .nav-item {
  height: 87px;
  width: 87px;
  min-width: 87px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 18px;
}

.offer-switch-button .nav-item .nav-link {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  font-weight: 700;
  font-size: 20px;
  color: #ff4838;
  border: 2px solid #ff4838;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

.offer-switch-button .nav-item .nav-link.active {
  height: 87px;
  width: 87px;
  font-size: 28px;
  color: #fff;
  background: #ff4838;
}

.package-sidebar .package-widget-style-2 {
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 5px;
}

.package-sidebar .package-widget-style-2 .widget-title {
  background: #162b32;
  border-radius: 5px;
  padding: 13px 20px;
}

.package-sidebar .package-widget-style-2 .widget-title h4 {
  font-weight: 700;
  color: #ffffff;
  font-size: 20px;
  margin-top: 3px;
}

.package-sidebar .package-widget-style-2 .widget-lavel {
  font-weight: 700;
  font-size: 25px;
  color: #ff4838;
}

.package-sidebar .package-widget-style-2 .widget-lavel span {
  font-size: 15px;
  font-weight: 500;
  color: #fff;
}

.package-sidebar .package-widget-style-2 .widget-body {
  padding: 20px 20px;
}

.package-sidebar .package-widget {
  background: #ffffff;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 5px;
}

.package-sidebar .package-widget .widget-title {
  padding: 20px 20px;
  border-bottom: 1px solid #eeeeee;
}

.package-sidebar .package-widget .widget-title h4 {
  font-weight: 600;
  font-size: 20px;
  color: #2d373c;
}

.package-sidebar .package-widget .widget-body {
  padding: 20px 20px;
}

.package-sidebar .widget-tag-cloud .tag-cloud a {
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  color: #2d373c;
  border: 1px solid #2d373c4d;
  padding: 6px 18px;
  margin-right: 12px;
  margin-top: 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.package-sidebar .widget-tag-cloud .tag-cloud a:hover {
  border-color: #ff4838;
  background: #ff4838;
  color: #fff;
}

.package-sidebar .widget-search .search-input-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 44px;
}

.package-sidebar .widget-search .search-input-group input {
  height: 100%;
  background: #f5f5f5;
  border-radius: 44px 0 0 44px;
  padding: 10px 15px 10px 15px;
}

.package-sidebar .widget-search .search-input-group input::-webkit-input-placeholder {
  font-weight: 500;
  font-size: 12px;
  color: #666666;
  line-height: 1;
}

.package-sidebar .widget-search .search-input-group input:-ms-input-placeholder {
  font-weight: 500;
  font-size: 12px;
  color: #666666;
  line-height: 1;
}

.package-sidebar .widget-search .search-input-group input::-ms-input-placeholder {
  font-weight: 500;
  font-size: 12px;
  color: #666666;
  line-height: 1;
}

.package-sidebar .widget-search .search-input-group input::placeholder {
  font-weight: 500;
  font-size: 12px;
  color: #666666;
  line-height: 1;
}

.package-sidebar .widget-search .search-input-group input:focus {
  border: none;
}

.package-sidebar .widget-search .search-input-group button {
  border: none;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
  background: #ff4838;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 0 44px 44px 0;
  margin-left: -1px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.package-sidebar .widget-search .search-input-group button:hover {
  background: #162b32;
}

.package-sidebar .widget-duration .deration-check {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 15px;
}

.package-sidebar .widget-duration .deration-check:first-child {
  margin-top: 0;
}

.package-sidebar .widget-duration .deration-check label {
  font-weight: 500;
  font-size: 16px;
  color: #2d373c;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.package-sidebar .widget-duration .deration-check label:hover {
  color: #ff4838;
}

.package-sidebar .widget-duration .deration-check label i {
  margin-right: 5px;
  font-size: 14px;
}

.package-sidebar .widget-duration .deration-check input {
  height: 18px;
  width: 18px;
  margin: 0;
  padding: 0;
  display: inline-block;
  margin-right: 15px;
}

.package-sidebar .widget-duration .deration-check input:focus {
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

.package-sidebar .widget-duration .deration-check input:checked {
  background-color: #ff4838;
  border-color: #ff4838;
}

.package-sidebar .widget-tour-categoris .category-check {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 15px;
}

.package-sidebar .widget-tour-categoris .category-check:first-child {
  margin-top: 0;
}

.package-sidebar .widget-tour-categoris .category-check label {
  font-weight: 500;
  font-size: 16px;
  color: #2d373c;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.package-sidebar .widget-tour-categoris .category-check label:hover {
  color: #ff4838;
}

.package-sidebar .widget-tour-categoris .category-check label i {
  margin-right: 5px;
  font-size: 14px;
}

.package-sidebar .widget-tour-categoris input {
  height: 18px;
  width: 18px;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.package-sidebar .widget-tour-categoris input:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(13, 253, 58, 0.25);
  box-shadow: 0 0 0 0.25rem rgba(13, 253, 58, 0.25);
}

.package-sidebar .widget-tour-categoris input:checked {
  background-color: #ff4838;
  border-color: #ff4838;
}

.package-sidebar .widget-recent-package-entries .package-sm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 25px;
}

.package-sidebar .widget-recent-package-entries .package-sm:first-child {
  margin-top: 0;
}

.package-sidebar .widget-recent-package-entries .package-sm .thumb {
  min-width: 80px;
  width: 80px;
  margin-right: 15px;
}

.package-sidebar .widget-recent-package-entries .package-sm .thumb img {
  width: 100%;
}

.package-sidebar .widget-recent-package-entries .package-sm .info h6 {
  font-weight: 600;
  font-size: 15px;
  color: #454545;
  line-height: 20px;
}

.package-sidebar .widget-recent-package-entries .package-sm .info h6:hover {
  color: #ff4838;
}

.package-sidebar .widget-recent-package-entries .package-sm .info h6 a {
  color: inherit;
}

.package-sidebar .widget-recent-package-entries .package-sm .info .price span {
  color: #2d373c;
  font-size: 12px;
  font-weight: 500;
}

.package-sidebar .widget-recent-package-entries .package-sm .info .price h6 {
  font-weight: 800;
  font-size: 16px;
  line-height: 16px;
  text-transform: capitalize;
  color: #ff4838;
}

.package-sidebar .widget-gallary ul {
  display: -ms-grid;
  display: grid;
  grid-gap: 20px;
  -ms-grid-columns: (auto) [3];
  grid-template-columns: repeat(3, auto);
}

.package-sidebar .widget-gallary ul li img {
  width: 100%;
}

.react-datepicker__input-container input {
  padding: 7px 0px;
}

.booking-form-wrapper .custom-input-group {
  margin-top: 20px;
}

.booking-form-wrapper .custom-input-group .submite-btn {
  padding-top: 10px;
}

.booking-form-wrapper .custom-input-group .submite-btn button {
  width: 100%;
  display: block;
}

.booking-form-wrapper .custom-input-group textarea {
  background: #fff;
}

.booking-form-wrapper .custom-input-group input {
  height: 45px;
  padding: 7px 12px;
  background: #fff;
}

.booking-form-wrapper .custom-input-group select {
  height: 45px;
  padding: 0 15px;
  font-weight: 500;
  font-size: 13px;
  color: #2d373c;
  background: #fff;
}

.tour-package-details .pd-header {
  margin-bottom: 30px;
}

.tour-package-details .pd-header .pd-top .pd-single-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.tour-package-details .pd-header .pd-top .pd-single-info .info-icon {
  margin-right: 15px;
  margin-top: 4px;
}

.tour-package-details .pd-header .pd-top .pd-single-info .info-icon img {
  max-width: 42px;
}

.tour-package-details .pd-header .pd-top .pd-single-info .info h6 {
  font-weight: 600;
  font-size: 17px;
  color: #454545;
}

.tour-package-details .pd-header .pd-top .pd-single-info .info span {
  font-weight: 600;
  font-size: 14px;
  color: #666666;
}

.tour-package-details .pd-header .pd-thumb {
  padding-top: 30px;
}

.tour-package-details .pd-header .pd-thumb img {
  width: 100%;
  border-radius: 5px;
}

.tour-package-details .pd-header .header-bottom {
  padding: 30px 0 30px 0;
  border-bottom: 1px solid #eeeeee;
}

.tour-package-details .pd-header .header-bottom .pd-lavel .location {
  font-weight: 600;
  font-size: 20px;
  color: #ff4838;
}

.tour-package-details .pd-header .header-bottom .pd-lavel .rating li {
  margin-right: 8px;
}

.tour-package-details .pd-header .header-bottom .pd-lavel .rating li i {
  font-size: 17px;
  color: #ff4838;
}

.tour-package-details .pd-header .header-bottom .pd-lavel .rating li:last-child {
  margin-right: 0;
}

.tour-package-details .pd-header .header-bottom .pd-title {
  font-weight: 700;
  font-size: 35px;
  text-transform: capitalize;
  color: #2d373c;
}

.tour-package-details .package-details-tabs {
  position: relative;
}

.tour-package-details .package-details-tabs .tab-switchers {
  background: #fff;
  top: 0;
  left: 0;
  z-index: 9;
  margin-bottom: 50px;
}

.tour-package-details .package-details-tabs .tab-switchers .nav-item .nav-link {
  border: 2px solid #ff4838;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 30px;
  font-weight: 600;
  font-size: 20px;
  color: #2d373c;
  padding: 8px 28px;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

.tour-package-details .package-details-tabs .tab-switchers .nav-item .nav-link:hover {
  background: #ff4838;
  color: #fff;
}

.tour-package-details .package-details-tabs .tab-switchers .nav-item .nav-link i {
  margin-right: 10px;
}

.tour-package-details .package-details-tabs .tab-switchers .nav-item .nav-link.active {
  background: #ff4838;
  color: #fff;
}

.tour-package-details .tab-pane .d-subtitle {
  font-weight: 700;
  font-size: 30px;
  color: #2d373c;
  line-height: 1;
}

.tour-package-details .package-info-tab p {
  font-size: 16px;
  line-height: 30px;
  color: #666666;
  padding-top: 15px;
}

.tour-package-details .package-info-tab .p-info-featured-img {
  margin: 20px 0;
}

.tour-package-details .package-info-tab .p-info-featured-img .featured-img img {
  width: 100%;
}

.tour-package-details .package-info-tab .p-info-featured-img .featured-video {
  left: 50%;
  bottom: -30px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: auto;
  padding: 0;
  margin: 0;
  border-right: 5px;
  overflow: hidden;
}

.tour-package-details .package-info-tab .p-info-featured-img .featured-video .video-overlay {
  position: absolute;
  inset: 0;
  background: rgba(255, 72, 56, 0.5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
}

.tour-package-details .package-info-tab .p-info-featured-img .featured-video .video-overlay:hover .play-icon {
  background: rgba(45, 55, 60, 0.8);
  cursor: pointer;
}

.tour-package-details .package-info-tab .p-info-featured-img .featured-video .video-overlay:hover .play-icon::before {
  inset: -8px;
  background: rgba(45, 55, 60, 0.6);
}

.tour-package-details .package-info-tab .p-info-featured-img .featured-video .video-overlay:hover .play-icon::after {
  inset: -24px;
  background: rgba(45, 55, 60, 0.5);
}

.tour-package-details .package-info-tab .p-info-featured-img .featured-video .video-overlay .play-icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 34.94px;
  height: 34.94px;
  background: rgba(45, 55, 60, 0.6);
  border-radius: 50%;
  position: relative;
  z-index: 1;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.tour-package-details .package-info-tab .p-info-featured-img .featured-video .video-overlay .play-icon::before {
  position: absolute;
  content: "";
  inset: -6px;
  background: rgba(45, 55, 60, 0.5);
  border-radius: 50%;
  z-index: 1;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.tour-package-details .package-info-tab .p-info-featured-img .featured-video .video-overlay .play-icon::after {
  position: absolute;
  content: "";
  inset: -12px;
  background: rgba(45, 55, 60, 0.4);
  border-radius: 50%;
  z-index: -1;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.tour-package-details .package-info-tab .p-info-featured-img .featured-video .video-overlay .play-icon i {
  font-size: 20px;
  color: #ff4838;
  position: relative;
  z-index: 2;
}

.tour-package-details .package-info-tab .package-info-table {
  margin-top: 80px;
  background: #ffffff;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 10px;
}

.tour-package-details .package-info-tab .package-info-table tr .tour-transport-col {
  padding-left: 10px;
}

.tour-package-details .package-info-tab .package-info-table tr .tour-transport-col .tour-transport img {
  max-width: 25px;
  margin-right: 10px;
}

.tour-package-details .package-info-tab .package-info-table tr th,
.tour-package-details .package-info-tab .package-info-table tr td {
  font-weight: 600;
  font-size: 18px;
  padding: 20px 10px 20px 40px;
  vertical-align: middle;
}

.tour-package-details .package-info-tab .package-info-table tr th .excluded-list li,
.tour-package-details .package-info-tab .package-info-table tr td .excluded-list li {
  margin-top: 10px;
}

.tour-package-details .package-info-tab .package-info-table tr th .excluded-list li:first-child,
.tour-package-details .package-info-tab .package-info-table tr td .excluded-list li:first-child {
  margin-top: 0;
}

.tour-package-details .package-info-tab .package-info-table tr th .excluded-list li i,
.tour-package-details .package-info-tab .package-info-table tr td .excluded-list li i {
  margin-right: 15px;
  color: #ff4838;
}

.tour-package-details .package-info-tab .package-info-table tr th .included-list li,
.tour-package-details .package-info-tab .package-info-table tr td .included-list li {
  margin-top: 10px;
}

.tour-package-details .package-info-tab .package-info-table tr th .included-list li:first-child,
.tour-package-details .package-info-tab .package-info-table tr td .included-list li:first-child {
  margin-top: 0;
}

.tour-package-details .package-info-tab .package-info-table tr th .included-list li i,
.tour-package-details .package-info-tab .package-info-table tr td .included-list li i {
  margin-right: 15px;
  color: #162b32;
}

.tour-package-details .package-info-tab .package-info-table tr th {
  color: #2d373c;
}

.tour-package-details .package-info-tab .package-info-table tr td {
  color: #666666;
}

.tour-package-details .package-info-tab .rating-overview {
  margin-top: 60px;
}

.tour-package-details .package-info-tab .rating-overview-row {
  margin-top: 24px;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid rgba(255, 72, 56, 0.15);
}

.tour-package-details .package-info-tab .rating-overview-row .total-rating {
  background: #f5f5f5;
  padding: 40px 20px;
}

.tour-package-details .package-info-tab .rating-overview-row .total-rating h3 {
  font-weight: 800;
  font-size: 50px;
  color: #ff4838;
}

.tour-package-details .package-info-tab .rating-overview-row .total-rating h5 {
  font-weight: 500;
  font-size: 25px;
  color: #262339;
}

.tour-package-details .package-info-tab .rating-overview-row .rating-info {
  padding: 45px 35px;
}

.tour-package-details .package-info-tab .rating-overview-row .rating-info .rating-box {
  margin-top: 28px;
}

.tour-package-details .package-info-tab .rating-overview-row .rating-info .rating-box:first-child {
  margin-top: 0;
}

.tour-package-details .package-info-tab .rating-overview-row .rating-info .rating-box h6 {
  font-weight: 600;
  font-size: 18px;
  color: #262339;
}

.tour-package-details .package-info-tab .rating-overview-row .rating-info .rating-box h6 span {
  font-size: 20px;
  font-weight: 700;
  float: right;
}

.tour-package-details .package-info-tab .rating-overview-row .rating-info .rating-box .rating-bar {
  width: 100%;
  height: 3px;
  border-radius: 6px;
  background: #eee;
  margin-top: 10px;
  position: relative;
  overflow: hidden;
}

.tour-package-details .package-info-tab .rating-overview-row .rating-info .rating-box .rating-bar::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ff4838;
}

.tour-package-details .package-info-tab .comment-section {
  padding: 60px 0;
}

.tour-package-details .package-plan-tab p {
  font-size: 16px;
  line-height: 28px;
  color: #666666;
  padding-top: 15px;
}

.tour-package-details .package-plan-tab .plans-accordion .plans-accordion-single {
  border: none;
  margin-top: 40px;
}

.tour-package-details .package-plan-tab .plans-accordion .plans-accordion-single .accordion-button {
  padding: 0 30px;
  padding-left: 0;
  cursor: pointer;
  background: #ffffff;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 80px;
}

.tour-package-details .package-plan-tab .plans-accordion .plans-accordion-single .accordion-button::after {
  background-image: none;
  content: "\ecad";
  font-size: 36px;
  font-family: boxicons;
  height: auto;
  width: auto;
  color: #2d373c;
}

.tour-package-details .package-plan-tab .plans-accordion .plans-accordion-single .accordion-button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.tour-package-details .package-plan-tab .plans-accordion .plans-accordion-single .accordion-button .paln-index-circle {
  width: 75px;
  height: 75px;
  background: #ff4838;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 5px solid rgba(22, 43, 50, 0.5);
  border-radius: 50%;
  font-weight: 700;
  font-size: 32px;
  color: #ffffff;
  margin-right: 25px;
}

.tour-package-details .package-plan-tab .plans-accordion .plans-accordion-single .accordion-button .plan-title {
  padding-bottom: 3px;
}

.tour-package-details .package-plan-tab .plans-accordion .plans-accordion-single .accordion-button .plan-title h5 {
  font-weight: 700;
  font-size: 23px;
  color: #2d373c;
}

.tour-package-details .package-plan-tab .plans-accordion .plans-accordion-single .accordion-button .plan-title h6 {
  font-weight: 500;
  font-size: 16px;
  color: #ff4838;
  padding-top: 8px;
}

.tour-package-details .package-plan-tab .plans-accordion .plans-accordion-single .plan-info {
  padding-left: 60px;
  padding-top: 20px;
  padding-bottom: 0;
}

.tour-package-details .package-plan-tab .plans-accordion .plans-accordion-single .plan-info p {
  padding-top: 0;
}

.tour-package-details .package-plan-tab .plans-accordion .plans-accordion-single .plan-info ul {
  padding-top: 8px;
}

.tour-package-details .package-plan-tab .plans-accordion .plans-accordion-single .plan-info ul li {
  font-weight: 500;
  font-size: 16px;
  color: #ff4838;
  padding-top: 5px;
}

.tour-package-details .package-plan-tab .plans-accordion .plans-accordion-single .plan-info ul li i {
  color: #162b32;
  margin-right: 8px;
}

.tour-package-details .package-gallary-tab .package-gallary-item {
  overflow: hidden;
  border-radius: 5px;
}

.tour-package-details .package-gallary-tab .package-gallary-item:hover img {
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
}

.tour-package-details .package-gallary-tab .package-gallary-item img {
  width: 100%;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.tour-package-details .package-location-tab .mapouter {
  position: relative;
  text-align: right;
  height: 770px;
  width: 100%;
  padding-top: 5px;
}

.tour-package-details .package-location-tab iframe {
  width: 100%;
  height: 550px;
}

/* =============================
    Package area end
==============================*/
/* =============================
  7.  Destination area start
==============================*/
.destination-style-one .custom-swiper-next,
.destination-style-one .custom-swiper-prev {
  border: 1px solid #ff4838 !important;
  opacity: 0.5;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.destination-style-one .custom-swiper-next i,
.destination-style-one .custom-swiper-prev i {
  color: #ff4838 !important;
}

.destination-style-one .custom-swiper-next:hover,
.destination-style-one .custom-swiper-prev:hover {
  opacity: 1;
}

.destination-card-style-one {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}

.destination-card-style-one:hover .d-card-thumb img {
  -webkit-transform: scale(1.04);
  transform: scale(1.04);
}

.destination-card-style-one:hover .d-card-overlay {
  background: rgba(45, 55, 60, 0.8);
}

.destination-card-style-one:hover .d-card-overlay .d-card-content .d-card-title,
.destination-card-style-one:hover .d-card-overlay .d-card-content .d-card-info,
.destination-card-style-one:hover .d-card-overlay .d-card-content .d-rating {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

.destination-card-style-one .d-card-thumb img {
  width: 100%;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.destination-card-style-one .d-card-overlay {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0);
  text-align: center;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-bottom: 50px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.destination-card-style-one .d-card-overlay .d-card-content .d-card-title {
  font-weight: 700;
  font-size: 30px;
  letter-spacing: 0.02em;
  color: #ffffff;
  -webkit-transform: translateY(40px);
  transform: translateY(40px);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.destination-card-style-one .d-card-overlay .d-card-content .d-card-title a {
  color: inherit;
}

.destination-card-style-one .d-card-overlay .d-card-content .d-card-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 7px;
  -webkit-transform: translateY(40px);
  transform: translateY(40px);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.destination-card-style-one .d-card-overlay .d-card-content .d-card-info .place-count,
.destination-card-style-one .d-card-overlay .d-card-content .d-card-info .hotel-count {
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.02em;
  color: #ffffff;
}

.destination-card-style-one .d-card-overlay .d-card-content .d-card-info .place-count span,
.destination-card-style-one .d-card-overlay .d-card-content .d-card-info .hotel-count span {
  font-weight: 700;
  font-size: 20px;
}

.destination-card-style-one .d-card-overlay .d-card-content .d-card-info .place-count {
  position: relative;
  margin-right: 20px;
}

.destination-card-style-one .d-card-overlay .d-card-content .d-card-info .place-count::before {
  position: absolute;
  content: "";
  top: 50%;
  right: -10px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 15px;
  width: 2px;
  background: #ff4838;
}

.destination-card-style-one .d-card-overlay .d-card-content .d-rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 10px;
  -webkit-transform: translateY(40px);
  transform: translateY(40px);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0;
}

.destination-card-style-one .d-card-overlay .d-card-content .d-rating li {
  margin-right: 7px;
}

.destination-card-style-one .d-card-overlay .d-card-content .d-rating li:last-child {
  margin-right: 0;
}

.destination-card-style-one .d-card-overlay .d-card-content .d-rating li i {
  font-size: 13px;
  color: #ff4838;
}

.destination-item {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}

.destination-item .destination-img img {
  width: 100%;
  height: auto;
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}

.destination-item .destination-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 5, 15, 0);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.destination-item .destination-overlay .content {
  padding: 30px;
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.destination-item .destination-overlay .content h5 {
  font-size: 22px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 10px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.destination-item .destination-overlay .content h6 {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  display: inline-block;
  position: relative;
  z-index: 1;
}

.destination-item .destination-overlay .content h6::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 0%;
  height: 3px;
  background: var(--c-primary);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border-radius: 5px;
}

.destination-item .destination-overlay .content:hover h6::after {
  width: 100%;
}

.destination-item:hover .destination-img img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.destination-item:hover .destination-overlay .content {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

.destination-item:hover .destination-overlay .content h6 {
  opacity: 1;
}

.destination-style-two .testi-pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 20px;
}

.destination-style-two .testi-pagination span {
  opacity: 1;
  width: 15px;
  height: 15px;
  border: 2px solid #162b32;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  border-radius: 50%;
  background: transparent;
  position: relative;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.destination-style-two .testi-pagination span.swiper-pagination-bullet-active {
  width: 21px;
  height: 21px;
  border-color: #ff4838;
}

.destination-style-two .testi-pagination span.swiper-pagination-bullet-active::before {
  position: absolute;
  content: "";
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #ff4838;
  left: 4px;
  top: 4px;
}

.destination-card-style-two {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 30px;
}

.destination-card-style-two:hover .d-card-thumb img {
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
}

.destination-card-style-two:hover .d-card-content .destination-title,
.destination-card-style-two:hover .d-card-content .place-count {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

.destination-card-style-two:hover .d-card-content .destination-title::before,
.destination-card-style-two:hover .d-card-content .place-count::before {
  opacity: 1;
}

.destination-card-style-two .d-card-thumb img {
  width: 100%;
  border-radius: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.destination-card-style-two .d-card-content {
  position: absolute;
  text-align: center;
  bottom: 20px;
  left: 0;
  text-align: center;
  width: 100%;
}

.destination-card-style-two .d-card-content .destination-title {
  font-weight: 700;
  font-size: 25px;
  letter-spacing: 0.02em;
  color: #ffffff;
  -webkit-transform: translateY(25px);
  transform: translateY(25px);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  padding-bottom: 8px;
}

.destination-card-style-two .d-card-content .destination-title::before {
  position: absolute;
  content: "";
  height: 3px;
  width: 83px;
  background: #ff4838;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.destination-card-style-two .d-card-content .destination-title a {
  color: inherit;
}

.destination-card-style-two .d-card-content .place-count {
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 0.02em;
  color: #ffffff;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.destination-card-style-two .d-card-content .place-count span {
  font-size: 20px;
}

.destination-details .dd-thumb {
  padding-bottom: 35px;
}

.destination-details .dd-thumb img {
  width: 100%;
  border-radius: 5px;
  cursor: initial;
}

.destination-details .destination-overview-table {
  margin-top: 40px;
}

.destination-details .dd-body .dd-subtitle {
  font-weight: 700;
  font-size: 35px;
  color: #2d373c;
}

.destination-details .dd-body p {
  font-size: 16px;
  line-height: 28px;
  color: #666666;
  margin-top: 15px;
}

.destination-details .dd-body .dd-img-group {
  margin: 0px 0 20px 0;
}

.destination-details .dd-body .dd-img-group .dd-inner-group img {
  width: 100%;
  cursor: initial;
}

.destination-details .dd-body .destination-overview-table .overview-table {
  background: #ffffff;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 0;
}

.destination-details .dd-body .destination-overview-table .overview-table tr th,
.destination-details .dd-body .destination-overview-table .overview-table tr td {
  font-weight: 600;
  font-size: 18px;
  padding: 15px 0px 15px 20px;
}

.destination-details .dd-body .destination-overview-table .overview-table tr th span,
.destination-details .dd-body .destination-overview-table .overview-table tr td span {
  margin-left: 6px;
}

.destination-details .dd-body .destination-overview-table .overview-table tr th {
  color: #2d373c;
}

.destination-details .dd-body .destination-overview-table .overview-table tr td {
  color: #ff4838;
}

.destination-details .dd-body .destination-map {
  padding-top: 40px;
}

.destination-details .dd-body .destination-map .mapouter {
  position: relative;
  text-align: right;
  height: 700px;
  width: 100%;
  margin-top: 20px;
}

.destination-details .dd-body .destination-map iframe {
  width: 100%;
  height: 700px;
}

/* =============================
   Destination area end
==============================*/
/* =============================
  8. Newslatter area start
==============================*/
.newslatter-wrapper {
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(100%, rgba(45, 55, 60, 0.8)),
      to(rgba(45, 55, 60, 0.8))),
    url(../../.././public/images/banner/newslatter-bg.png);
  background: linear-gradient(rgba(45, 55, 60, 0.8) 100%,
      rgba(45, 55, 60, 0.8) 100%),
    url(../../.././public/images/banner/newslatter-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding: 70px 0;
}

.newslatter-wrapper .newslatter-side {
  max-width: 470px;
  color: #fff;
  margin-bottom: 16px;
}

.newslatter-wrapper .newslatter-side h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 57px;
  text-transform: uppercase;
  color: #ffffff;
}

.newslatter-wrapper .newslatter-side h2 span {
  color: #ff4838;
}

.newslatter-wrapper .newslatter-side p {
  font-size: 18px;
  color: #ffffff;
  padding-top: 10px;
}

.newslatter-wrapper .newslatter-side .newslatter-form-input {
  position: relative;
  margin-top: 50px;
}

.newslatter-wrapper .newslatter-side .newslatter-form-input input {
  background: #ffffff;
  border: 1px solid rgba(206, 62, 38, 0.2);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 56px;
  border-radius: 0;
  padding: 20px 46px 20px 20px;
}

.newslatter-wrapper .newslatter-side .newslatter-form-input input::-webkit-input-placeholder {
  font-size: 11px;
  color: #666666;
}

.newslatter-wrapper .newslatter-side .newslatter-form-input input:-ms-input-placeholder {
  font-size: 11px;
  color: #666666;
}

.newslatter-wrapper .newslatter-side .newslatter-form-input input::-ms-input-placeholder {
  font-size: 11px;
  color: #666666;
}

.newslatter-wrapper .newslatter-side .newslatter-form-input input::placeholder {
  font-size: 11px;
  color: #666666;
}

.newslatter-wrapper .newslatter-side .newslatter-form-input .newslatter-submit {
  position: relative;
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
  color: #ffffff;
  background: #ff4838;
  border-radius: 0px;
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  border: none;
  padding: 0 30px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.newslatter-wrapper .newslatter-side .newslatter-form-input .newslatter-submit:hover {
  background: #162b32;
}

.newslatter-wrapper .achievement-counter-side .achievement-box-style-one {
  text-align: center;
  min-height: 215px;
  border: 2px solid #ff4838;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 25px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.newslatter-wrapper .achievement-counter-side .achievement-box-style-one:hover {
  background: rgba(255, 255, 255, 0.1);
  border: 2px solid #ffffff1a;
}

.newslatter-wrapper .achievement-counter-side .achievement-box-style-one .achievement-icon {
  min-height: 70px;
}

.newslatter-wrapper .achievement-counter-side .achievement-box-style-one .achievement-icon img {
  max-width: 100px;
}

.newslatter-wrapper .achievement-counter-side .achievement-box-style-one .achievement-box-content h2 {
  font-weight: 800;
  font-size: 45px;
  letter-spacing: 0.03em;
  color: #ffffff;
}

.newslatter-wrapper .achievement-counter-side .achievement-box-style-one .achievement-box-content h4 {
  padding-top: 5px;
  font-size: 25px;
  color: #ffffff;
}

.newslatter-style-two .newslatter-offer-side {
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(100%, rgba(45, 55, 60, 0.8)),
      to(rgba(45, 55, 60, 0.8))),
    url(../../.././public/images/banner/newslatter-bg2.png);
  background: linear-gradient(rgba(45, 55, 60, 0.8) 100%,
      rgba(45, 55, 60, 0.8) 100%),
    url(../../.././public/images/banner/newslatter-bg2.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 100px 20px;
  min-height: 552px;
}

.newslatter-style-two .newslatter-offer-side .newslatter-offer-content {
  max-width: 528px;
  color: #fff;
}

.newslatter-style-two .newslatter-offer-side .newslatter-offer-content h2 {
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  color: #ffffff;
}

.newslatter-style-two .newslatter-offer-side .newslatter-offer-content h2 span {
  color: #ff4838;
}

.newslatter-style-two .newslatter-offer-side .newslatter-offer-content h6 {
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  padding-top: 17px;
  font-weight: 400;
}

.newslatter-style-two .newslatter-offer-side .newslatter-offer-content .explore-btn {
  margin-top: 38px;
}

.newslatter-style-two .newslatter-form-side {
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(100%, rgba(222, 185, 155, 0.3)),
      to(rgba(222, 185, 155, 0.3))),
    url(../../.././public/images/banner/newslatter-bg3.png);
  background: linear-gradient(rgba(222, 185, 155, 0.3) 100%,
      rgba(222, 185, 155, 0.3) 100%),
    url(../../.././public/images/banner/newslatter-bg3.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 100px 20px;
  min-height: 552px;
}

.newslatter-style-two .newslatter-form-side .newslatter-form-content {
  max-width: 570px;
}

.newslatter-style-two .newslatter-form-side .newslatter-form-content h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 57px;
  text-transform: uppercase;
  color: #ff4838;
}

.newslatter-style-two .newslatter-form-side .newslatter-form-content h2 span {
  color: #162b32;
}

.newslatter-style-two .newslatter-form-side .newslatter-form-content h6 {
  font-size: 20px;
  line-height: 30px;
  color: #666666;
  font-weight: 400;
  padding-top: 10px;
}

.newslatter-style-two .newslatter-form-side .newslatter-form-content .newslatter-form-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 56px;
  margin-top: 44px;
}

.newslatter-style-two .newslatter-form-side .newslatter-form-content .newslatter-form-wrap input {
  background: #ffffff;
  border: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 50px 0 0 50px;
  height: 100%;
  padding: 20px;
}

.newslatter-style-two .newslatter-form-side .newslatter-form-content .newslatter-form-wrap button {
  border: none;
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
  color: #ffffff;
  background: #ff4838;
  border-radius: 0px 50px 50px 0px;
  height: 100%;
  padding: 0 30px;
  margin-left: -1px;
}

.newslatter-style-two .newslatter-form-side .newslatter-form-content .newslatter-form-wrap button:hover {
  background: transparent;
  border: 2px solid #ff4838;
  color: #ff4838;
}

/* =============================
   Newslatter area end
==============================*/
/* =============================
  9. Gallary area start
==============================*/
.gallery-wrapper {
  margin-top: -35px;
}

.gallary-item {
  margin-top: 35px;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}

.gallary-item:hover .gallary-item-overlay {
  opacity: 1;
}

.gallary-item:hover .gallary-item-overlay i {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.gallary-item .gallary-item-overlay {
  position: absolute;
  inset: 0;
  background: rgba(206, 20, 70, 0.4);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.gallary-item .gallary-item-overlay i {
  font-size: 50px;
  color: #fff;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

.gallary-item img {
  width: 100%;
  border-radius: 5px;
}

.social-card {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}

.social-card:hover .social-thumb img {
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
}

.social-card:hover .social-overlay {
  opacity: 1;
  inset: 0;
}

.social-card:hover .social-overlay i {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.social-card .social-thumb img {
  width: 100%;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

.social-card .social-overlay {
  position: absolute;
  inset: 30px;
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      from(rgba(45, 55, 60, 0.7)),
      to(rgba(206, 62, 38, 0.7)));
  background: linear-gradient(180deg,
      rgba(45, 55, 60, 0.7) 0%,
      rgba(206, 62, 38, 0.7) 100%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.social-card .social-overlay i {
  font-size: 35px;
  color: #fff;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.gallary-group .gallary-group-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 40px;
}

.gallary-group .gallary-group-header .gallary-group-title {
  font-weight: 700;
  font-size: 30px;
  color: #2d373c;
}

.gallary-group .gallary-group-header .gallary-arrows .gallary-button-prev {
  margin-right: 40px;
}

.gallary-group .gallary-group-header .gallary-arrows .gallary-button-next,
.gallary-group .gallary-group-header .gallary-arrows .gallary-button-prev {
  display: inline-block;
}

.gallary-group .gallary-group-header .gallary-arrows .gallary-button-next i,
.gallary-group .gallary-group-header .gallary-arrows .gallary-button-prev i {
  height: 25px;
  width: 25px;
  color: #ff4838;
  border: 1px solid #ff4838;
  border-radius: 50%;
  text-align: center;
  line-height: 25px;
  display: inline-block;
  font-size: 13px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.gallary-group .gallary-group-header .gallary-arrows .gallary-button-next i:hover,
.gallary-group .gallary-group-header .gallary-arrows .gallary-button-prev i:hover {
  background: #ff4838;
  color: #fff;
}

/* =============================
   Gallary area end
==============================*/
/* =============================
  10. testimonial area start
==============================*/
.testimonial-style-one {
  background: #162b32;
  padding: 90px 0;
  overflow: hidden;
  position: relative;
}

.testimonial-style-one .testimonial-shape-group {
  position: absolute;
  inset: 0;
  background-size: cover;
  background-image: -webkit-gradient(linear,
      left top,
      left bottom,
      from(rgba(0, 5, 15, 0.6)),
      to(rgba(0, 5, 15, 0.6))),
    url(../../../public/images/shapes/testi-shape-group.png);
  background-image: linear-gradient(rgba(0, 5, 15, 0.6), rgba(0, 5, 15, 0.6)),
    url(../../../public/images/shapes/testi-shape-group.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding-left: 10%;
  padding-right: 10%;
}

.testimonial-style-one .section-head-alpha h2,
.testimonial-style-one .section-head-alpha p {
  color: #fff;
}

.testimonial-card-alpha {
  background: #fff;
  min-height: 100px;
  position: relative;
  border-radius: 5px;
  padding: 5px 25px 25px 25px;
  margin-top: 24px;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  margin-top: 20px;
}

.testimonial-card-alpha:hover {
  background: rgba(45, 55, 60, 0.9);
}

.testimonial-card-alpha:hover .testimonial-overlay-img {
  opacity: 0.5;
  z-index: 0;
}

.testimonial-card-alpha:hover .testimonial-card-top .qoute-icon i,
.testimonial-card-alpha:hover .testimonial-card-top .testimonial-count {
  color: rgba(255, 255, 255, 0.1);
}

.testimonial-card-alpha:hover .testimonial-card-top .testimonial-thumb {
  opacity: 0;
}

.testimonial-card-alpha:hover .testimonial-body p {
  color: #ffffff;
}

.testimonial-card-alpha:hover .testimonial-body .testimonial-bottom .reviewer-info .reviewer-name {
  color: #ff4838;
}

.testimonial-card-alpha:hover .testimonial-body .testimonial-bottom .reviewer-info h6 {
  color: #fff;
}

.testimonial-card-alpha .testimonial-overlay-img {
  position: absolute;
  inset: 0;
  z-index: -1;
  opacity: 0;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

.testimonial-card-alpha .testimonial-overlay-img img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 5px;
}

.testimonial-card-alpha .testimonial-card-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 30px;
}

.testimonial-card-alpha .testimonial-card-top .qoute-icon i {
  font-size: 55px;
  color: rgba(22, 43, 50, 0.05);
  line-height: 1;
}

.testimonial-card-alpha .testimonial-card-top .testimonial-thumb {
  -webkit-transform: translateY(-24px);
  transform: translateY(-24px);
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.testimonial-card-alpha .testimonial-card-top .testimonial-thumb img {
  max-width: 90px;
  border-radius: 50%;
  border: 1px solid #fff;
}

.testimonial-card-alpha .testimonial-card-top .testimonial-count {
  font-weight: 800;
  font-size: 35px;
  color: rgba(22, 43, 50, 0.08);
  line-height: 1;
}

.testimonial-card-alpha .testimonial-body {
  position: relative;
}

.testimonial-card-alpha .testimonial-body p {
  text-align: center;
  font-size: 16px;
  line-height: 25px;
  color: #666666;
}

.testimonial-card-alpha .testimonial-body .testimonial-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 10px;
}

.testimonial-card-alpha .testimonial-body .testimonial-bottom .reviewer-info {
  margin-top: 10px;
}

.testimonial-card-alpha .testimonial-body .testimonial-bottom .reviewer-info .reviewer-name {
  font-weight: 600;
  font-size: 22px;
  text-transform: capitalize;
  color: #262339;
}

.testimonial-card-alpha .testimonial-body .testimonial-bottom .reviewer-info h6 {
  padding-top: 4px;
  color: #a5a5a5;
  font-size: 18px;
  text-transform: capitalize;
}

.testimonial-card-alpha .testimonial-body .testimonial-bottom .testimonial-rating {
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.testimonial-card-alpha .testimonial-body .testimonial-bottom .testimonial-rating li {
  margin-right: 8px;
}

.testimonial-card-alpha .testimonial-body .testimonial-bottom .testimonial-rating li:last-child {
  margin-right: 0;
}

.testimonial-card-alpha .testimonial-body .testimonial-bottom .testimonial-rating li i {
  font-size: 16px;
  color: #ff4838;
}

.testimonial-slider-two .slick-dots li button:before {
  font-size: 16px;
  opacity: 1;
  margin-top: 10px;
  color: #ff4838;
}

.testimonial-slider-two .slick-dots li.slick-active button:before {
  color: #fff;
}

.testimonial-card-gamma {
  background: #ffffff;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 5px;
  max-width: 790px;
}

.testimonial-card-gamma .testimonial-quote-icon {
  text-align: center;
}

.testimonial-card-gamma .testimonial-card-body {
  padding: 30px 30px;
}

.testimonial-card-gamma .testimonial-card-body .review-texts p {
  font-size: 16px;
  line-height: 30px;
  color: #666666;
}

.testimonial-card-gamma .testimonial-card-body .testimonial-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.testimonial-card-gamma .testimonial-card-body .testimonial-info .reviewr-info {
  padding-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.testimonial-card-gamma .testimonial-card-body .testimonial-info .reviewr-info .reviwer-info-cotn {
  margin-left: 15px;
}

.testimonial-card-gamma .testimonial-card-body .testimonial-info .reviewr-info .reviewer-name {
  font-weight: 600;
  font-size: 23px;
  text-transform: capitalize;
  color: #696969;
}

.testimonial-card-gamma .testimonial-card-body .testimonial-info .reviewr-info p {
  font-size: 18px;
  text-transform: capitalize;
  color: #a5a5a5;
  padding-top: 4px;
}

.testimonial-card-gamma .testimonial-card-body .testimonial-info .reviewer-rating {
  padding-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.testimonial-card-gamma .testimonial-card-body .testimonial-info .reviewer-rating li {
  margin-right: 8px;
}

.testimonial-card-gamma .testimonial-card-body .testimonial-info .reviewer-rating li i {
  color: #ff4838;
  font-size: 18px;
}

.testimonial-card-gamma .testimonial-card-body .testimonial-info .reviewer-rating li:last-child {
  margin-right: 0;
}

.slick-slide.slick-center {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

.slick-slide.slick-center .slider-item .single-thumb img {
  border: 2px solid #ff4838;
  border-radius: 50%;
}

.testimonial-style-two {
  background: url(../../../public/images/banner/testi-bg.png) no-repeat center;
  background-size: cover;
  padding: 70px 0 70px 0;
}

.testimonial-style-two .section-head-gamma h2 {
  color: #fff;
}

.testimonial-style-two .testimonial-thumb {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
  width: auto;
  padding-left: 15px;
  text-align: center;
}

.testimonial-style-two .testimonial-thumb .swiper-slide {
  height: auto !important;
  margin: 20px 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.testimonial-style-two .testimonial-thumb .swiper-slide .single-thumb img {
  max-width: 100px;
  border-radius: 50%;
  -webkit-transform: scale(1);
  transform: scale(1);
  border: 2px solid transparent;
}

.testimonial-style-two .testimonial-thumb .swiper-slide.swiper-slide-active .single-thumb img {
  max-width: 100px;
  border-radius: 50%;
  border: 2px solid #fff;
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.testimonial-style-two .testimonial-thumb .swiper-slide img {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

/* =============================
    testimonial area end
==============================*/
/* =============================
  11. Guide area start
==============================*/
.guide-card-alpha {
  background: #ffffff;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 0px;
}

.guide-card-alpha:hover .guide-image .guide-social-links {
  -webkit-transform: translate(-50%, -16px);
  transform: translate(-50%, -16px);
  opacity: 1;
}

.guide-card-alpha .guide-image {
  position: relative;
}

.guide-card-alpha .guide-image img {
  width: 100%;
}

.guide-card-alpha .guide-image .guide-social-links {
  position: absolute;
  left: 50%;
  bottom: 0;
  opacity: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  background: #2d373c;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 10px;
  padding: 12px 14px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.guide-card-alpha .guide-image .guide-social-links li {
  margin-right: 18px;
}

.guide-card-alpha .guide-image .guide-social-links li:last-child {
  margin-right: 0;
}

.guide-card-alpha .guide-image .guide-social-links li a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 28px;
  width: 28px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 30px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #162b32;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.guide-card-alpha .guide-image .guide-social-links li a:hover {
  background: #ff4838;
  color: #fff;
}

.guide-card-alpha .guide-image .guide-social-links li a i {
  font-size: 14px;
}

.guide-card-alpha .guide-content {
  text-align: center;
  padding: 25px;
}

.guide-card-alpha .guide-content .guide-name {
  font-weight: 700;
  font-size: 23px;
  text-transform: capitalize;
  color: #2d373c;
}

.guide-card-alpha .guide-content .guide-name a {
  color: inherit;
}

.guide-card-alpha .guide-content .guide-designation {
  font-weight: 500;
  font-size: 18px;
  text-transform: capitalize;
  color: #666666;
  padding-top: 5px;
}

.guide-card-gamma {
  background: #ffffff;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 5px;
  overflow: hidden;
}

.guide-card-gamma:hover .guide-image .guide-social-links {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}

.guide-card-gamma .guide-image {
  position: relative;
}

.guide-card-gamma .guide-image img {
  width: 100%;
}

.guide-card-gamma .guide-image .contact-lavel {
  position: absolute;
  left: 15px;
  bottom: 15px;
}

.guide-card-gamma .guide-image .contact-lavel a {
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  color: #ffffff;
  display: inline-block;
  background: #ff4838;
  border-radius: 100px;
  padding: 6px 22px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.guide-card-gamma .guide-image .contact-lavel a:hover {
  background: #666666;
}

.guide-card-gamma .guide-image .guide-social-links {
  position: absolute;
  top: 25px;
  right: 25px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: translateX(30px);
  transform: translateX(30px);
  opacity: 0;
}

.guide-card-gamma .guide-image .guide-social-links li {
  margin-bottom: 15px;
}

.guide-card-gamma .guide-image .guide-social-links li:last-child {
  margin-bottom: 0;
}

.guide-card-gamma .guide-image .guide-social-links li a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 30px;
  width: 30px;
  background: #fff;
  border-radius: 30px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #162b32;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.guide-card-gamma .guide-image .guide-social-links li a:hover {
  background: #ff4838;
  color: #fff;
}

.guide-card-gamma .guide-image .guide-social-links li a i {
  font-size: 15px;
}

.guide-card-gamma .guide-content {
  text-align: center;
  padding: 25px;
}

.guide-card-gamma .guide-content .guide-name {
  font-weight: 700;
  font-size: 22px;
  text-transform: capitalize;
  color: #2d373c;
}

.guide-card-gamma .guide-content .guide-name a {
  color: inherit;
}

.guide-card-gamma .guide-content .guide-designation {
  font-weight: 500;
  font-size: 15px;
  text-transform: capitalize;
  color: #666666;
  padding-top: 5px;
}

/* =============================
   Guide area end
==============================*/
/* =============================
   12. Blog area start
==============================*/
.blog-card-alpha {
  background: #ffffff;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 0px;
}

.blog-card-alpha:hover .blog-thumb img {
  -webkit-transform: scale(1.05) translateX(4px);
  transform: scale(1.05) translateX(4px);
}

.blog-card-alpha .blog-thumb {
  position: relative;
  overflow: hidden;
}

.blog-card-alpha .blog-thumb img {
  width: 100%;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.blog-card-alpha .blog-thumb .blog-lavel {
  position: absolute;
  bottom: 14px;
  left: 25px;
}

.blog-card-alpha .blog-thumb .blog-lavel a {
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  display: inline-block;
  background: #ff4838;
  -webkit-box-shadow: 0px 0px 15px rgba(206, 62, 38, 0.1);
  box-shadow: 0px 0px 15px rgba(206, 62, 38, 0.1);
  border-radius: 3px;
  padding: 6.5px 9px;
}

.blog-card-alpha .blog-thumb .blog-lavel a i {
  margin-right: 6px;
}

.blog-card-alpha .blog-content {
  padding: 20px;
  text-align: center;
}

.blog-card-alpha .blog-content .blog-body-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 35px;
}

.blog-card-alpha .blog-content .blog-body-top a {
  font-weight: 500;
  font-size: 14px;
  color: #696969;
}

.blog-card-alpha .blog-content .blog-body-top a i {
  color: #ff4838;
  margin-right: 5px;
}

.blog-card-alpha .blog-content .blog-title {
  padding-top: 8px;
  font-weight: 600;
  line-height: 28px;
  font-size: 20px;
  color: #2d373c;
}

.blog-card-alpha .blog-content .blog-title a {
  color: inherit;
}

.blog-card-gamma {
  background: #ffffff;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 5px 5px 20px 20px;
  overflow: hidden;
}

.blog-card-gamma:hover .blog-thumb img {
  -webkit-transform: scale(1.05) translateX(4px);
  transform: scale(1.05) translateX(4px);
}

.blog-card-gamma:hover .blog-thumb .blog-lavel a {
  background: #666666;
}

.blog-card-gamma .blog-thumb {
  position: relative;
  overflow: hidden;
}

.blog-card-gamma .blog-thumb img {
  width: 100%;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.blog-card-gamma .blog-thumb .blog-lavel {
  position: absolute;
  top: 25px;
  left: 25px;
}

.blog-card-gamma .blog-thumb .blog-lavel a {
  font-family:'Roboto-Regular';
  font-weight: 700;
  font-size: 15px;
  color: #ffffff;
  display: inline-block;
  background: #ff4838;
  -webkit-box-shadow: 0px 0px 15px rgba(206, 62, 38, 0.1);
  box-shadow: 0px 0px 15px rgba(206, 62, 38, 0.1);
  border-radius: 3px;
  padding: 4px 20px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.blog-card-gamma .blog-thumb .blog-lavel a i {
  margin-right: 6px;
}

.blog-card-gamma .blog-content {
  padding: 20px;
  text-align: center;
}

.blog-card-gamma .blog-content .blog-body-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 35px;
}

.blog-card-gamma .blog-content .blog-body-top a {
  font-weight: 500;
  font-size: 14px;
  color: #696969;
}

.blog-card-gamma .blog-content .blog-body-top a i {
  color: #ff4838;
  margin-right: 5px;
}

.blog-card-gamma .blog-content .blog-title {
  padding-top: 8px;
  font-weight: 600;
  line-height: 28px;
  font-size: 20px;
  color: #2d373c;
}

.blog-card-gamma .blog-content .blog-title a {
  color: inherit;
}

.blog-card-gamma-full {
  background: #ffffff;
  margin-top: 40px;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 5px 5px 20px 20px;
  overflow: hidden;
}

.blog-card-gamma-full:hover .blog-thumb img {
  -webkit-transform: scale(1.05) translateX(4px);
  transform: scale(1.05) translateX(4px);
}

.blog-card-gamma-full:hover .blog-thumb .blog-lavel a {
  background: #666666;
}

.blog-card-gamma-full .blog-thumb {
  position: relative;
  overflow: hidden;
}

.blog-card-gamma-full .blog-thumb img {
  width: 100%;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.blog-card-gamma-full .blog-thumb .blog-lavel {
  position: absolute;
  top: 25px;
  left: 25px;
}

.blog-card-gamma-full .blog-thumb .blog-lavel a {
  font-family:'Roboto-Regular';
  font-weight: 700;
  font-size: 15px;
  color: #ffffff;
  display: inline-block;
  background: #ff4838;
  -webkit-box-shadow: 0px 0px 15px rgba(206, 62, 38, 0.1);
  box-shadow: 0px 0px 15px rgba(206, 62, 38, 0.1);
  border-radius: 3px;
  padding: 7px 20px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.blog-card-gamma-full .blog-thumb .blog-lavel a i {
  margin-right: 6px;
}

.blog-card-gamma-full .blog-content {
  padding: 20px;
  text-align: center;
}

.blog-card-gamma-full .blog-content .blog-body-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 40px;
}

.blog-card-gamma-full .blog-content .blog-body-top a {
  font-weight: 500;
  font-size: 15px;
  color: #696969;
}

.blog-card-gamma-full .blog-content .blog-body-top a i {
  color: #ff4838;
  margin-right: 5px;
}

.blog-card-gamma-full .blog-content .blog-title {
  padding-top: 8px;
  font-weight: 600;
  line-height: 35px;
  font-size: 25px;
  color: #2d373c;
}

.blog-card-gamma-full .blog-content .blog-title a {
  color: inherit;
}

.blog-sidebar .blog-widget {
  background: #ffffff;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 5px;
}

.blog-sidebar .blog-widget .widget-title {
  padding: 20px 20px;
  border-bottom: 1px solid #eeeeee;
}

.blog-sidebar .blog-widget .widget-title h4 {
  font-weight: 600;
  font-size: 20px;
  color: #2d373c;
}

.blog-sidebar .blog-widget .widget-body {
  padding: 20px 20px;
}

.blog-sidebar .widget-search .search-input-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 44px;
}

.blog-sidebar .widget-search .search-input-group input {
  height: 100%;
  background: #f5f5f5;
  border-radius: 44px 0 0 44px;
  padding: 10px 15px;
}

.blog-sidebar .widget-search .search-input-group input::-webkit-input-placeholder {
  font-weight: 500;
  font-size: 12px;
  color: #666666;
  line-height: 1;
}

.blog-sidebar .widget-search .search-input-group input:-ms-input-placeholder {
  font-weight: 500;
  font-size: 12px;
  color: #666666;
  line-height: 1;
}

.blog-sidebar .widget-search .search-input-group input::-ms-input-placeholder {
  font-weight: 500;
  font-size: 12px;
  color: #666666;
  line-height: 1;
}

.blog-sidebar .widget-search .search-input-group input::placeholder {
  font-weight: 500;
  font-size: 12px;
  color: #666666;
  line-height: 1;
}

.blog-sidebar .widget-search .search-input-group input:focus {
  border: none;
}

.blog-sidebar .widget-search .search-input-group button {
  border: none;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
  background: #ff4838;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 0 44px 44px 0;
  margin-left: -1px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.blog-sidebar .widget-search .search-input-group button:hover {
  background: #162b32;
}

.blog-sidebar .widget-categories ul li {
  padding-top: 15px;
}

.blog-sidebar .widget-categories ul li:first-child {
  padding-top: 0;
}

.blog-sidebar .widget-categories ul li a {
  font-weight: 500;
  font-size: 16px;
  color: #2d373c;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.blog-sidebar .widget-categories ul li a:hover {
  color: #ff4838;
}

.blog-sidebar .widget-categories ul li a:hover h6 {
  -webkit-transform: translateX(5px);
  transform: translateX(5px);
}

.blog-sidebar .widget-categories ul li a h6 {
  display: inline-block;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

.blog-sidebar .widget-categories ul li a h6 i {
  margin-right: 8px;
  font-size: 14px;
}

.blog-sidebar .widget-categories ul li a span {
  float: right;
}

.blog-sidebar .widget-tag-cloud .widget-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: -10px;
}

.blog-sidebar .widget-tag-cloud .tag-cloud a {
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  color: #2d373c;
  border: 1px solid #2d373c4d;
  padding: 6px 18px;
  margin-right: 12px;
  margin-top: 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.blog-sidebar .widget-tag-cloud .tag-cloud a:hover {
  border-color: #ff4838;
  background: #ff4838;
  color: #fff;
}

.blog-sidebar .widget-gallary ul {
  display: -ms-grid;
  display: grid;
  grid-gap: 20px;
  -ms-grid-columns: (auto) [3];
  grid-template-columns: repeat(3, auto);
}

.blog-sidebar .widget-gallary ul li img {
  width: 100%;
}

.blog-sidebar .widget-recent-entries-custom ul li {
  display: table;
  margin-top: 25px;
}

.blog-sidebar .widget-recent-entries-custom ul li:first-child {
  margin-top: 0;
}

.blog-sidebar .widget-recent-entries-custom ul li:hover .wb h6 {
  color: #ff4838;
}

.blog-sidebar .widget-recent-entries-custom ul li .wi {
  float: left;
  width: 80px;
  vertical-align: middle;
  display: table-cell;
}

.blog-sidebar .widget-recent-entries-custom ul li .wi img {
  width: 100%;
}

.blog-sidebar .widget-recent-entries-custom ul li .wb {
  padding-left: 16px;
  vertical-align: middle;
  display: table-cell;
}

.blog-sidebar .widget-recent-entries-custom ul li .wb h6 {
  font-weight: 600;
  font-size: 15px;
  color: #2d373c;
  line-height: 20px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.blog-sidebar .widget-recent-entries-custom ul li .wb h6 a {
  color: inherit;
}

.blog-sidebar .widget-recent-entries-custom ul li .wb .wb-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
  gap: 30px;
  padding-top: 15px;
}

.blog-sidebar .widget-recent-entries-custom ul li .wb .wb-info span {
  font-weight: 500;
  font-size: 12px;
  color: #696969;
}

.blog-sidebar .widget-recent-entries-custom ul li .wb .wb-info span i {
  margin-right: 5px;
  color: #ff4838;
}

.blog-details .post-thumb {
  margin-top: 20px;
}

.blog-details .post-thumb img {
  border-radius: 5px;
  width: 100%;
}

.blog-details .post-header .post-title {
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  color: #2d373c;
}

.blog-details .post-header .post-meta {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
  padding-top: 10px;
  gap: 30px;
}

.blog-details .post-header .post-meta a {
  display: inline-block;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  margin-right: 20px;
  font-weight: 500;
  font-size: 15px;
  color: #696969;
}

.blog-details .post-header .post-meta a:hover {
  color: #ff4838;
}

.blog-details .post-header .post-meta a i {
  color: #ff4838;
  margin-right: 3px;
}

.blog-details .post-body .sub-title {
  font-weight: 700;
  font-size: 25px;
  text-transform: capitalize;
  color: #2d373c;
}

.blog-details .post-body p {
  font-size: 16px;
  line-height: 30px;
  color: #666666;
  margin-top: 20px;
}

.blog-details .post-body .post-innner-image img {
  width: 100%;
}

.blog-details .post-body .blog-quote-box-two {
  padding: 0 20px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.blog-details .post-body .blog-quote-box-two blockquote {
  margin: 0;
  padding-top: 20px;
}

.blog-details .post-body .blog-quote-box-two blockquote p {
  margin-top: 0;
  line-height: 34px;
  font-weight: 600;
  font-size: 20px;
  color: #2d373c;
}

.blog-details .post-body .blog-quote-box-one {
  background: #ffffff;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 100px 5px 5px 5px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.blog-details .post-body .blog-quote-box-one blockquote {
  padding: 30px 30px;
  margin: 0;
  margin-left: 30px;
}

.blog-details .post-body .blog-quote-box-one blockquote p {
  margin-top: 0;
  line-height: 34px;
  font-weight: 600;
  font-size: 20px;
  color: #2d373c;
}

.blog-details .post-body .post-innner-image {
  margin-top: 30px;
  margin-bottom: 30px;
}

.blog-details .post-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 18px 0;
  border-top: 1px solid rgba(45, 55, 60, 0.2);
  border-bottom: 1px solid rgba(45, 55, 60, 0.2);
  margin-top: 40px;
}

.blog-details .post-footer .post-switcher {
  font-weight: 600;
  font-size: 15px;
  color: #162b32;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.blog-details .post-footer .post-switcher:hover {
  color: #ff4838;
}

.blog-details .post-footer .post-switcher.prev-post i {
  margin-right: 8px;
}

.blog-details .post-footer .post-switcher.next-post i {
  margin-left: 8px;
}

.blog-details .post-footer .next-post-link {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #2d373c;
  padding: 0 40px;
}

.comment-section {
  padding: 50px 0;
}

.comment-section h4 {
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 30px;
  -webkit-text-decoration-line: underline !important;
  text-decoration-line: underline !important;
  color: #2d373c;
}

.comment-section .comment-list .single-comment {
  margin-top: 40px;
}

.comment-section .comment-list .single-comment:first-child {
  margin-top: 0px;
}

.comment-section .comment-list .single-comment .commmentor {
  margin-right: 25px;
  padding-top: 3px;
}

.comment-section .comment-list .single-comment .commmentor img {
  max-width: 145px;
  border-radius: 5px;
}

.comment-section .comment-list .single-comment .comment .info h6 {
  font-weight: 600;
  font-size: 20px;
  text-transform: capitalize;
  color: #454545;
  margin-bottom: 3px;
}

.comment-section .comment-list .single-comment .comment .info span {
  font-size: 14px;
  text-transform: capitalize;
  color: #ff4838;
}

.comment-section .comment-list .single-comment .comment .rating li {
  margin-right: 10px;
}

.comment-section .comment-list .single-comment .comment .rating li i {
  font-size: 17px;
  color: #ff4838;
}

.comment-section .comment-list .single-comment .comment .rating li:last-child {
  margin-right: 0;
}

.comment-section .comment-list .single-comment .comment p {
  font-size: 16px;
  line-height: 30px;
  text-transform: capitalize;
  padding-top: 8px;
  color: #666666;
}

.comment-section .comment-list .single-comment .comment .reply-btn {
  padding-top: 8px;
}

.comment-section .comment-list .single-comment .comment .reply-btn a {
  font-weight: 700;
  font-size: 15px;
  text-transform: capitalize;
  color: #162b32;
}

.comment-section .comment-list .single-comment .comment .reply-btn a:hover {
  color: #ff4838;
}

.comment-section .comment-list .single-comment .comment .reply-btn a i {
  margin-right: 7px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.comment-section .comment-btn {
  margin-top: 24px;
}

.comment-section .comment-btn a {
  font-weight: 600;
  font-size: 17px;
  color: #666666;
  display: inline-block;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.comment-section .comment-btn a:hover {
  color: #ff4838;
}

.comment-form {
  padding: 40px 50px;
  background: #ffffff;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 5px;
}

.comment-form h4 {
  font-weight: 700;
  font-size: 25px;
  color: #2d373c;
}

.comment-form .form-rating {
  margin-top: 25px;
}

.comment-form .form-rating li {
  margin-right: 7px;
}

.comment-form .form-rating li:last-child {
  margin-right: 0;
}

.comment-form .form-rating li i {
  font-size: 20px;
  color: #ff4838;
}

/* =============================
    Blog area end
==============================*/
/* =============================
   13. Footer area start
==============================*/
.footer-area .footer-main-wrapper {
  background: #2d373c;
  padding-top: 80px;
  position: relative;
  z-index: 1;
}

.footer-area .footer-main-wrapper .footer-vactor {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  z-index: -1;
}

.footer-area .footer-main-wrapper .footer-vactor img {
  max-width: 100%;
}

.footer-area .footer-main-wrapper .footer-about {
  color: #fff;
}

.footer-area .footer-main-wrapper .footer-about p {
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
}

.footer-area .footer-main-wrapper .footer-about .footer-social-wrap {
  padding-top: 35px;
}

.footer-area .footer-main-wrapper .footer-about .footer-social-wrap h5 {
  font-weight: 700;
  font-size: 25px;
  color: #ffffff;
}

.footer-area .footer-main-wrapper .footer-about .footer-social-wrap .footer-social-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-top: 10px;
}

.footer-area .footer-main-wrapper .footer-about .footer-social-wrap .footer-social-links li {
  margin-right: 20px;
  margin-top: 10px;
}

.footer-area .footer-main-wrapper .footer-about .footer-social-wrap .footer-social-links li:last-child {
  margin-right: 0;
}

.footer-area .footer-main-wrapper .footer-about .footer-social-wrap .footer-social-links li a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 37.5px;
  height: 37.5px;
  background: #ff4838;
  -webkit-box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.06);
  border-radius: 34px;
  border: 1px solid #ff4838;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  -webkit-transition: 0.35s;
  transition: 0.35s;
}

.footer-area .footer-main-wrapper .footer-about .footer-social-wrap .footer-social-links li a:hover {
  background: transparent;
  color: #ff4838;
}

.footer-area .footer-main-wrapper .footer-about .footer-social-wrap .footer-social-links li a:hover i {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.footer-area .footer-main-wrapper .footer-about .footer-social-wrap .footer-social-links li a i {
  font-size: 17px;
  -webkit-transition: 0.35s;
  transition: 0.35s;
}

.footer-area .footer-main-wrapper .footer-widget {
  color: #fff;
}

.footer-area .footer-main-wrapper .footer-widget .footer-widget-title {
  font-weight: 700;
  font-size: 25px;
  color: #ffffff;
  padding-bottom: 5px;
}

.footer-area .footer-main-wrapper .footer-widget .footer-links li {
  padding-top: 12px;
}

.footer-area .footer-main-wrapper .footer-widget .footer-links li a {
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #ffffff;
  position: relative;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

.footer-area .footer-main-wrapper .footer-widget .footer-links li a:hover {
  -webkit-transform: translateX(14px);
  transform: translateX(14px);
}

.footer-area .footer-main-wrapper .footer-widget .footer-links li a:hover::before {
  opacity: 1;
}

.footer-area .footer-main-wrapper .footer-widget .footer-links li a::before {
  position: absolute;
  content: "";
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: -12px;
  height: 5px;
  width: 5px;
  background: #ff4838;
  border-radius: 50%;
  opacity: 0;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

.footer-area .footer-main-wrapper .footer-widget .footer-gallary-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr) [3];
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  padding-top: 15px;
}

.footer-area .footer-main-wrapper .footer-widget .footer-gallary-grid .footer-gallary-item img {
  width: 100%;
  border-radius: 4px;
}

.footer-area .footer-contact-wrapper {
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 10px 0;
  margin-top: 80px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.footer-area .footer-contact-wrapper h5 {
  font-weight: 700;
  font-size: 25px;
  color: #ffffff;
}

.footer-area .footer-contact-wrapper .footer-contact-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.footer-area .footer-contact-wrapper .footer-contact-list li {
  margin-top: 15px;
  margin-bottom: 15px;
  margin-right: 60px;
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
}

.footer-area .footer-contact-wrapper .footer-contact-list li:last-child {
  margin-right: 0;
}

.footer-area .footer-contact-wrapper .footer-contact-list li i {
  margin-right: 5px;
}

.footer-area .footer-contact-wrapper .footer-contact-list li a {
  color: inherit;
}

.footer-area .footer-bottom {
  background: #162b32;
  color: #fff;
  padding: 13px 0;
}

.footer-area .footer-bottom .footer-logo {
  padding: 10px 0;
}

.footer-area .footer-bottom .copyright-link {
  padding: 10px 0;
}

.footer-area .footer-bottom .copyright-link p {
  font-size: 14px;
  color: #ffffff;
}

.footer-area .footer-bottom .copyright-link p a {
  color: inherit;
}

.footer-area .footer-bottom .policy-links .policy-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 0;
}

.footer-area .footer-bottom .policy-links .policy-list li {
  margin-right: 30px;
  position: relative;
}

.footer-area .footer-bottom .policy-links .policy-list li a {
  font-weight: 500;
  font-size: 15px;
  color: #ffffff;
}

.footer-area .footer-bottom .policy-links .policy-list li:last-child {
  margin-right: 0;
}

.footer-area .footer-bottom .policy-links .policy-list li:last-child::before {
  position: absolute;
  content: "";
  left: -15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: #ff4838;
  height: 12px;
  width: 1px;
}

/* =============================
    Footer area end
==============================*/
/* =============================
  14. world map area start
==============================*/
.world-map-area .world-map-wrap {
  min-height: 650px;
  position: relative;
}

.world-map-area .world-map-wrap .world-map-background {
  position: absolute;
  inset: 0;
}

.world-map-area .world-map-wrap .world-map-background img {
  width: 100%;
  height: 100%;
}

.world-map-area .world-map-wrap .single-location {
  position: absolute;
}

.world-map-area .world-map-wrap .single-location:hover .location-disc {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.world-map-area .world-map-wrap .single-location.location-1 {
  top: 20%;
  left: 10%;
}

.world-map-area .world-map-wrap .single-location.location-2 {
  top: 30%;
  left: 38%;
}

.world-map-area .world-map-wrap .single-location.location-3 {
  top: 40%;
  left: 68%;
}

.world-map-area .world-map-wrap .single-location.location-4 {
  top: 76%;
  left: 15%;
}

.world-map-area .world-map-wrap .single-location.location-5 {
  top: 70%;
  left: 50%;
}

.world-map-area .world-map-wrap .single-location .location-icon {
  height: 50px;
  width: 50px;
  display: inline-block;
  margin-left: 5px;
}

.world-map-area .world-map-wrap .single-location .location-icon i {
  font-size: 30px;
  color: #ff4838;
  cursor: pointer;
}

.world-map-area .world-map-wrap .single-location .location-disc {
  width: 300px;
  background: #ffffff;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 5px;
  padding: 25px;
  text-align: center;
  margin-top: 20px;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
}

.world-map-area .world-map-wrap .single-location .location-disc::before {
  position: absolute;
  content: "";
  bottom: 100%;
  left: 10px;
  height: 12px;
  width: 20px;
  background: #fff;
  -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.world-map-area .world-map-wrap .single-location .location-disc h6 {
  font-weight: 700;
  font-size: 20px;
  color: #2d373c;
  margin-bottom: 10px;
}

.world-map-area .world-map-wrap .single-location .location-disc p {
  font-size: 16px;
  line-height: 23px;
  color: #666666;
}

/* =============================
   world map area end
==============================*/
/* =============================
 15. About Wrapper start
==============================*/
.about-main-wrappper .about-tab-wrapper .about-tab-image-grid img {
  width: 100%;
}

.about-main-wrappper .about-tab-wrapper .about-tab-image-grid .about-video {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}

.about-main-wrappper .about-tab-wrapper .about-tab-image-grid .about-video .video-overlay {
  position: absolute;
  inset: 0;
  background: rgba(255, 72, 56, 0.5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.about-main-wrappper .about-tab-wrapper .about-tab-image-grid .about-video .video-overlay:hover .play-icon {
  background: rgba(125, 163, 182, 0.8);
  cursor: pointer;
}

.about-main-wrappper .about-tab-wrapper .about-tab-image-grid .about-video .video-overlay:hover .play-icon::before {
  background: rgba(45, 55, 60, 0.6);
  inset: -12px;
}

.about-main-wrappper .about-tab-wrapper .about-tab-image-grid .about-video .video-overlay:hover .play-icon::after {
  background: rgba(45, 55, 60, 0.5);
  inset: -30px;
}

.about-main-wrappper .about-tab-wrapper .about-tab-image-grid .about-video .video-overlay .play-icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 53.11px;
  height: 53.11px;
  background: rgba(45, 55, 60, 0.6);
  border-radius: 50%;
  position: relative;
  z-index: 1;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.about-main-wrappper .about-tab-wrapper .about-tab-image-grid .about-video .video-overlay .play-icon::before {
  position: absolute;
  content: "";
  inset: -6px;
  background: rgba(45, 55, 60, 0.5);
  border-radius: 50%;
  z-index: 1;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.about-main-wrappper .about-tab-wrapper .about-tab-image-grid .about-video .video-overlay .play-icon::after {
  position: absolute;
  content: "";
  inset: -12px;
  background: rgba(45, 55, 60, 0.4);
  border-radius: 50%;
  z-index: -1;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.about-main-wrappper .about-tab-wrapper .about-tab-image-grid .about-video .video-overlay .play-icon i {
  font-size: 30px;
  color: #ffffff;
  position: relative;
  z-index: 2;
}

.about-main-wrappper .about-tab-wrapper .about-tab-image-grid .float-images {
  margin-top: -60px;
}

.about-main-wrappper .about-tab-wrapper .about-tab-image-grid .float-images .about-image {
  border-radius: 5px;
  overflow: hidden;
  cursor: initial;
}

.about-main-wrappper .about-wrap-title {
  font-weight: 800;
  font-size: 45px;
  color: #2d373c;
}

.about-main-wrappper .about-wrap-title span {
  color: #ff4838;
}

.about-main-wrappper .about-image img,
.about-main-wrappper .about-image-group img {
  cursor: initial;
}

.about-main-wrappper .about-tab-wrap .about-tab-switcher {
  margin-top: 30px;
}

.about-main-wrappper .about-tab-wrap .about-tab-switcher .nav-item .nav-link {
  text-align: center;
  padding: 20px 12px;
  color: #162b32;
  min-width: 170px;
  cursor: pointer;
}

.about-main-wrappper .about-tab-wrap .about-tab-switcher .nav-item .nav-link.active {
  background: #ffffff;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  color: #ff4838;
}

.about-main-wrappper .about-tab-wrap .about-tab-switcher .nav-item .nav-link h3 {
  font-weight: 800;
  font-size: 40px;
  letter-spacing: 0.03em;
  color: inherit;
}

.about-main-wrappper .about-tab-wrap .about-tab-switcher .nav-item .nav-link h6 {
  font-size: 18px;
  color: inherit;
  padding-top: 10px;
}

.about-main-wrappper .about-tab-wrap .about-tab-content {
  padding-top: 10px;
}

.about-main-wrappper .about-tab-wrap .about-tab-content .tab-pane p {
  font-size: 16px;
  line-height: 30px;
  color: #666666;
}

.about-main-wrappper .achievement-counter-wrap .achievement-counter-cards .achievement-counter-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 30px;
  border: 1px solid #ff48384d;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 30px;
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}

.about-main-wrappper .achievement-counter-wrap .achievement-counter-cards .achievement-counter-card:hover {
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-color: transparent;
}

.about-main-wrappper .achievement-counter-wrap .achievement-counter-cards .achievement-counter-card:hover .counter-box h2,
.about-main-wrappper .achievement-counter-wrap .achievement-counter-cards .achievement-counter-card:hover .counter-box h6 {
  color: #2d373c;
}

.about-main-wrappper .achievement-counter-wrap .achievement-counter-cards .achievement-counter-card .counter-box {
  min-width: 133px;
}

.about-main-wrappper .achievement-counter-wrap .achievement-counter-cards .achievement-counter-card .counter-box h2 {
  font-weight: 800;
  font-size: 35px;
  letter-spacing: 0.03em;
  color: #ff4838;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.about-main-wrappper .achievement-counter-wrap .achievement-counter-cards .achievement-counter-card .counter-box h6 {
  padding-top: 7px;
  font-weight: 500;
  font-size: 18px;
  color: #ff4838;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.about-main-wrappper .achievement-counter-wrap .achievement-counter-cards .achievement-counter-card p {
  margin-left: 15px;
  font-size: 15px;
  line-height: 25px;
  color: #666666;
}

/* =============================
   About Wrapper end
==============================*/
/* =============================
  16. Contact Wrapper start
==============================*/
.contact-info {
  background: #ffffff;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 5px;
  padding: 40px 40px 45px 40px;
  margin-left: -80px;
}

.contact-info h3 {
  font-size: 30px;
  color: #2d373c;
  font-weight: 700;
  display: inline-block;
  position: relative;
  padding-bottom: 7px;
}

.contact-info h3::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 80px;
  height: 3px;
  background: -webkit-gradient(linear,
      left top,
      right top,
      color-stop(46.35%, #ff4838),
      to(rgba(255, 255, 255, 0)));
  background: linear-gradient(90deg,
      #ff4838 46.35%,
      rgba(255, 255, 255, 0) 100%);
  border-radius: 5px;
}

.contact-info ul li {
  background: #ffffff;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 5px;
  padding: 15px 20px;
  margin-top: 30px;
  border-radius: 5px 35px 5px 5px;
  border: 1px solid #eeeeee;
  max-width: 450px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.contact-info ul li:hover {
  background: #00152b;
}

.contact-info ul li:hover h6,
.contact-info ul li:hover span {
  color: #fff;
}
.contact-info ul li:hover a {
  color: #fff;
}
.contact-info ul li h6 {
  font-weight: 700;
  font-size: 16px;
  color: #2d373c;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.contact-info ul li span{
  font-weight: 500;
  font-size: 16px;
  color: #5c5c5c;
  display: inline-block;
  margin-top: 8px;
  margin-right: 20px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.contact-info ul li a{
  font-weight: 500;
  font-size: 16px;
  color: #5c5c5c;
  display: inline-block;
  margin-top: 8px;
  margin-right: 20px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.contact-map .mapouter {
  position: relative;
  text-align: right;
  width: 100%;
  margin-top: 40px;
}

.contact-map iframe {
  width: 100%;
  height: 550px;
}

.contact-form-wrap {
  background: #ffffff;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 5px;
  padding: 40px;
}

.contact-form-wrap h4 {
  font-weight: 700;
  font-size: 25px;
  color: #2d373c;
}

.contact-form-wrap p {
  font-size: 16px;
  color: #5c5c5c;
  margin-top: 10px;
}

/* =============================
   Contact Wrapper end
==============================*/
/* =============================
  17. FAQ wrapper start
==============================*/
.faq-wrapper .faqs .accordion-header {
  padding-bottom: 0;
}

.faq-wrapper .faqs h2 {
  font-weight: 800;
  font-size: 60px;
  letter-spacing: 0.03em;
  color: #2d373c;
  padding-bottom: 26px;
}

.faq-wrapper .faqs h2 span {
  color: #ff4838;
}

.faq-wrapper .faq-sidebar .faq-topics {
  background: #ffffff;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 10px;
  padding: 25px;
}

.faq-wrapper .faq-sidebar .faq-topics h3 {
  font-weight: 800;
  font-size: 28px;
  letter-spacing: 0.02em;
  color: #2d373c;
  padding-bottom: 5px;
}

.faq-wrapper .faq-sidebar .faq-topics .radio-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 10px;
}

.faq-wrapper .faq-sidebar .faq-topics .radio-group label {
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.02em;
  color: #2d373c;
}

.faq-wrapper .faq-sidebar .faq-topics .radio-group input {
  padding: 0;
  height: 14px;
  width: 14px;
  margin: 0;
  border: 2px solid #2d373c;
  margin-right: 10px;
}

.faq-wrapper .faq-sidebar .faq-topics .radio-group input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.faq-wrapper .faq-sidebar .faq-topics .radio-group input:checked {
  background-image: none;
  border-color: #ff4838;
  background: #ff4838;
}

.faq-wrapper .faq-sidebar .faq-form {
  background: #ffffff;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 10px;
  padding: 25px;
  margin-top: 30px;
}

.faq-wrapper .faq-sidebar .faq-form h3 {
  font-weight: 800;
  font-size: 28px;
  letter-spacing: 0.02em;
  color: #2d373c;
}

.faq-wrapper .faq-sidebar .faq-form .custom-input-group button[type="submit"] {
  display: block;
  width: 100%;
}

.faq-wrapper .faq-sidebar .faq-form .custom-input-grou {
  margin-top: 30px;
}

/* =============================
   FAQ wrapper end
==============================*/
/* =============================
   home3 style
==============================*/
.searchbar-section {
  margin-top: -48px;
}

.searchbar-section.home4 {
  margin-top: -390px;
}

.all-package-btn {
  border-radius: 5px;
}

.package-card-beta {
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  background: #fff;
}

.package-card-beta:hover .package-card-body::before {
  height: 100%;
  opacity: 0.1;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.package-card-beta .package-thumb {
  position: relative;
}

.package-card-beta .package-thumb img {
  width: 100%;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

.package-card-beta .package-thumb .card-lavel {
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  color: #ff4838;
  background: #ffffff;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 30px;
  position: absolute;
  padding: 9px 15px;
  bottom: -21px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  min-width: 170px;
  cursor: pointer;
  z-index: 9;
}

.package-card-beta .package-thumb .card-lavel i {
  margin-right: 8px;
}

.package-card-beta .package-thumb .card-lavel:hover {
  background-color: #ff4838;
  color: #fff;
}

.package-card-beta .package-card-body {
  padding: 30px 25px;
  position: relative;
  z-index: 1;
}

.package-card-beta .package-card-body::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: var(--c-primary);
  z-index: -1;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  opacity: 0;
  -webkit-transform: scale(0, 0);
  transform: scale(0, 0);
}

.package-card-beta .package-card-body .p-card-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #454545;
  margin-top: 5px;
}

.package-card-beta .package-card-body .p-card-title a {
  color: inherit;
}

.package-card-beta .package-card-body .p-card-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 20px;
}

.package-card-beta .package-card-body .p-card-bottom .book-btn a {
  display: inline-block;
  font-weight: 600;
  font-size: 15px;
  text-transform: uppercase;
  color: #ff4838;
  background: #ffffff;
  border: 1px solid #ff4838;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 30px;
  padding: 8px 16px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  white-space: nowrap;
}

@media (max-width: 991px) {
  .package-card-beta .package-card-body .p-card-bottom .book-btn a {
    padding: 6px 10px;
  }
}

.package-card-beta .package-card-body .p-card-bottom .book-btn a i {
  margin-left: 5px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.package-card-beta .package-card-body .p-card-bottom .book-btn:hover a {
  background: var(--c-primary);
  color: #fff;
}

.package-card-beta .package-card-body .p-card-bottom .book-btn:hover i {
  -webkit-transform: translateX(5px);
  transform: translateX(5px);
}

.package-card-beta .package-card-body .p-card-bottom .p-card-info {
  padding-left: 10px;
}

.package-card-beta .package-card-body .p-card-bottom .p-card-info h6 {
  color: #ff4838;
  font-weight: 800;
  font-size: 20px;
  line-height: 1;
}

@media (max-width: 991px) {
  .package-card-beta .package-card-body .p-card-bottom .p-card-info h6 {
    font-size: 14px;
  }
}

.package-card-beta .package-card-body .p-card-bottom .p-card-info h6 span {
  font-size: 14px;
}

.package-card-beta .package-card-body .p-card-bottom .p-card-info span {
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
  color: #2d373c;
}

.upcoming-tour-area {
  background-image: -webkit-gradient(linear,
      left top,
      left bottom,
      from(rgba(0, 5, 15, 0.6)),
      to(rgba(0, 5, 15, 0.6))),
    url("../../../public/images/package/upcoming-bg.png");
  background-image: linear-gradient(rgba(0, 5, 15, 0.6), rgba(0, 5, 15, 0.6)),
    url("../../../public/images/package/upcoming-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.upcoming-tour-area .upcoming-btn {
  display: inline-block;
}

.upcoming-tour-area .slider-arrows .testi-prev4,
.upcoming-tour-area .testimonial-style-three .slider-arrows .testi-next4,
.testimonial-style-three .upcoming-tour-area .slider-arrows .testi-next4,
.testimonial-style-three .upcoming-tour-area .slider-arrows .testi-next5 {
  color: var(--c-primary);
  border-radius: 50%;
  background: rgba(255, 72, 56, 0.6);
  color: #fff;
  height: 30px;
  line-height: 30px;
  width: 30px;
  text-align: center;
  position: relative;
  overflow: hidden;
  z-index: 9;
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
}

.upcoming-tour-area .slider-arrows .testi-prev4::before,
.upcoming-tour-area .testimonial-style-three .slider-arrows .testi-next4::before,
.testimonial-style-three .upcoming-tour-area .slider-arrows .testi-next4::before,
.testimonial-style-three .upcoming-tour-area .slider-arrows .testi-next5::before {
  content: "";
  position: absolute;
  left: -1px;
  top: -1px;
  height: 31px;
  width: 31px;
  background: var(--c-primary);
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
  border-radius: 50%;
  z-index: -1;
}

.upcoming-tour-area .slider-arrows .testi-prev4:hover,
.upcoming-tour-area .testimonial-style-three .slider-arrows .testi-next4:hover,
.testimonial-style-three .upcoming-tour-area .slider-arrows .testi-next4:hover,
.testimonial-style-three .upcoming-tour-area .slider-arrows .testi-next5:hover {
  color: #fff;
}

.upcoming-tour-area .slider-arrows .testi-prev4:hover::before,
.upcoming-tour-area .testimonial-style-three .slider-arrows .testi-next4:hover::before,
.testimonial-style-three .upcoming-tour-area .slider-arrows .testi-next4:hover::before,
.testimonial-style-three .upcoming-tour-area .slider-arrows .testi-next5:hover::before {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.upcoming-tour-area .slider-arrows .testi-next4 {
  color: var(--c-primary);
  border-radius: 50%;
  background: rgba(255, 72, 56, 0.6);
  color: #fff;
  height: 30px;
  line-height: 30px;
  width: 30px;
  text-align: center;
  position: relative;
  overflow: hidden;
  z-index: 9;
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
}

.upcoming-tour-area .slider-arrows .testi-next4::before {
  content: "";
  position: absolute;
  right: -1px;
  top: -1px;
  height: 31px;
  width: 31px;
  background: var(--c-primary);
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
  border-radius: 50%;
  z-index: -1;
}

.upcoming-tour-area .slider-arrows .testi-next4:hover {
  color: #fff;
}

.upcoming-tour-area .slider-arrows .testi-next4:hover::before {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.package-area .slider-arrows .testi-next4,
.package-area .slider-arrows .testi-prev4 {
  color: var(--c-primary);
  border-radius: 50%;
  background: rgba(255, 72, 56, 0.6);
  color: #fff;
  height: 30px;
  line-height: 30px;
  width: 30px;
  text-align: center;
  position: relative;
  overflow: hidden;
  z-index: 9;
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
}

.package-area .slider-arrows .testi-next4::before,
.package-area .slider-arrows .testi-prev4::before {
  content: "";
  position: absolute;
  right: -1px;
  top: -1px;
  height: 31px;
  width: 31px;
  background: var(--c-primary);
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
  border-radius: 50%;
  z-index: -1;
}

.package-area.slider-arrows .testi-next4:hover,
.package-area.slider-arrows .testi-prev4:hover {
  color: #fff;
}

.package-area .slider-arrows .testi-next4:hover::before,
.package-area .slider-arrows .testi-prev4:hover::before {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.package-card-delta {
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  /* box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1); */
  box-shadow: 3px 5px 35px #ccc;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  background: #fff;
}

.package-card-delta:hover .package-thumb .card-lavel {
  opacity: 1;
  -webkit-transform: translateY(0px) scale(1);
  transform: translateY(0px) scale(1);
}

.package-card-delta:hover .package-card-body::before {
  height: 100%;
  opacity: 0.1;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.package-card-delta .package-thumb {
  position: relative;
}

.package-card-delta .package-thumb img {
  width: 100%;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

.package-card-delta .package-thumb .card-lavel {
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  background-color: #ff4838;
  color: #fff;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 30px;
  position: absolute;
  padding: 9px 15px;
  top: 10px;
  /* left: 25px; */
  right: 0;
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
  min-width: 170px;
  cursor: pointer;
  z-index: 9;
  opacity: 0;
  -webkit-transform: translateY(10px) scale(0.5);
  transform: translateY(10px) scale(0.5);
}

.package-card-delta .package-thumb .card-lavel i {
  margin-right: 8px;
}

.package-card-delta .package-thumb .card-lavel:hover {
  color: #ff4838;
  background-color: #fff;
}

.package-card-delta .package-card-body {
  padding: 30px 25px;
  position: relative;
  z-index: 1;
}

.package-card-delta .package-card-body::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: var(--c-primary);
  z-index: -1;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  opacity: 0;
  -webkit-transform: scale(0, 0);
  transform: scale(0, 0);
}

.package-card-delta .package-card-body .p-card-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #454545;
  margin-top: 5px;
}

.package-card-delta .package-card-body .p-card-title a {
  color: inherit;
}

.package-card-delta .package-card-body .p-card-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 20px;
}

.package-card-delta .package-card-body .p-card-bottom .book-btn a {
  display: inline-block;
  font-weight: 600;
  font-size: 15px;
  text-transform: uppercase;
  color: #ff4838;
  background: #ffffff;
  border: 1px solid #ff4838;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 30px;
  padding: 8px 16px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  white-space: nowrap;
}

@media (max-width: 991px) {
  .package-card-delta .package-card-body .p-card-bottom .book-btn a {
    padding: 6px 10px;
  }
}

.package-card-delta .package-card-body .p-card-bottom .book-btn a i {
  margin-left: 5px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.package-card-delta .package-card-body .p-card-bottom .book-btn:hover a {
  background: var(--c-primary);
  color: #fff;
}

.package-card-delta .package-card-body .p-card-bottom .book-btn:hover i {
  -webkit-transform: translateX(5px);
  transform: translateX(5px);
}

.package-card-delta .package-card-body .p-card-bottom .p-card-info {
  padding-left: 10px;
}

.package-card-delta .package-card-body .p-card-bottom .p-card-info h6 {
  color: #ff4838;
  font-weight: 800;
  font-size: 20px;
  line-height: 1;
}

@media (max-width: 991px) {
  .package-card-delta .package-card-body .p-card-bottom .p-card-info h6 {
    font-size: 14px;
  }
}

.package-card-delta .package-card-body .p-card-bottom .p-card-info h6 span {
  font-size: 14px;
}

.package-card-delta .package-card-body .p-card-bottom .p-card-info span {
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
  color: #2d373c;
}

.package-card-gamma {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #ffffff;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 30px;
}

.package-card-gamma:hover .package-card-body .p-card-bottom .book-btn a {
  background: #ff4838;
  color: #fff;
}

.package-card-gamma:hover .package-card-body .p-card-bottom .book-btn a i {
  -webkit-transform: translateX(5px);
  transform: translateX(5px);
}

.package-card-gamma .package-thumb img {
  max-width: 355px;
  border-radius: 10px;
}

.package-card-gamma .package-card-body {
  text-align: center;
  padding-left: 30px;
  padding-right: 20px;
}

.package-card-gamma .package-card-body .card-lavel {
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  color: #ffffff;
  background: #ff4838;
  border-radius: 3px;
  padding: 9px 32px;
  -webkit-clip-path: polygon(9% 0, 90% 3%, 100% 94%, 0 100%);
  clip-path: polygon(9% 0, 90% 3%, 100% 94%, 0 100%);
  display: inline-block;
}

.package-card-gamma .package-card-body .card-lavel i {
  margin-right: 8px;
}

.package-card-gamma .package-card-body .p-card-title {
  padding-top: 18px;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #454545;
  text-align: start;
}

.package-card-gamma .package-card-body .p-card-title a {
  color: inherit;
}

.package-card-gamma .package-card-body .p-card-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 20px;
}

.package-card-gamma .package-card-body .p-card-bottom .book-btn a {
  display: inline-block;
  font-weight: 600;
  font-size: 15px;
  text-transform: uppercase;
  color: #ff4838;
  background: #ffffff;
  border: 1px solid #ff4838;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 8px 16px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.package-card-gamma .package-card-body .p-card-bottom .book-btn a i {
  margin-left: 5px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.package-card-gamma .package-card-body .p-card-bottom .p-card-info {
  text-align: start;
}

.package-card-gamma .package-card-body .p-card-bottom .p-card-info h6 {
  color: #ff4838;
  font-weight: 800;
  font-size: 20px;
  line-height: 1;
}

.package-card-gamma .package-card-body .p-card-bottom .p-card-info h6 span {
  font-size: 14px;
}

.package-card-gamma .package-card-body .p-card-bottom .p-card-info span {
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
  color: #2d373c;
}

.package-bottom-btn {
  margin-top: 50px;
}

.offer-switch-button {
  margin-bottom: 30px;
}

.offer-switch-button .nav-item {
  height: 87px;
  width: 87px;
  min-width: 87px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 18px;
}

.offer-switch-button .nav-item .nav-link {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  font-weight: 700;
  font-size: 20px;
  color: #ff4838;
  border: 2px solid #ff4838;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

.offer-switch-button .nav-item .nav-link.active {
  height: 87px;
  width: 87px;
  font-size: 28px;
  color: #fff;
  background: #ff4838;
}

.offer-switch-button2 {
  margin-bottom: 30px;
}

.offer-switch-button2 .nav-item {
  min-width: 87px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 18px;
}

.offer-switch-button2 .nav-item .nav-link {
  min-height: 40px;
  min-width: 95px;
  border-radius: 5px;
  font-weight: 700;
  font-size: 20px;
  color: #ff4838;
  border: 2px solid #ff4838;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.offer-switch-button2 .nav-item .nav-link.active {
  color: #fff;
  background: #ff4838;
  position: relative;
}

.offer-switch-button2 .nav-item .nav-link.active::before {
  content: "";
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
  transform: translateX(-50%) rotate(45deg);
  bottom: -5px;
  height: 12px;
  width: 12px;
  background-color: var(--c-primary);
}

.offer-switch-button2 .nav-item .nav-link:hover {
  color: #fff;
  background: #ff4838;
}

.offer-switch-button3 {
  margin-bottom: 30px;
}

.offer-switch-button3 .nav-item {
  min-width: 87px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 18px;
}

.offer-switch-button3 .nav-item .nav-link {
  min-height: 40px;
  min-width: 95px;
  border-radius: 5px;
  font-weight: 700;
  font-size: 20px;
  color: #7d878c;
  border: 2px solid #7d878c;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.offer-switch-button3 .nav-item .nav-link.active {
  color: #fff;
  background: #ff4838;
  border: 1px solid #ff4838;
  position: relative;
}

.offer-switch-button3 .nav-item .nav-link.active::before {
  content: "";
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
  transform: translateX(-50%) rotate(45deg);
  bottom: -5px;
  height: 12px;
  width: 12px;
  background-color: var(--c-primary);
}

.offer-switch-button3 .nav-item .nav-link:hover {
  color: #fff;
  background: #ff4838;
  border: 1px solid #ff4838;
}

.package-sidebar .package-widget-style-2 {
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 5px;
}

.package-sidebar .package-widget-style-2 .widget-title {
  background: #162b32;
  border-radius: 5px;
  padding: 13px 20px;
}

.package-sidebar .package-widget-style-2 .widget-title h4 {
  font-weight: 700;
  color: #ffffff;
  font-size: 20px;
  margin-top: 3px;
}

.package-sidebar .package-widget-style-2 .widget-lavel {
  font-weight: 700;
  font-size: 25px;
  color: #ff4838;
}

.package-sidebar .package-widget-style-2 .widget-lavel span {
  font-size: 15px;
  font-weight: 500;
  color: #fff;
}

.package-sidebar .package-widget-style-2 .widget-body {
  padding: 20px 20px;
}

.package-sidebar .package-widget {
  background: #ffffff;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 5px;
}

.package-sidebar .package-widget .widget-title {
  padding: 20px 20px;
  border-bottom: 1px solid #eeeeee;
}

.package-sidebar .package-widget .widget-title h4 {
  font-weight: 600;
  font-size: 20px;
  color: #2d373c;
}

.package-sidebar .package-widget .widget-body {
  padding: 20px 20px;
}

.package-sidebar .widget-tag-cloud .tag-cloud a {
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  color: #2d373c;
  border: 1px solid #2d373c4d;
  padding: 6px 18px;
  margin-right: 12px;
  margin-top: 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.package-sidebar .widget-tag-cloud .tag-cloud a:hover {
  border-color: #ff4838;
  background: #ff4838;
  color: #fff;
}

.package-sidebar .widget-search .search-input-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 44px;
}

.package-sidebar .widget-search .search-input-group input {
  height: 100%;
  background: #f5f5f5;
  border-radius: 44px 0 0 44px;
  padding: 10px 15px 10px 15px;
}

.package-sidebar .widget-search .search-input-group input::-webkit-input-placeholder {
  font-weight: 500;
  font-size: 12px;
  color: #666666;
  line-height: 1;
}

.package-sidebar .widget-search .search-input-group input:-ms-input-placeholder {
  font-weight: 500;
  font-size: 12px;
  color: #666666;
  line-height: 1;
}

.package-sidebar .widget-search .search-input-group input::-ms-input-placeholder {
  font-weight: 500;
  font-size: 12px;
  color: #666666;
  line-height: 1;
}

.package-sidebar .widget-search .search-input-group input::placeholder {
  font-weight: 500;
  font-size: 12px;
  color: #666666;
  line-height: 1;
}

.package-sidebar .widget-search .search-input-group input:focus {
  border: none;
}

.package-sidebar .widget-search .search-input-group button {
  border: none;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
  background: #ff4838;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 0 44px 44px 0;
  margin-left: -1px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.package-sidebar .widget-search .search-input-group button:hover {
  background: #162b32;
}

.package-sidebar .widget-duration .deration-check {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 0px;
}

.package-sidebar .widget-duration .deration-check:first-child {
  margin-top: 0;
}

.package-sidebar .widget-duration .deration-check label {
  font-weight: 500;
  font-size: 16px;
  color: #2d373c;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.package-sidebar .widget-duration .deration-check label:hover {
  color: #ff4838;
}

.package-sidebar .widget-duration .deration-check label i {
  margin-right: 5px;
  font-size: 14px;
}

.package-sidebar .widget-duration .deration-check input {
  height: 18px;
  width: 18px;
  margin: 0;
  padding: 0;
  display: inline-block;
  margin-right: 15px;
}

.package-sidebar .widget-duration .deration-check input:focus {
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

.package-sidebar .widget-duration .deration-check input:checked {
  background-color: #ff4838;
  border-color: #ff4838;
}

.package-sidebar .widget-tour-categoris .category-check {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 15px;
}

.package-sidebar .widget-tour-categoris .category-check:first-child {
  margin-top: 0;
}

.package-sidebar .widget-tour-categoris .category-check label {
  font-weight: 500;
  font-size: 16px;
  color: #2d373c;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.package-sidebar .widget-tour-categoris .category-check label:hover {
  color: #ff4838;
}

.package-sidebar .widget-tour-categoris .category-check label i {
  margin-right: 5px;
  font-size: 14px;
}

.package-sidebar .widget-tour-categoris input {
  height: 18px;
  width: 18px;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.package-sidebar .widget-tour-categoris input:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(13, 253, 58, 0.25);
  box-shadow: 0 0 0 0.25rem rgba(13, 253, 58, 0.25);
}

.package-sidebar .widget-tour-categoris input:checked {
  background-color: #ff4838;
  border-color: #ff4838;
}

.package-sidebar .widget-recent-package-entries .package-sm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 25px;
}

.package-sidebar .widget-recent-package-entries .package-sm:first-child {
  margin-top: 0;
}

.package-sidebar .widget-recent-package-entries .package-sm .thumb {
  min-width: 80px;
  width: 80px;
  margin-right: 15px;
}

.package-sidebar .widget-recent-package-entries .package-sm .thumb img {
  width: 100%;
}

.package-sidebar .widget-recent-package-entries .package-sm .info h6 {
  font-weight: 600;
  font-size: 15px;
  color: #454545;
  line-height: 20px;
}

.package-sidebar .widget-recent-package-entries .package-sm .info h6:hover {
  color: #ff4838;
}

.package-sidebar .widget-recent-package-entries .package-sm .info h6 a {
  color: inherit;
}

.package-sidebar .widget-recent-package-entries .package-sm .info .price span {
  color: #2d373c;
  font-size: 12px;
  font-weight: 500;
}

.package-sidebar .widget-recent-package-entries .package-sm .info .price h6 {
  font-weight: 800;
  font-size: 16px;
  line-height: 16px;
  text-transform: capitalize;
  color: #ff4838;
}

.package-sidebar .widget-gallary ul {
  display: -ms-grid;
  display: grid;
  grid-gap: 20px;
  -ms-grid-columns: (auto) [3];
  grid-template-columns: repeat(3, auto);
}

.package-sidebar .widget-gallary ul li img {
  width: 100%;
}

.react-datepicker__input-container input {
  padding: 7px 0px;
}

.booking-form-wrapper .custom-input-group {
  margin-top: 20px;
}

.booking-form-wrapper .custom-input-group .submite-btn {
  padding-top: 10px;
}

.booking-form-wrapper .custom-input-group .submite-btn button {
  width: 100%;
  display: block;
}

.booking-form-wrapper .custom-input-group textarea {
  background: #fff;
}

.numberType::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.booking-form-wrapper .custom-input-group input {
  height: 45px;
  padding: 7px 12px;
  background: #fff;
}

.booking-form-wrapper .custom-input-group select {
  height: 45px;
  padding: 0 15px;
  font-weight: 500;
  font-size: 13px;
  color: #2d373c;
  background: #fff;
}

.tour-package-details .pd-header {
  margin-bottom: 30px;
}

.tour-package-details .pd-header .pd-top .pd-single-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.tour-package-details .pd-header .pd-top .pd-single-info .info-icon {
  margin-right: 15px;
  margin-top: 4px;
}

.tour-package-details .pd-header .pd-top .pd-single-info .info-icon img {
  max-width: 42px;
}

.tour-package-details .pd-header .pd-top .pd-single-info .info h6 {
  font-weight: 600;
  font-size: 17px;
  color: #454545;
}

.tour-package-details .pd-header .pd-top .pd-single-info .info span {
  font-weight: 600;
  font-size: 14px;
  color: #666666;
}

.tour-package-details .pd-header .pd-thumb {
  padding-top: 30px;
}

.tour-package-details .pd-header .pd-thumb img {
  width: 100%;
  border-radius: 5px;
}

.tour-package-details .pd-header .header-bottom {
  padding: 30px 0 30px 0;
  border-bottom: 1px solid #eeeeee;
}

.tour-package-details .pd-header .header-bottom .pd-lavel .location {
  font-weight: 600;
  font-size: 20px;
  color: #ff4838;
}

.tour-package-details .pd-header .header-bottom .pd-lavel .rating li {
  margin-right: 8px;
}

.tour-package-details .pd-header .header-bottom .pd-lavel .rating li i {
  font-size: 17px;
  color: #ff4838;
}

.tour-package-details .pd-header .header-bottom .pd-lavel .rating li:last-child {
  margin-right: 0;
}

.tour-package-details .pd-header .header-bottom .pd-title {
  font-weight: 700;
  font-size: 35px;
  text-transform: capitalize;
  color: #2d373c;
}

.tour-package-details .package-details-tabs {
  position: relative;
}

.tour-package-details .package-details-tabs .tab-switchers {
  background: #fff;
  top: 0;
  left: 0;
  z-index: 9;
  margin-bottom: 50px;
}

.tour-package-details .package-details-tabs .tab-switchers .nav-item .nav-link {
  border: 2px solid #ff4838;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 30px;
  font-weight: 600;
  font-size: 20px;
  color: #2d373c;
  padding: 8px 28px;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

.tour-package-details .package-details-tabs .tab-switchers .nav-item .nav-link:hover {
  background: #ff4838;
  color: #fff;
}

.tour-package-details .package-details-tabs .tab-switchers .nav-item .nav-link i {
  margin-right: 10px;
}

.tour-package-details .package-details-tabs .tab-switchers .nav-item .nav-link.active {
  background: #ff4838;
  color: #fff;
}

.tour-package-details .tab-pane .d-subtitle {
  font-weight: 700;
  font-size: 30px;
  color: #2d373c;
  line-height: 1;
}

.tour-package-details .package-info-tab p {
  font-size: 16px;
  line-height: 30px;
  color: #666666;
  /* padding-top: 15px; */
  padding-top: 0px;
}

.tour-package-details .package-info-tab .p-info-featured-img {
  margin: 20px 0;
}

.tour-package-details .package-info-tab .p-info-featured-img .featured-img img {
  width: 100%;
}

.tour-package-details .package-info-tab .p-info-featured-img .featured-video {
  left: 50%;
  bottom: -30px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: auto;
  padding: 0;
  margin: 0;
  border-right: 5px;
  overflow: hidden;
}

.tour-package-details .package-info-tab .p-info-featured-img .featured-video .video-overlay {
  position: absolute;
  inset: 0;
  background: rgba(255, 72, 56, 0.5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
}

.tour-package-details .package-info-tab .p-info-featured-img .featured-video .video-overlay:hover .play-icon {
  background: rgba(45, 55, 60, 0.8);
  cursor: pointer;
}

.tour-package-details .package-info-tab .p-info-featured-img .featured-video .video-overlay:hover .play-icon::before {
  inset: -8px;
  background: rgba(45, 55, 60, 0.6);
}

.tour-package-details .package-info-tab .p-info-featured-img .featured-video .video-overlay:hover .play-icon::after {
  inset: -24px;
  background: rgba(45, 55, 60, 0.5);
}

.tour-package-details .package-info-tab .p-info-featured-img .featured-video .video-overlay .play-icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 34.94px;
  height: 34.94px;
  background: rgba(45, 55, 60, 0.6);
  border-radius: 50%;
  position: relative;
  z-index: 1;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.tour-package-details .package-info-tab .p-info-featured-img .featured-video .video-overlay .play-icon::before {
  position: absolute;
  content: "";
  inset: -6px;
  background: rgba(45, 55, 60, 0.5);
  border-radius: 50%;
  z-index: 1;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.tour-package-details .package-info-tab .p-info-featured-img .featured-video .video-overlay .play-icon::after {
  position: absolute;
  content: "";
  inset: -12px;
  background: rgba(45, 55, 60, 0.4);
  border-radius: 50%;
  z-index: -1;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.tour-package-details .package-info-tab .p-info-featured-img .featured-video .video-overlay .play-icon i {
  font-size: 20px;
  color: #ff4838;
  position: relative;
  z-index: 2;
}

.tour-package-details .package-info-tab .package-info-table {
  margin-top: 80px;
  background: #ffffff;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 10px;
}

.tour-package-details .package-info-tab .package-info-table tr .tour-transport-col {
  padding-left: 10px;
}

.tour-package-details .package-info-tab .package-info-table tr .tour-transport-col .tour-transport img {
  max-width: 25px;
  margin-right: 10px;
}

.tour-package-details .package-info-tab .package-info-table tr th,
.tour-package-details .package-info-tab .package-info-table tr td {
  font-weight: 600;
  font-size: 15px;
  padding: 20px 10px 20px 40px;
  vertical-align: middle;
}

.tour-package-details .package-info-tab .package-info-table tr th .excluded-list li,
.tour-package-details .package-info-tab .package-info-table tr td .excluded-list li {
  margin-top: 10px;
}

.tour-package-details .package-info-tab .package-info-table tr th .excluded-list li:first-child,
.tour-package-details .package-info-tab .package-info-table tr td .excluded-list li:first-child {
  margin-top: 0;
}

.tour-package-details .package-info-tab .package-info-table tr th .excluded-list li i,
.tour-package-details .package-info-tab .package-info-table tr td .excluded-list li i {
  margin-right: 15px;
  color: #ff4838;
}

.tour-package-details .package-info-tab .package-info-table tr th .included-list li,
.tour-package-details .package-info-tab .package-info-table tr td .included-list li {
  margin-top: 10px;
}

.tour-package-details .package-info-tab .package-info-table tr th .included-list li:first-child,
.tour-package-details .package-info-tab .package-info-table tr td .included-list li:first-child {
  margin-top: 0;
}

.tour-package-details .package-info-tab .package-info-table tr th .included-list li i,
.tour-package-details .package-info-tab .package-info-table tr td .included-list li i {
  margin-right: 15px;
  color: #162b32;
}

.tour-package-details .package-info-tab .package-info-table tr th {
  color: #2d373c;
}

.tour-package-details .package-info-tab .package-info-table tr td {
  color: #666666;
}

.tour-package-details .package-info-tab .rating-overview {
  margin-top: 60px;
}

.tour-package-details .package-info-tab .rating-overview-row {
  margin-top: 24px;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid rgba(255, 72, 56, 0.15);
}

.tour-package-details .package-info-tab .rating-overview-row .total-rating {
  background: #f5f5f5;
  padding: 40px 20px;
}

.tour-package-details .package-info-tab .rating-overview-row .total-rating h3 {
  font-weight: 800;
  font-size: 50px;
  color: #ff4838;
}

.tour-package-details .package-info-tab .rating-overview-row .total-rating h5 {
  font-weight: 500;
  font-size: 25px;
  color: #262339;
}

.tour-package-details .package-info-tab .rating-overview-row .rating-info {
  padding: 45px 35px;
}

.tour-package-details .package-info-tab .rating-overview-row .rating-info .rating-box {
  margin-top: 28px;
}

.tour-package-details .package-info-tab .rating-overview-row .rating-info .rating-box:first-child {
  margin-top: 0;
}

.tour-package-details .package-info-tab .rating-overview-row .rating-info .rating-box h6 {
  font-weight: 600;
  font-size: 18px;
  color: #262339;
}

.tour-package-details .package-info-tab .rating-overview-row .rating-info .rating-box h6 span {
  font-size: 20px;
  font-weight: 700;
  float: right;
}

.tour-package-details .package-info-tab .rating-overview-row .rating-info .rating-box .rating-bar {
  width: 100%;
  height: 3px;
  border-radius: 6px;
  background: #eee;
  margin-top: 10px;
  position: relative;
  overflow: hidden;
}

.tour-package-details .package-info-tab .rating-overview-row .rating-info .rating-box .rating-bar::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ff4838;
}

.tour-package-details .package-info-tab .comment-section {
  padding: 60px 0;
}

.tour-package-details .package-plan-tab p {
  font-size: 16px;
  line-height: 28px;
  color: #666666;
  padding-top: 15px;
}

.tour-package-details .package-plan-tab .plans-accordion .plans-accordion-single {
  border: none;
  margin-top: 40px;
}

.tour-package-details .package-plan-tab .plans-accordion .plans-accordion-single .accordion-button {
  padding: 0 30px;
  padding-left: 0;
  cursor: pointer;
  background: #ffffff;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 80px;
}

.tour-package-details .package-plan-tab .plans-accordion .plans-accordion-single .accordion-button::after {
  background-image: none;
  content: "\ecad";
  font-size: 36px;
  font-family: boxicons;
  height: auto;
  width: auto;
  color: #2d373c;
}

.tour-package-details .package-plan-tab .plans-accordion .plans-accordion-single .accordion-button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.tour-package-details .package-plan-tab .plans-accordion .plans-accordion-single .accordion-button .paln-index-circle {
  width: 75px;
  height: 75px;
  background: #ff4838;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 5px solid rgba(22, 43, 50, 0.5);
  border-radius: 50%;
  font-weight: 700;
  font-size: 32px;
  color: #ffffff;
  margin-right: 25px;
}

.tour-package-details .package-plan-tab .plans-accordion .plans-accordion-single .accordion-button .plan-title {
  padding-bottom: 3px;
}

.tour-package-details .package-plan-tab .plans-accordion .plans-accordion-single .accordion-button .plan-title h5 {
  font-weight: 700;
  font-size: 23px;
  color: #2d373c;
}

.tour-package-details .package-plan-tab .plans-accordion .plans-accordion-single .accordion-button .plan-title h6 {
  font-weight: 500;
  font-size: 16px;
  color: #ff4838;
  padding-top: 8px;
}

.tour-package-details .package-plan-tab .plans-accordion .plans-accordion-single .plan-info {
  padding-left: 60px;
  padding-top: 20px;
  padding-bottom: 0;
}

.tour-package-details .package-plan-tab .plans-accordion .plans-accordion-single .plan-info p {
  padding-top: 0;
}

.tour-package-details .package-plan-tab .plans-accordion .plans-accordion-single .plan-info ul {
  padding-top: 8px;
}

.tour-package-details .package-plan-tab .plans-accordion .plans-accordion-single .plan-info ul li {
  font-weight: 500;
  font-size: 16px;
  color: #ff4838;
  padding-top: 5px;
}

.tour-package-details .package-plan-tab .plans-accordion .plans-accordion-single .plan-info ul li i {
  color: #162b32;
  margin-right: 8px;
}

.tour-package-details .package-gallary-tab .package-gallary-item {
  overflow: hidden;
  border-radius: 5px;
}

.tour-package-details .package-gallary-tab .package-gallary-item:hover img {
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
}

.tour-package-details .package-gallary-tab .package-gallary-item img {
  width: 100%;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.tour-package-details .package-location-tab .mapouter {
  position: relative;
  text-align: right;
  height: 770px;
  width: 100%;
  padding-top: 5px;
}

.tour-package-details .package-location-tab iframe {
  width: 100%;
  height: 550px;
}

.testimonial-card-gamma {
  background: #fff;
  border-radius: 5px;
  max-width: 790px;
  cursor: pointer;
}

.testimonial-card-gamma .testimonial-card-body {
  padding: 30px;
}

.testimonial-card-gamma .testimonial-card-body .review-texts {
  font-size: 16px;
  line-height: 30px;
  color: #666666;
}

.testimonial-card-gamma .testimonial-card-body .testimonial-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 5px;
}

.testimonial-card-gamma .testimonial-card-body .testimonial-info .reviewr-info {
  padding-top: 20px;
}

.testimonial-card-gamma .testimonial-card-body .testimonial-info .reviwer-info-cotn h5 {
  font-weight: 600;
  font-size: 23px;
  text-transform: capitalize;
  color: #696969;
}

.testimonial-card-gamma .testimonial-card-body .testimonial-info .reviwer-info-cotn p {
  font-size: 18px;
  text-transform: capitalize;
  color: #a5a5a5;
  padding-top: 4px;
}

.testimonial-card-gamma .testimonial-card-body .testimonial-info .reviewer-rating {
  padding-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: end;
}

.testimonial-card-gamma .testimonial-card-body .testimonial-info .reviewer-rating .bi {
  color: var(--c-primary);
}

.testimonial-card-gamma .testimonial-card-body .testimonial-info .reviewr-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.testimonial-card-gamma .testimonial-card-body .testimonial-info .reviewr-info .single-thumb {
  margin-right: 15px;
}

.testimonial-card-beta {
  background: #fff;
  border-radius: 5px;
  max-width: 790px;
  cursor: pointer;
  position: relative;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  position: relative;
  z-index: 1;
}

.testimonial-card-beta::after {
  content: "";
  position: absolute;
  left: 30px;
  top: 0;
  width: 60px;
  height: 65px;
  background: rgba(255, 72, 56, 0.1);
  z-index: -1;
}

.testimonial-card-beta::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0%;
  height: 2px;
  background: var(--c-primary);
  z-index: 2;
  -webkit-transition: 0.45s ease-out 0s;
  transition: 0.45s ease-out 0s;
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.testimonial-card-beta:hover {
  background: #00152b;
}

.testimonial-card-beta:hover .testimonial-card-body .review-texts p {
  color: #fff;
}

.testimonial-card-beta:hover .testimonial-card-body .testimonial-info .reviwer-info-cotn h5 {
  font-weight: 600;
  font-size: 23px;
  text-transform: capitalize;
  color: #ccc;
}

.testimonial-card-beta:hover::before {
  width: 100%;
}

.testimonial-card-beta .testimonial-quote-icon {
  position: absolute;
  right: 25px;
  top: 45px;
}

.testimonial-card-beta .testimonial-card-body {
  padding: 30px;
}

.testimonial-card-beta .testimonial-card-body .review-texts {
  margin-top: 20px;
}

.testimonial-card-beta .testimonial-card-body .review-texts p {
  font-size: 18px;
  line-height: 28px;
  color: #666666;
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.testimonial-card-beta .testimonial-card-body .testimonial-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 5px;
}

.testimonial-card-beta .testimonial-card-body .testimonial-info .reviwer-info-cotn h5 {
  font-weight: 600;
  font-size: 23px;
  text-transform: capitalize;
  color: #696969;
}

.testimonial-card-beta .testimonial-card-body .testimonial-info .reviwer-info-cotn p {
  font-size: 18px;
  text-transform: capitalize;
  color: #a5a5a5;
  padding-top: 4px;
}

.testimonial-card-beta .testimonial-card-body .testimonial-info .reviewer-rating {
  padding-top: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: end;
}

.testimonial-card-beta .testimonial-card-body .testimonial-info .reviewer-rating .bi {
  color: var(--c-primary);
}

.testimonial-card-beta .testimonial-card-body .testimonial-info .reviewr-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.testimonial-card-beta .testimonial-card-body .testimonial-info .reviewr-info .single-thumb {
  margin-right: 15px;
  max-width: 60px;
  width: 100%;
}

.testimonial-card-beta .testimonial-card-body .testimonial-info .reviewr-info .single-thumb img {
  max-width: 100%;
  height: auto;
}

.pb-110 {
  padding-bottom: 110px;
}

.testimonial-style-three {
  background-image: -webkit-gradient(linear,
      left top,
      left bottom,
      from(rgba(0, 5, 15, 0.6)),
      to(rgba(0, 5, 15, 0.6))),
    url("../../../public/images/reviewer/review3-bg.png");
  background-image: linear-gradient(rgba(0, 5, 15, 0.6), rgba(0, 5, 15, 0.6)),
    url("../../../public/images/reviewer/review3-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding-left: 10%;
  padding-right: 10%;
}

@media (min-width: 768px) and (max-width: 991px) {
  .testimonial-style-three {
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media (max-width: 767px) {
  .testimonial-style-three {
    padding-left: 2%;
    padding-right: 2%;
  }
}

.testimonial-style-three .slider-arrows .testi-prev4,
.testimonial-style-three .slider-arrows .testi-prev5,
.testimonial-style-three .slider-arrows .testi-prev6,
.testimonial-style-three .slider-arrows .testi-next4,
.testimonial-style-three .slider-arrows .testi-next5,
.testimonial-style-three .slider-arrows .testi-next6 {
  color: var(--c-primary);
  border-radius: 50%;
  background: rgba(255, 72, 56, 0.6);
  color: #fff;
  height: 30px;
  line-height: 30px;
  width: 30px;
  text-align: center;
  position: relative;
  overflow: hidden;
  z-index: 1;
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
}

.testimonial-style-three .slider-arrows .testi-prev4::before,
.testimonial-style-three .slider-arrows .testi-prev5::before,
.testimonial-style-three .slider-arrows .testi-prev6::before,
.testimonial-style-three .slider-arrows .testi-next4::before,
.testimonial-style-three .slider-arrows .testi-next5::before,
.testimonial-style-three .slider-arrows .testi-next6::before {
  content: "";
  position: absolute;
  left: -1px;
  top: -1px;
  height: 31px;
  width: 31px;
  background: var(--c-primary);
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
  border-radius: 50%;
  z-index: -1;
}

.testimonial-style-three .slider-arrows .testi-prev4:hover,
.testimonial-style-three .slider-arrows .testi-prev5:hover,
.testimonial-style-three .slider-arrows .testi-prev6:hover,
.testimonial-style-three .slider-arrows .testi-next4:hover,
.testimonial-style-three .slider-arrows .testi-next5:hover,
.testimonial-style-three .slider-arrows .testi-next6:hover {
  color: #fff;
}

.testimonial-style-three .slider-arrows .testi-prev4:hover::before,
.testimonial-style-three .slider-arrows .testi-prev5:hover::before,
.testimonial-style-three .slider-arrows .testi-prev6:hover::before,
.testimonial-style-three .slider-arrows .testi-next4:hover::before,
.testimonial-style-three .slider-arrows .testi-next5:hover::before,
.testimonial-style-three .slider-arrows .testi-next6:hover::before {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.guide-card-beta {
  background: #ffffff;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 0px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
}

.guide-card-beta:hover .guide-image img {
  -webkit-transform: scale(1.15);
  transform: scale(1.15);
}

.guide-card-beta:hover .guide-image .guide-social-links {
  -webkit-transform: translate(0%);
  transform: translate(0%);
  opacity: 1;
}

.guide-card-beta .guide-image {
  position: relative;
  overflow: hidden;
}

.guide-card-beta .guide-image img {
  width: 100%;
  -webkit-transition: all 0.65s ease;
  transition: all 0.65s ease;
  -webkit-transition-delay: 0.35s ease;
  transition-delay: 0.35s ease;
}

.guide-card-beta .guide-image .guide-social-links {
  position: absolute;
  left: 0%;
  top: 0px;
  opacity: 0;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  background: transparent;
  padding: 12px 14px;
  -webkit-transition: all 0.45s ease-in;
  transition: all 0.45s ease-in;
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
  background: rgba(0, 0, 0, 0.4);
  width: 60px;
  height: 100%;
  opacity: 0;
}

.guide-card-beta .guide-image .guide-social-links li {
  margin-right: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.guide-card-beta .guide-image .guide-social-links li:last-child {
  margin-right: 0;
}

.guide-card-beta .guide-image .guide-social-links li a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 28px;
  width: 28px;
  background: white;
  border-radius: 30px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--c-primary);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.guide-card-beta .guide-image .guide-social-links li a:hover {
  background: #ff4838;
  color: #fff;
}

.guide-card-beta .guide-image .guide-social-links li a i {
  font-size: 14px;
}

.guide-card-beta .guide-content {
  text-align: center;
  padding: 25px;
  -webkit-transition: all 0.45s ease-in;
  transition: all 0.45s ease-in;
}

.guide-card-beta .guide-content .guide-name {
  font-weight: 700;
  font-size: 23px;
  text-transform: capitalize;
  color: #2d373c;
}

.guide-card-beta .guide-content .guide-name a {
  color: inherit;
}

.guide-card-beta .guide-content .guide-designation {
  font-weight: 500;
  font-size: 18px;
  text-transform: capitalize;
  color: #666666;
  padding-top: 5px;
}

.guide-card-gamma {
  background: #ffffff;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 5px;
  overflow: hidden;
}

.guide-card-gamma:hover .guide-image .guide-social-links {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}

.guide-card-gamma .guide-image {
  position: relative;
}

.guide-card-gamma .guide-image img {
  width: 100%;
}

.guide-card-gamma .guide-image .contact-lavel {
  position: absolute;
  left: 15px;
  bottom: 15px;
}

.guide-card-gamma .guide-image .contact-lavel a {
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  color: #ffffff;
  display: inline-block;
  background: #ff4838;
  border-radius: 100px;
  padding: 6px 22px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.guide-card-gamma .guide-image .contact-lavel a:hover {
  background: #666666;
}

.guide-card-gamma .guide-image .guide-social-links {
  position: absolute;
  top: 25px;
  right: 25px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: translateX(30px);
  transform: translateX(30px);
  opacity: 0;
}

.guide-card-gamma .guide-image .guide-social-links li {
  margin-bottom: 15px;
}

.guide-card-gamma .guide-image .guide-social-links li:last-child {
  margin-bottom: 0;
}

.guide-card-gamma .guide-image .guide-social-links li a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 30px;
  width: 30px;
  background: #fff;
  border-radius: 30px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #162b32;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.guide-card-gamma .guide-image .guide-social-links li a:hover {
  background: #ff4838;
  color: #fff;
}

.guide-card-gamma .guide-image .guide-social-links li a i {
  font-size: 15px;
}

.guide-card-gamma .guide-content {
  text-align: center;
  padding: 25px;
}

.guide-card-gamma .guide-content .guide-name {
  font-weight: 700;
  font-size: 22px;
  text-transform: capitalize;
  color: #2d373c;
}

.guide-card-gamma .guide-content .guide-name a {
  color: inherit;
}

.guide-card-gamma .guide-content .guide-designation {
  font-weight: 500;
  font-size: 15px;
  text-transform: capitalize;
  color: #666666;
  padding-top: 5px;
}

.blog-card-beta {
  background: #ffffff;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 5px;
  overflow: hidden;
}

.blog-card-beta:hover .blog-thumb img {
  -webkit-transform: scale(1.05) translateX(4px);
  transform: scale(1.05) translateX(4px);
}

.blog-card-beta:hover .blog-thumb .blog-lavel a {
  color: #fff;
  background: var(--c-primary);
}

.blog-card-beta .blog-thumb {
  position: relative;
  overflow: hidden;
}

.blog-card-beta .blog-thumb img {
  width: 100%;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.blog-card-beta .blog-thumb .blog-lavel {
  position: absolute;
  bottom: 25px;
  left: 25px;
}

.blog-card-beta .blog-thumb .blog-lavel a {
  font-family:'Roboto-Regular';
  font-weight: 500;
  font-size: 15px;
  color: var(--c-primary);
  display: inline-block;
  background: #fff;
  -webkit-box-shadow: 0px 0px 15px rgba(206, 62, 38, 0.1);
  box-shadow: 0px 0px 15px rgba(206, 62, 38, 0.1);
  border-radius: 30px;
  padding: 5px 20px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.blog-card-beta .blog-thumb .blog-lavel a i {
  margin-right: 6px;
}

.blog-card-beta .blog-content {
  padding: 20px;
  text-align: center;
}

.blog-card-beta .blog-content .blog-body-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 35px;
}

.blog-card-beta .blog-content .blog-body-top a {
  font-weight: 500;
  font-size: 14px;
  color: #696969;
}

.blog-card-beta .blog-content .blog-body-top a i {
  color: #ff4838;
  margin-right: 5px;
}

.blog-card-beta .blog-content .blog-title {
  padding-top: 8px;
  font-weight: 600;
  line-height: 28px;
  font-size: 20px;
  color: #2d373c;
}

.blog-card-beta .blog-content .blog-title:hover a {
  color: var(--c-primary);
}

.blog-card-beta .blog-content .blog-title a {
  color: inherit;
  -webkit-transition: all 0.35s ease-in;
  transition: all 0.35s ease-in;
}

.blog-card-gamma-full {
  background: #ffffff;
  margin-top: 40px;
  -webkit-box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 5px 5px 20px 20px;
  overflow: hidden;
}

.blog-card-gamma-full:hover .blog-thumb img {
  -webkit-transform: scale(1.05) translateX(4px);
  transform: scale(1.05) translateX(4px);
}

.blog-card-gamma-full:hover .blog-thumb .blog-lavel a {
  background: #666666;
}

.blog-card-gamma-full .blog-thumb {
  position: relative;
  overflow: hidden;
}

.blog-card-gamma-full .blog-thumb img {
  width: 100%;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.blog-card-gamma-full .blog-thumb .blog-lavel {
  position: absolute;
  top: 25px;
  left: 25px;
}

.blog-card-gamma-full .blog-thumb .blog-lavel a {
  font-family:'Roboto-Regular';
  font-weight: 700;
  font-size: 15px;
  color: #ffffff;
  display: inline-block;
  background: #ff4838;
  -webkit-box-shadow: 0px 0px 15px rgba(206, 62, 38, 0.1);
  box-shadow: 0px 0px 15px rgba(206, 62, 38, 0.1);
  border-radius: 3px;
  padding: 7px 20px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.blog-card-gamma-full .blog-thumb .blog-lavel a i {
  margin-right: 6px;
}

.blog-card-gamma-full .blog-content {
  padding: 20px;
  text-align: center;
}

.blog-card-gamma-full .blog-content .blog-body-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 40px;
}

.blog-card-gamma-full .blog-content .blog-body-top a {
  font-weight: 500;
  font-size: 15px;
  color: #696969;
}

.blog-card-gamma-full .blog-content .blog-body-top a i {
  color: #ff4838;
  margin-right: 5px;
}

.blog-card-gamma-full .blog-content .blog-title {
  padding-top: 8px;
  font-weight: 600;
  line-height: 35px;
  font-size: 25px;
  color: #2d373c;
}

.blog-card-gamma-full .blog-content .blog-title a {
  color: inherit;
}

.more-update-section {
  background: linear-gradient(91.22deg,
      rgba(0, 5, 15, 0.8) 24.54%,
      rgba(0, 5, 15, 0.2) 100%),
    url("../../../public/images/banner/update-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 90px 30px;
}

.more-update-content h2 {
  font-size: 45px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.more-update-content p {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  width: 90%;
}

.update-btn {
  white-space: nowrap;
}

/*=======================================
  3. search area start
=======================================*/
.mobile-search {
  background: rgba(0, 5, 15, 0.6);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  width: 100%;
  height: 220px;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  align-items: center;
  position: fixed;
  cursor: pointer;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  top: 0;
  left: 0;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.6s ease;
  transition: 0.6s ease;
  padding: 35px 100px;
}

.mobile-search label {
  color: #fff;
  margin-bottom: 20px;
}

.mobile-search.slide {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.mobile-search input {
  border: none;
  border-radius: unset;
  border-bottom: 1px solid rgba(238, 238, 238, 0.7);
  outline: none;
  padding-left: 0px;
  padding-bottom: 5px;
  width: 100%;
  background: transparent;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  color: #fff;
  margin-bottom: 20px;
}

.mobile-search input:focus {
  border-bottom: 1px solid var(--c-primary);
}

.mobile-search input::-webkit-input-placeholder {
  color: #dddddd;
}

.mobile-search input:-ms-input-placeholder {
  color: #dddddd;
}

.mobile-search input::-ms-input-placeholder {
  color: #dddddd;
}

.mobile-search input::placeholder {
  color: #dddddd;
}

.mobile-search .search-cross-btn {
  color: #fff;
  cursor: pointer;
  background: rgba(var(--c-primary), 0.6);
  border-radius: 50%;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  line-height: 43px;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

.mobile-search .search-cross-btn:hover {
  background: var(--c-primary);
  color: #fff;
}

.mobile-search .search-cross-btn i {
  font-size: 25px;
  cursor: pointer;
}

.topbar-style-two {
  background: #030013;
  position: relative;
  z-index: 1;
  padding: 8px 0px;
}

.topbar-style-two .topbar-contact-left .contact-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.topbar-style-two .topbar-contact-left .contact-list li {
  margin-right: 20px;
  font-weight: 600;
  font-size: 13px;
  color: #ffffff;
}

.topbar-style-two .topbar-contact-left .contact-list li a {
  color: inherit;
}

.topbar-style-two .topbar-contact-left .contact-list li:last-child {
  margin-right: 0;
}

.topbar-style-two .topbar-contact-left .contact-list li i {
  margin-right: 5px;
}

.topbar-style-two .topbar-ad a {
  display: inline-block;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
  background: #ff4838;
  padding: 13px 50px;
}

.topbar-style-two .topbar-social-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.topbar-style-two .topbar-social-links li {
  margin-right: 20px;
}

.topbar-style-two .topbar-social-links li:last-child {
  margin-right: 0;
}

.topbar-style-two .topbar-social-links li i {
  color: #fff;
  font-size: 16px;
  -webkit-transition: all 0.35s ease-in;
  transition: all 0.35s ease-in;
}

.topbar-style-two .topbar-social-links li i::before {
  vertical-align: middle;
}

.topbar-style-two .topbar-social-links li i:hover {
  color: var(--c-primary);
}

.gradient-custom {
  /* fallback for old browsers */
  background: #f6d365;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right bottom,
      rgba(246, 211, 101, 1),
      rgba(253, 160, 133, 1));

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right bottom,
      rgba(246, 211, 101, 1),
      rgba(253, 160, 133, 1));
}

.box {
  width: 200px;
  height: 300px;
  position: relative;
  border: 1px solid #bbb;
  background: #eee;
  float: left;
  margin: 20px;
}

.ribbon {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 122px;
  height: 128px;
  text-align: right;
}

.ribbon span {
  font-size: 11.8px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  line-height: 32px;
  transform: rotate(45deg);
  width: 175px;
  display: block;
  background: #79a70a;
  background: linear-gradient(#9bc90d 0%, #79a70a 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 33px;
  right: -41px;
}

.ribbon span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #79a70a;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79a70a;
}

.ribbon span::after {
  content: "";
  position: absolute;
  right: 0%;
  top: 100%;
  z-index: -1;
  border-right: 3px solid #79a70a;
  border-left: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79a70a;
}

.red span {
  background: linear-gradient(#ff4838 0%, #ff4838 100%);
}

.red span::before {
  border-left-color: #8f0808;
  border-top-color: #8f0808;
}

.red span::after {
  border-right-color: #8f0808;
  border-top-color: #8f0808;
}

.blue span {
  background: linear-gradient(#2989d8 0%, #1e5799 100%);
}

.blue span::before {
  border-left-color: #1e5799;
  border-top-color: #1e5799;
}

.blue span::after {
  border-right-color: #1e5799;
  border-top-color: #1e5799;
}

.foo {
  clear: both;
}

.bar {
  content: "";
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #79a70a;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79a70a;
}

.baz {
  font-size: 1rem;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  line-height: 2em;
  transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #79a70a;
  background: linear-gradient(#9bc90d 0%, #79a70a 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 100px;
  left: 1000px;
}

/* ----------Cobagage Wallet ----------------- */
/* body{
  margin-top:20px;
  background:#FAFAFA;
} */
.order-walletCard {
  color: #fff;
}

.bg-c-blue {
  background: linear-gradient(45deg, #4099ff, #73b4ff);
}

.bg-c-green {
  background: linear-gradient(45deg, #2ed8b6, #59e0c5);
}

.bg-c-yellow {
  background: linear-gradient(45deg, #ffb64d, #ffcb80);
}

.bg-c-pink {
  background: linear-gradient(45deg, #ff5370, #ff869a);
}

.walletCard {
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  border: none;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.walletCard .card-block {
  padding: 25px;
}

.order-card i {
  font-size: 26px;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.rate i,
.rate i:hover~i {
  color: #222;
  text-shadow: none;
  transition: color 200ms, text-shadow 200ms;
  /* This will remove the delay when
     moving the cursor left or right
     within the set of stars. */
  transition-delay: 0;
}

/* This is the style that will be
   applied to all stars and then
   subsequently removed from the stars
   to the right of the one being
   hovered. */
.rate:hover i {
  color: #fc0;
  text-shadow: #fc0 0 0 20px;
}

/* Make the effect apply one star at a
   time. Limiting the selector to when
   .rate is hovered removes the effect
   without delay when cursor leaves
   the .rate area. */
.rate:hover i:nth-child(2) {
  transition-delay: 30ms;
}

.rate:hover i:nth-child(3) {
  transition-delay: 60ms;
}

.rate:hover i:nth-child(4) {
  transition-delay: 90ms;
}

.rate:hover i:nth-child(5) {
  transition-delay: 120ms;
}

/* Miscellaneous styles. */
.rate i {
  cursor: pointer;
  font-style: normal;
}

.subscribecard {
  width: 95%;
  border-radius: 20px;
  background: #fff;
  box-shadow: 20px 20px 60px #cacaca, -20px -20px 0px #ffffff;
  border: none;
}

.neo-button {
  width: 40px;
  height: 40px;
  outline: 0 !important;
  cursor: pointer;
  color: #fff;
  font-size: 15px;
  border: none;
  margin-right: 10px;
  border-radius: 50%;
  background: linear-gradient(145deg, #d6d6d6, #ffffff);
  box-shadow: 20px 20px 60px #cacaca, -20px -20px 60px #ffffff;
}

.neo-button:active {
  border-radius: 50%;
  background: linear-gradient(145deg, #d6d6d6, #ffffff);
  box-shadow: inset 20px 20px 60px #cacaca, inset -20px -20px 60px #ffffff;
}

.bi-arrow-right-circle-fill {
  color: #ff4838;
}

/* SubscribeAds css */

.section-title {
  margin-bottom: 38px;
}

.shadow,
.subscription-wrapper {
  box-shadow: 0px 15px 39px 0px rgba(8, 18, 109, 0.1) !important;
}

.icon-primary {
  color: #062caf;
}

.icon-bg-circle {
  position: relative;
}

.icon-lg {
  font-size: 50px;
}

.icon-bg-circle::before {
  z-index: 1;
  position: relative;
}

.icon-bg-primary::after {
  background: #062caf !important;
}

.icon-bg-circle::after {
  content: "";
  position: absolute;
  width: 68px;
  height: 68px;
  top: -35px;
  left: 15px;
  border-radius: 50%;
  background: inherit;
  opacity: 0.1;
}

.icon-bg-yellow::after {
  background: #f6a622 !important;
}

.icon-bg-purple::after {
  background: #7952f5;
}

.icon-yellow {
  color: #f6a622;
}

.icon-purple {
  color: #7952f5;
}

.icon-cyan {
  color: #02d0a1;
}

.icon-bg-cyan::after {
  background: #02d0a1;
}

.icon-bg-red::after {
  background: #ff4949;
}

.icon-red {
  color: #ff4949;
}

.icon-bg-green::after {
  background: #66cc33;
}

.icon-green {
  color: #66cc33;
}

.icon-bg-orange::after {
  background: #ff7c17;
}

.icon-orange {
  color: #ff7c17;
}

.icon-bg-blue::after {
  background: #3682ff;
}

.icon-blue {
  color: #3682ff;
}

/* Upgrade membership Css */

.wrapper {
  width: 100%;
  background: #fff;
  border-radius: 10px;
  box-shadow: 3px 3px 36px rgba(0, 0, 0, 0.2);
}

.wrapper .title {
  color: #fff;
  line-height: 65px;
  text-align: center;
  background: #ff4838;
  font-size: 25px;
  font-weight: 500;
  border-radius: 4px 4px 0 0;
}

.wrapper .box {
  padding: 20px 30px;
  background: #fff;
  border-radius: 10px;
  width: 95%;
}

.wrapper .box label {
  display: flex;
  height: 53px;
  width: 100%;
  align-items: center;
  border: 1px solid lightgrey;
  border-radius: 5px;
  margin: 10px 0;
  padding-left: 11px;
  cursor: pointer;
  transition: all 0.3s ease;
}

#value-1:checked~.value-1,
#value-2:checked~.value-2,
#value-3:checked~.value-3,
#value-4:checked~.value-4 {
  background: #ff4838;
  border-color: #9c27b0;
}

.wrapper .box label .select-dots {
  height: 20px;
  width: 20px;
  background: #d9d9d9;
  border-radius: 50%;
  position: relative;
  transition: all 0.3s ease;
}

#value-1:checked~.value-1 .select-dots,
#value-2:checked~.value-2 .select-dots,
#value-3:checked~.value-3 .select-dots,
#value-4:checked~.value-4 .select-dots {
  background: #fff;
}

.box label .select-dots::before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(2);
  width: 9px;
  height: 9px;
  border-radius: 50%;
  transition: all 0.3s ease;
}

#value-1:checked~.value-1 .select-dots::before,
#value-2:checked~.value-2 .select-dots::before,
#value-3:checked~.value-3 .select-dots::before,
#value-4:checked~.value-4 .select-dots::before {
  background: #9c27b0;
  transform: translate(-50%, -50%) scale(1);
}

.wrapper .box label .text {
  color: #333;
  font-size: 18px;
  font-weight: 400;
  padding-left: 10px;
  transition: color 0.3s ease;
}

#value-1:checked~.value-1 .text,
#value-2:checked~.value-2 .text,
#value-3:checked~.value-3 .text,
#value-4:checked~.value-4 .text {
  color: #fff;
}

.wrapper .box input[type="radio"] {
  display: none;
}

/* Upgrade to club membership Css */

.t-white {
  font-size: 50px;
  color: white;
  font-weight: 600;
}

.silverCard {
  /* width: 315px; */
  /* margin: 10px; */
  color: white;
  border-radius: 10px;
  /* background-color: #413f3f; */
  /* background: linear-gradient(-135deg, #fae37d, #881da8, #20668f); */
  position: relative;
}

a {
  text-decoration: none;
}

a .btn.btn-primary {
  width: 100%;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: #252525; */
  background-color: #fff;
  border-color: black;
  border: none
}


a .btn.btn-primary p {
  font-size: 14px;
  color: white;
}

/* a:hover .btn.btn-primary p {
  font-size: 14px;
  color: black;
} */

#btn-primary,
.fas.fa-book,
.bi.bi-arrow-up-right-circle-fill,
.bi.bi-heptagon-fill,
.bi.bi-arrow-down-right-circle-fill {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #131212;
}

.order.btn.btn-primary {
  width: 100%;
  /* height: 60px; */
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: white; */
  /* border: none; */
  background: #ff4838;
  color: #fff;
  border: 2px solid #ff4838;
}

.order.btn.btn-primary:hover {
  background: #131212;
  border: 2px solid #131212;
  color: #fff;
}

@media (max-width: 450px) {
  .silverCard {
    max-width: 300px;
  }

  a .btn.btn-primary p {
    font-size: 13px;
  }

  .text-muted {
    font-size: 14px;
  }

  .searchbar-section.home4 {
    margin-top: -280px;
  }
}

/* My Requests CSS */
.earning {
  color: #ffffff;
  text-align: left;
  font-size: 16px;
  width: 100%;
  max-width: 1000px;
  /* margin: 50px 10px 10px; */
  border-radius: 10%;
}

.earning .plan {
  margin: 0;
  width: 95%;
  position: relative;
  float: left;
  background-color: #262626;
  border-radius: 20px;
  /* border: 1px solid #1e1e1e; */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
}

.earning .plan:hover,
earning .plan.hover {
  background-color: #1c1c1c;
}

.earning .plan:hover i,
earning .plan.hover i {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.earning * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
}

.earning header {
  position: relative;
  padding: 20px 10px;
}

.earning header i {
  font-size: 46px;
  margin: 0 15px;
  color: #f39c12;
  display: inline-block;
  float: left;
}

.earning .plan-title {
  top: 0;
  font-weight: 800;
  margin: 10px 0 0;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.earning .plan-cost {
  margin: 0;
  /* opacity: 0.2; */
}

.earning .plan-price {
  font-weight: 800;
  font-size: 1.3em;
  color: #f39c12;
}

.earning .plan-type {
  opacity: 0.8;
  font-size: 0.7em;
  text-transform: uppercase;
}

.earning .plan-select {
  background-color: #fff;
  box-shadow: none;
  text-align: center;
}

.earning .plan-select a {
  color: #ffffff;
  text-decoration: none;
  padding: 15px 20px;
  margin: 20px;
  border-radius: 40px;
  font-size: 11px;
  font-weight: 800;
  text-transform: uppercase;
  display: inline-block;
  background-color: #ff4838;
}

.earning .plan-select a:hover {
  background-color: #262626;
}

.earning .featured {
  margin-top: -10px;
  background-color: #262626;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.earning .featured .plan-select a {
  margin: 30px 20px;
}

@media only screen and (max-width: 767px) {
  .earning .plan {
    width: 100%;
  }

  .earning .plan-title,
  .earning .plan-select a {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .earning .plan-select a,
  .earning .featured .plan-select a {
    margin: 20px;
  }

  .earning .featured {
    margin-top: 0;
  }
}

@media only screen and (max-width: 440px) {
  .earning .plan {
    width: 100%;
  }
}

/* tooltip css */

.icons_container .icon {
  text-align: center;
  cursor: pointer;
  display: flex;
  position: relative;
  z-index: 2;
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.icons_container .icon span {
  display: flex;
  position: relative;
  z-index: 2;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.icons_container .icon .tooltip {
  position: absolute;
  top: 0;
  z-index: 1;
  background: #fff;
  color: #fff;
  padding: 8px 14px;
  font-size: 11px;
  font-weight: 500;
  border-radius: 10px;
  opacity: 0;
  pointer-events: none;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.icons_container .icon:hover .tooltip {
  top: -40px;
  opacity: 1;
  pointer-events: auto;
  margin-left: -10px;
}

.icon .tooltip:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background: #fff;
  left: 50%;
  bottom: -6px;
  transform: translateX(-50%) rotate(45deg);
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.icons_container .icon:hover span {
  color: #fff;
}

.icons_container .icon:hover span,
.icons_container .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4);
}

.icons_container .facebook:hover span,
.icons_container .facebook:hover .tooltip,
.icons_container .facebook:hover .tooltip:before {
  background: #ff4838;
  /* background: #3B5999; */
}

/* {digital signature} */
.digital {
  display: flex;
  justify-content: center;
  align-items: center;
}

.animated-check {
  height: 10em;
  width: 10em;
}

.animated-check path {
  fill: none;
  stroke: #7ac142;
  stroke-width: 4;
  stroke-dasharray: 23;
  stroke-dashoffset: 23;
  animation: draw 1s linear forwards;
  stroke-linecap: round;
  stroke-linejoin: round;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

/* -------- invoice -------- */
.card {
  margin-bottom: 1.5rem;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #c8ced3;
  border-radius: 0.25rem;
}

.invoice-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.invoice-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #f0f3f5;
  border-bottom: 1px solid #c8ced3;
}

/* --------------------notification tooltip------------ */

/* notifiction css for ipad */

@media (max-width: 1200px) {
  header .header-style-four .nav-right-icons .user-dropdown .emailusermobile-drop-list {
    background: #000;
    position: absolute;
    z-index: 2;
    min-width: 500px;
    padding: 15px;
    color: #ffff;
    right: 0px;
    top: 57px;
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    visibility: hidden;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
}

@media (max-width: 767px) {
  header .header-style-four .nav-right-icons .user-dropdown .emailusermobile-drop-list {
    background: #000;
    position: absolute;
    z-index: 2;
    min-width: 225px;
    padding: 15px;
    color: #ffff;
    right: 0px;
    top: 57px;
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    visibility: hidden;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
}

/* payment pop */
.customDatePickerWidth,
.customDatePickerWidth>div.react-datepicker-wrapper,
.customDatePickerWidth>div>div.react-datepicker__input-container .customDatePickerWidth>div>div.react-datepicker__input-container input {
  width: 100%;
}

.paymentPop {
  border-radius: 10px;
  padding: 8px;
  border: 1px solid #0d6efd;
}

.ArrivalMargin {
  margin-left: 43px;
}

.walletImg {
  height: 250px;
}

.marginTop {
  margin-top: -75px;
  margin-bottom: 0px;
}

.travelCard {
  padding-left: 60px;
}

.expireName {
  padding-top: 5px;
  padding-left: 35px;
}

@media (max-width: 1200px) {
  .paymentPop {
    border-radius: 10px;
    padding: 8px;
    border: 1px solid #0d6efd;
  }

  .ArrivalMargin {
    margin-left: 85px;
  }

  .walletImg {
    width: 430px;
  }

  .marginTop {
    margin-top: -99px;
  }

  .travelCard {
    padding-left: 85px;
  }

  .expireName {
    padding-top: 5px;
    padding-left: 35px;
  }
}

.advert {
  font-size: 17px !important;
}

@media (max-width: 767px) {
  .paymentPop {
    border-radius: 0px;
    padding: 0px;
    border: none;
  }

  .advert {
    font-size: 12px !important;
    padding-bottom: 8px;
  }

  .ArrivalMargin {
    margin-left: 52px;
  }

  .walletImg {
    height: 212px;
    width: 100%;
  }

  .marginTop {
    margin-top: 5px;
    margin-bottom: -32px;
  }

  .Accetp {
    margin-left: 15px;
    margin-bottom: 11px;
  }

  .travelCard {
    padding-left: 85px;
  }

  .expireName {
    padding-top: 5px;
    padding-left: 23px;
  }
}

.nav-tabs .nav-link.active {
  border: none;
  font-size: 20px;
  background-color: transparent !important;
  border-bottom: 2px solid #ff4838 !important;
  /* color: #fff; */
  color: #222222;
  border-radius: 0px !important;
  font-weight: 600;
}

.nav-tabs .nav-link:hover {
  /* border: 1px solid #ff4838 !important; */
  background-color: #ff4838 !important;
  color: #fff !important;
  border-radius: 5px !important;
}

.nav-tabs,
.nav-tabs .nav-link {
  border: none !important;
  font-size: 20px;
  font-weight: 600;
  color: #222222;
}

/* chat css */
/* chat css */
::-webkit-scrollbar {
  width: 10px
}

::-webkit-scrollbar-track {
  background: #eee
}

::-webkit-scrollbar-thumb {
  background: #888
}

::-webkit-scrollbar-thumb:hover {
  background: #555
}

/* .wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #651FFF
} */

.main {
  background-color: #eee;
  width: 320px;
  position: relative;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 6px 0px 0px 0px
}

.scroll {
  overflow-y: scroll;
  scroll-behavior: smooth;
  height: 325px
}

.img1 {
  border-radius: 50%;
  background-color: #66BB6A
}

.name {
  font-size: 8px
}

.msg {
  background-color: #fff;
  font-size: 12px;
  padding: 5px;
  border-radius: 5px;
  font-weight: 500;
  color: #3e3c3c
}

.between {
  font-size: 8px;
  font-weight: 500;
  color: #a09e9e
}

.navbar {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
}

.form-control {
  font-size: 12px;
  font-weight: 400;
  width: 230px;
  height: 30px;
  border: none
}
.form-control2 {
  font-size: 12px;
  font-weight: 400;
  width: '100%';
  height: 30px;
  border: none
}

/* form-control: focus {
    box-shadow: none;
    overflow: hidden;
    border: none
} */

.form-control:focus {
  box-shadow: none !important
}

.icon1 {
  color: #7C4DFF !important;
  font-size: 18px !important;
  cursor: pointer
}

.icon2 {
  color: #512DA8 !important;
  font-size: 18px !important;
  position: relative;
  left: 8px;
  padding: 0px;
  cursor: pointer
}

.icondiv {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  padding: 2px;
  position: relative;
  bottom: 1px}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.textDecoration{
  text-decoration-line: underline;
}
.nav-pills .nav-link{
  color: #222222;
  font-weight: 600;
}
.nav-pills .nav-link.active{
  background-color: rgb(238, 146, 0);
}
.travel:hover{
  background-color: rgb(238, 146, 0);
  border-radius: 5px;
}
.luggage:hover{
  background-color: rgb(83, 189, 226);
  border-radius: 5px;
}
.article:hover{
  background-color: gray;
  border-radius: 5px;
}
.donation:hover{
  background-color: black;
  border-radius: 5px;
}
.nav-pills .nav-link:hover{
  color: #ffffff;
}